import React from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";

const FinalCustomersList = (props: any) => {
  const filteredData = React.useMemo(() => {
    const uniqueRows: any[] = [];
    props.offers.forEach((row: any) => {
      if (!uniqueRows.some((r) => r.reference === row.reference)) {
        uniqueRows.push(row);
      }
    });
    return uniqueRows;
  }, [props.offers]);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.offers ? (
            <MaterialTable
              columns={[
                { title: "CODIGO", field: "reference", width: "2%" },

                { title: "CLIENTE", field: "reference_name", width: "2%" },

                {
                  title: "CANAL",
                  field: "finalcustomertypename",
                  width: "2%",
                },
              ]}
              options={{
                toolbar: true,
                exportButton: false,
                selection: false,
                search: false,
                paging: false,
                pageSize: 15,
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => {
                  return {
                    fontSize: 13,
                    color: "#676A6C",
                    background: "#F5F5F5",
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                  };
                },
                filtering: false,
              }}
              data={filteredData}
              title="Clientes Finales"
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default FinalCustomersList;
