import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
} from "reactstrap";

import * as FaIcons from "react-icons/fa";

import { useTranslation } from "react-i18next";
import {
  calcularRendimientoProductoPorTipoCliente,
  getproducts,
  obtenerDetallesClientesPorProducto,
  obtenerVendedoresPorProductoYAnio,
  obtenerVentasProductosByAnio,
} from "../services/chartsService";

import ProductHeader from "../shared/components/ProductHeader";
import ProductsList from "../shared/components/ProductsList";
import VendedoresPorProductoChart from "../shared/components/charts/VendedoresPorProductoChart";
import VentasProductosTreemap from "../shared/components/charts/VentasProductosTreemap";
import SellerPerformance from "../shared/components/Sellerperformance";

import Map from "../shared/components/Maps/Map";
import CustomersProductsSoldList from "../shared/components/CustomersProductsSoldList";
import TortaChart from "../shared/components/charts/TortaChart";
import { obtenerCategorias } from "../services/customerService";

interface Categoria {
  type_id: number;
  description: string;
  // Agrega aquí otras propiedades según sean necesarias
}

const ProductsKPI = () => {
  const { t } = useTranslation(["home", "projects"]);

  const [datos8, setdatos8] = useState([]);

  const [datos15, setdatos15] = useState([]);
  const [datos16, setdatos16] = useState([]);
  const [datos, setdatos] = useState([]);
  const [datos11, setdatos11] = useState([]);

  const [datos10, setdatos10] = useState([]);
  const [total_ventas, settotal_ventas] = useState(null);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [chartsyear, setchartsyear] = useState(new Date().getFullYear());

  const [datarelations, setDataRelations] = useState({
    relacionesProductoCliente: [],
    sumasTotalesPorProducto: [],
  });

  const years = Array.from(
    new Array(new Date().getFullYear() - 2021),
    (val, index) => 2022 + index
  );

  useEffect(() => {
    refreshCustomers();
    obtenerCategorias().then((data) => {
      setCategorias(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartsyear]);

  const refreshCustomers = () => {
    obtenerVentasProductosByAnio(chartsyear).then((response) => {
      if (response?.data) {
        setdatos15(response?.data);
      } else {
      }
    });

    getproducts().then((response) => {
      if (response?.data) {
        setdatos16(response?.data);
      } else {
      }
    });

    /*
    obtenerRelacionesProductoCliente(chartsyear).then((response) => {
      if (response?.data) {
        setDataRelations({
          relacionesProductoCliente: response?.data.relacionesProductoCliente,
          sumasTotalesPorProducto: response?.data.sumasTotalesPorProducto,
        });
      } else {
      }
    }); */
  };

  const offer_handler = (data: any) => {
    setdatos(data);
    loadCharts(data);
  };

  const loadCharts = (data: any) => {
    obtenerVendedoresPorProductoYAnio(data.nmc_code, chartsyear).then(
      (response) => {
        //console.table(response?.data);
        if (response?.data) {
          console.table(response?.data);
          setdatos8(response?.data);
        } else {
        }
      }
    );

    calcularRendimientoProductoPorTipoCliente(data.nmc_code, chartsyear).then(
      (response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos11(response?.data);
          let totalVentas = response.data.reduce(
            (acumulador: number, item: { total_ventas: string }) => {
              // Convertir el formato localizado de número (ej. "1.234,56") a un flotante
              let totalVentasNumerico = parseFloat(
                item.total_ventas.replace(".", "").replace(",", ".")
              );
              return acumulador + totalVentasNumerico;
            },
            0
          );
          settotal_ventas(totalVentas);
          // Mostrar el total de ventas en consola o usarlo como se necesite
          console.log(`Total de Ventas: ${totalVentas}`);
        } else {
        }
      }
    );
    obtenerDetallesClientesPorProducto(data.nmc_code, chartsyear).then(
      (response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos10(response?.data);
        } else {
        }
      }
    );
  };

  const show = (item: any) => {
    window.open(`/layout/channel/${item.id}`);
  };

  const chartsyearschange = (e: any) => {
    setchartsyear(e.target.value);

    obtenerVentasProductosByAnio(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos15(response?.data);
      } else {
      }
    });
    /*
    obtenerRelacionesProductoCliente(chartsyear).then((response) => {
      if (response?.data) {
        setDataRelations({
          relacionesProductoCliente: response?.data.relacionesProductoCliente,
          sumasTotalesPorProducto: response?.data.sumasTotalesPorProducto,
        });
      } else {
      }
    }); */
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            {t("productskpi.projectsTitle")}
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-8">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>
                {t("productskpi.projectsBreadcrum")}
              </BreadcrumbItem>
              <BreadcrumbItem>{t("productskpi.listBreadcrum")}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <select
                  id="1"
                  value={chartsyear}
                  onChange={chartsyearschange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                <CardText>
                  {" "}
                  {datos15 ? <VentasProductosTreemap datos={datos15} /> : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* <Row>
          <Col>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {datarelations ? (
                  <ProductClientNetwork
                    data={datarelations.relacionesProductoCliente}
                    sumasTotalesPorProducto={
                      datarelations.sumasTotalesPorProducto
                    }
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row> */}

        <br />
        <Row>
          <Col xs="2">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  {" "}
                  {datos16 ? (
                    <ProductsList
                      datos={datos16}
                      offer_handler={offer_handler}
                    />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs="10">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  <Row>
                    <Col xs="4">
                      <ProductHeader datos={datos} total={total_ventas} />
                    </Col>
                    <Col> </Col>
                  </Row>
                  <br></br>
                  <Row>
                    {datos11 &&
                      datos11.map((item, index) => (
                        <Col key={index}>
                          <SellerPerformance item={item} />
                        </Col>
                      ))}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>
                      <Card>
                        {datos8 ? (
                          <VendedoresPorProductoChart datos={datos8} />
                        ) : null}
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        {datos10 ? <TortaChart datos={datos10} /> : null}
                      </Card>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col>
                      <Card>
                        {datos10 ? (
                          <Map
                            datos={datos10}
                            show={show}
                            categorias={categorias}
                          />
                        ) : null}
                        {datos10 ? (
                          <CustomersProductsSoldList
                            datos={datos10}
                            show={show}
                          />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductsKPI;
