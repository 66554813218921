/* eslint-disable no-useless-concat */
import React from "react";
import { Grid } from "@material-ui/core";
import TouchApp from "@material-ui/icons/TouchApp";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";

const PromotersList = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.customers ? (
            <MaterialTable
              columns={[
                { title: "ID", field: "id", width: "2%" },
                {
                  title: `${t("CustomerDetail.Modal.PromoterModal.List.Name")}`,
                  field: "name",
                  width: 20,
                },
                {
                  title: `${t(
                    "CustomerDetail.Modal.PromoterModal.List.Address"
                  )}`,
                  field: "address",
                  width: "80%",
                },
              ]}
              options={{
                toolbar: true,
                exportButton: false,
                selection: false,
                search: true,
                paging: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: {
                  fontSize: 13,
                  color: "#676A6C",
                  background: "#F5F5F5",
                },
                filtering: false,
              }}
              data={props.customers}
              title={
                t("CustomerDetail.Modal.PromoterModal.List.Select") as string
              }
              actions={[
                {
                  icon: () => <TouchApp />,
                  tooltip: `${t(
                    "CustomerDetail.Modal.PromoterModal.List.Select"
                  )}`,
                  onClick: (event, rowData) => props.edit(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: `${t(
                    "MaterialTable.emptyDataSourceMessage"
                  )}`,
                  addTooltip: `${t("MaterialTable.addTooltip")}`,
                  deleteTooltip: `${t("MaterialTable.deleteTooltip")}`,
                  editTooltip: `${t("MaterialTable.editTooltip")}`,
                  filterRow: {
                    filterTooltip: `${t("MaterialTable.filterTooltip")}`,
                  },
                  editRow: {
                    deleteText: `${t("MaterialTable.deleteText")}`,
                    cancelTooltip: `${t("MaterialTable.cancelTooltip")}`,
                    saveTooltip: `${t("MaterialTable.saveTooltip")}`,
                  },
                },
                grouping: {
                  placeholder: `${t("MaterialTable.placeholder")}`,
                  groupedBy: `${t("MaterialTable.groupedBy")}`,
                },
                header: {
                  actions: `${t("MaterialTable.actions")}`,
                },
                pagination: {
                  // eslint-disable-next-line no-useless-concat
                  labelDisplayedRows:
                    "{from}-{to}" +
                    `${t("MaterialTable.labelDisplayedRows")}` +
                    "{count}",
                  labelRowsSelect: `${t("MaterialTable.labelRowsSelect")}`,
                  labelRowsPerPage: `${t("MaterialTable.labelRowsPerPage")}`,
                  firstAriaLabel: `${t("MaterialTable.firstAriaLabel")}`,
                  firstTooltip: `${t("MaterialTable.firstTooltip")}`,
                  previousAriaLabel: `${t("MaterialTable.previousAriaLabel")}`,
                  previousTooltip: `${t("MaterialTable.previousTooltip")}`,
                  nextAriaLabel: `${t("MaterialTable.nextAriaLabel")}`,
                  nextTooltip: `${t("MaterialTable.nextTooltip")}`,
                  lastAriaLabel: `${t("MaterialTable.lastAriaLabel")}`,
                  lastTooltip: `${t("MaterialTable.lastTooltip")}`,
                },
                toolbar: {
                  addRemoveColumns: `${t("MaterialTable.addRemoveColumns")}`,
                  nRowsSelected: "{0}" + `${t("MaterialTable.nRowsSelected")}`,
                  showColumnsTitle: `${t("MaterialTable.showColumnsTitle")}`,
                  showColumnsAriaLabel: `${t(
                    "MaterialTable.showColumnsAriaLabel"
                  )}`,
                  exportTitle: `${t("MaterialTable.exportTitle")}`,
                  exportAriaLabel: `${t("MaterialTable.exportAriaLabel")}`,
                  searchTooltip: `${t("MaterialTable.searchTooltip")}`,
                  searchPlaceholder: `${t("MaterialTable.searchPlaceholder")}`,
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default PromotersList;
