import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  getCustomersOfferPieByProduct,
  getCustomersOfferPieByProductDetail,
  gettotalProductOfferByStatus,
} from "../../../services/chartsService";
import OffersDetailsProductList from "../../../shared/components/charts/OffersDetailsProductList";
import { Spinner } from "reactstrap"; // Asegúrate de que reactstrap está instalado

require("highcharts/modules/exporting")(Highcharts);

const ChartProductsOffer = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenDetalle, setIsModalOpenDetalle] = useState<boolean>(false);
  const [pieData, setpieData] = useState([]);
  const [totalY, settotalY] = useState(null);
  const [productName, setproductName] = useState("");
  const [referenceName, setreferenceName] = useState("");
  const [offers, setoffers] = useState([]);
  const [productsoffers, setproductsoffers] = useState([]);
  const [loading, setLoading] = useState<boolean>(true); // Estado para el loader

  useEffect(() => {
    gettotalProductOfferByStatus(0).then((response) => {
      if (response?.data) {
        let totalObj = response?.data.find((item: any) => item.total_y);
        settotalY(totalObj);
        setproductsoffers(response?.data);
      }
      setLoading(false); // Cambia el estado del loader a false una vez que los datos se cargan
    });
  }, []);

  const ProductOffersInformation = (name: string) => {
    setLoading(true); // Mostrar loader al cargar información del producto
    getCustomersOfferPieByProduct(0, name).then((response) => {
      setproductName(name);
      if (response?.data) {
        let totalObj = response?.data.find((item: any) => item.total_y);
        settotalY(totalObj);
        setpieData(response?.data);
        setIsModalOpen(true);
      }
      setLoading(false); // Ocultar loader después de cargar la información del producto
    });
  };

  const ProductOffersInformationDetail = (name: string) => {
    setLoading(true); // Mostrar loader al cargar detalles del producto
    setreferenceName(name);
    getCustomersOfferPieByProductDetail(0, productName, name).then(
      (response) => {
        if (response?.data) {
          setoffers(response?.data);
          setIsModalOpenDetalle(true);
        }
        setLoading(false); // Ocultar loader después de cargar los detalles del producto
      }
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setpieData([]);
  };
  const toggleModalDetalle = () => {
    setIsModalOpenDetalle(!isModalOpenDetalle);
  };

  const options = {
    colors: [
      "#E67E22",
      "#9900ff",
      "#6600cc",
      "#0099e6",
      "#0066ff",
      "#005EBB",
      "#ffe680",
      "#ff9900",
      "#ff3300",
      "#000d1a",
    ],
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Productos Oferta",
    },
    subtitle: {
      //@ts-ignore
      text: `Total: ${totalY?.total_y}`,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    xAxis: {
      type: "category",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              ProductOffersInformation(
                // @ts-ignore
                this.name
              );
            },
          },
        },
      },
    },
    series: [{ name: "€ Total", data: productsoffers }],
    loading: false,
  };

  const ChartPieoptions = {
    colors: [
      "#FF0000", // rojo
      "#FF4000", // naranja oscuro
      "#FF8000", // naranja
      "#FFBF00", // amarillo oscuro
      "#FFFF00", // amarillo
      "#BFFF00", // amarillo verdoso
      "#80FF00", // verde claro
      "#00FF00", // verde
      "#00FF80", // verde azulado
      "#00FFFF", // cian
      "#0080FF", // azul verdoso
      "#0000FF", // azul
      "#4000FF", // azul violeta
      "#8000FF", // violeta oscuro
      "#BF00FF", // magenta oscuro
      "#FF00FF", // magenta
      "#FF40FF", // rosa oscuro
      "#FF80FF", // rosa claro
      "#FFC0CB", // rosa
      "#FF8C69", // coral
      "#FF6EC7", // rosa claro
      "#8B0000", // marrón oscuro
      "#8B4513", // marrón
      "#808080", // gris
    ],
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: productName,
    },
    subtitle: {
      // @ts-ignore
      text: `TOTAL: ${totalY?.total_y}`,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: <b>{point.percentage:.1f} %</b>",
          useHTML: true,
        },
        showInLegend: true,
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              // @ts-ignore
              ProductOffersInformationDetail(this.name);
            },
          },
        },
      },
    },
    series: [{ data: pieData }],
    loading: false,
  };

  return (
    <>
      <div>
        {loading ? (
          <Spinner color="primary" /> // Componente de carga
        ) : (
          <>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <Modal size="xl" isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}></ModalHeader>
              <ModalBody>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={ChartPieoptions}
                />
              </ModalBody>
            </Modal>

            <Modal
              size="xl"
              isOpen={isModalOpenDetalle}
              toggle={toggleModalDetalle}
            >
              <ModalHeader toggle={toggleModalDetalle}></ModalHeader>
              <ModalBody>
                <OffersDetailsProductList
                  offers={offers}
                  referenceName={referenceName}
                  showCustomer={props.showCustomer} // O la condición necesaria
                />
              </ModalBody>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default ChartProductsOffer;
