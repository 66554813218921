import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import CustomerTypeList from "./Lists/CustomerTypeList";
import { getCustomerType } from "../../../../../services/customerService";

const EditCustomerType = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [users, setusers] = useState([]);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getCustomerType().then((response) => {
      console.table(response?.data.customerstypes);
      if (response?.data.ok) {
        setusers(response?.data.customerstypes);
      } else {
      }
    });
  };
  const edit = (item: any) => {
    const itemsend = {
      id: item.type_id,
    };
    props.editType(itemsend);
  };

  return (
    <>
      <Modal size="xs" isOpen={props.isOpen} toggle={props.toggle_customertype}>
        <ModalHeader>
          {t("CustomerDetail.Modal.EdiTypeProjectModal.Title")}
        </ModalHeader>
        <ModalBody>
          {users ? <CustomerTypeList users={users} edit={edit} /> : null}
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_customertype}>
            {" "}
            {t("CustomerDetail.Modal.EdiTypeProjectModal.List.CancelButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditCustomerType;
