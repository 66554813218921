import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Col,
  Input,
  InputGroup,
} from "reactstrap";

import ChargeListModal from "./ChargeListModal";
import { useTranslation } from "react-i18next";

const NewContactModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [name, setname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [charge, setcharge] = useState("");
  const [chargedescription, setchargedescription] = useState("");

  const [openchargeModal, setopenchargeModal] = useState(false);

  const name_handler = (e: any) => {
    setname(e.target.value);
  };
  const lastname_handler = (e: any) => {
    setlastname(e.target.value);
  };
  const email_handler = (e: any) => {
    setemail(e.target.value);
  };
  const phone_handler = (e: any) => {
    setphone(e.target.value);
  };
  const charge_handler = (item: any) => {
    setcharge(item.id);
    setchargedescription(item.description);
    setopenchargeModal(false);
  };

  const charge_toogle = () => {
    setopenchargeModal(!openchargeModal);
  };

  const NewContact = () => {
    if (name) {
      if (lastname) {
        if (email) {
          if (phone) {
            if (charge) {
              const item = {
                name: name,
                lastname: lastname,
                email: email,
                phone: phone,
                charge: charge,
              };
              props.addNewContact(item);
            } else {
              alert("Selecciona Cargo");
            }
          } else {
            alert("Ingresa Teléfono");
          }
        } else {
          alert("Ingresa Email");
        }
      } else {
        alert("Ingresa Apellido");
      }
    } else {
      alert("Ingresa Nombre");
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_contact}>
        <ModalHeader> {t("SharedComponents.NewContact.Title")}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                {t("SharedComponents.NewContact.NameField")}
              </Label>
              <Col sm={10}>
                <Input
                  id="formPromotora"
                  name="promotora"
                  placeholder={
                    t("SharedComponents.NewContact.NameField") as string
                  }
                  type="text"
                  onChange={name_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                {t("SharedComponents.NewContact.LastNameField")}
              </Label>
              <Col sm={10}>
                <Input
                  id="formPromotoraalias"
                  name="promotoraalias"
                  placeholder={
                    t("SharedComponents.NewContact.LastNameField") as string
                  }
                  type="text"
                  onChange={lastname_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                {t("SharedComponents.NewContact.EmailField")}
              </Label>
              <Col sm={10}>
                <Input
                  id="formPromotoraalias"
                  name="promotoraalias"
                  placeholder={
                    t("SharedComponents.NewContact.EmailField") as string
                  }
                  type="email"
                  onChange={email_handler}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                {t("SharedComponents.NewContact.FoneField")}
              </Label>
              <Col sm={10}>
                <Input
                  id="formNMCCode"
                  name="nmccode"
                  placeholder={
                    t("SharedComponents.NewContact.FoneField") as string
                  }
                  type="text"
                  onChange={phone_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                {t("SharedComponents.NewContact.ChargeField")}
              </Label>
              <Col sm={10}>
                <InputGroup>
                  <Input placeholder={chargedescription} disabled />

                  <Button color="secondary" onClick={charge_toogle}>
                    {t("SharedComponents.NewContact.SelectButton")}
                  </Button>
                </InputGroup>
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_contact}>
            {" "}
            {t("SharedComponents.NewContact.cancelButton")}
          </Button>
          <Button onClick={NewContact} color="success">
            {t("SharedComponents.NewContact.insertButton")}
          </Button>
        </ModalFooter>
      </Modal>
      {openchargeModal ? (
        <ChargeListModal
          isOpen={openchargeModal}
          charge_handler={charge_handler}
          charge_toogle={charge_toogle}
        />
      ) : null}
    </div>
  );
};

export default NewContactModal;
