import React from "react";
import { Grid, Button } from "@material-ui/core";
import MaterialTable, { Column } from "material-table";
import { utils, writeFile } from "xlsx";

// Definición de la interfaz para los datos procesados
interface DatosProcesados {
  material_description: string;
  product_code: string;
  product_description: string;
  family_description: string;
  category_description: string;
  mes: number;
  anio: number;
  total_sales: number;
  total_cantidad: number;
}

interface VentasPorMaterialidadListProps {
  datos: string | DatosProcesados[];
}

const VentasPorMaterialidadList: React.FC<VentasPorMaterialidadListProps> = (
  props
) => {
  let datosProcesados: DatosProcesados[] | null = null;
  let errorMessage: string | null = null;

  // Procesar datos JSON
  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos) as DatosProcesados[];
      // Asegurarse de que total_sales sea un número
      datosProcesados = datosProcesados.map((dato) => ({
        ...dato,
        total_sales:
          typeof dato.total_sales === "string"
            ? parseFloat(dato.total_sales)
            : dato.total_sales,
        total_cantidad:
          typeof dato.total_cantidad === "string"
            ? parseFloat(dato.total_cantidad)
            : dato.total_cantidad,
      }));
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      errorMessage = "Error al analizar JSON";
    }
  } else if (Array.isArray(props.datos)) {
    datosProcesados = props.datos.map((dato) => ({
      ...dato,
      total_sales:
        typeof dato.total_sales === "string"
          ? parseFloat(dato.total_sales)
          : dato.total_sales,
      total_cantidad:
        typeof dato.total_cantidad === "string"
          ? parseFloat(dato.total_cantidad)
          : dato.total_cantidad,
    }));
  } else {
    console.error("Formato de datos desconocido");
    errorMessage = "Formato de datos desconocido";
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  // Función para exportar a Excel
  const exportToExcel = () => {
    if (datosProcesados) {
      // Calcular totales por materialidad y categoría
      const resumenPorMaterialidad: any = {};

      datosProcesados.forEach((dato) => {
        if (!resumenPorMaterialidad[dato.material_description]) {
          resumenPorMaterialidad[dato.material_description] = {};
        }

        if (
          !resumenPorMaterialidad[dato.material_description][
            dato.category_description
          ]
        ) {
          resumenPorMaterialidad[dato.material_description][
            dato.category_description
          ] = 0;
        }

        resumenPorMaterialidad[dato.material_description][
          dato.category_description
        ] += dato.total_sales;
      });

      // Calcular totales por mes y año
      const resumenPorMes: any = {};

      datosProcesados.forEach((dato) => {
        const key = `${dato.anio}-${dato.mes}`;
        if (!resumenPorMes[key]) {
          resumenPorMes[key] = 0;
        }
        resumenPorMes[key] += dato.total_sales;
      });

      // Crear datos de resumen por materialidad en formato adecuado para Excel
      const resumenMaterialidadExcel = [];
      for (const material in resumenPorMaterialidad) {
        for (const categoria in resumenPorMaterialidad[material]) {
          resumenMaterialidadExcel.push({
            "Descripción del Material": material,
            "Descripción de la Categoría": categoria,
            "Total Ventas": resumenPorMaterialidad[material][categoria],
          });
        }
      }

      // Crear datos de resumen por mes en formato adecuado para Excel
      const resumenMesExcel = [];
      for (const key in resumenPorMes) {
        const [anio, mes] = key.split("-");
        resumenMesExcel.push({
          Año: parseInt(anio),
          Mes: parseInt(mes),
          "Total Ventas": resumenPorMes[key],
        });
      }

      // Crear una hoja de trabajo para el resumen por materialidad
      const wsResumenMaterialidad = utils.json_to_sheet(
        resumenMaterialidadExcel
      );

      // Crear una hoja de trabajo para el resumen por mes
      const wsResumenMes = utils.json_to_sheet(resumenMesExcel);

      // Crear una hoja de trabajo para los datos originales
      const wsDatos = utils.json_to_sheet(datosProcesados);

      // Crear un libro de trabajo
      const wb = utils.book_new();
      utils.book_append_sheet(wb, wsDatos, "Ventas");
      utils.book_append_sheet(
        wb,
        wsResumenMaterialidad,
        "Resumen por Materialidad"
      );
      utils.book_append_sheet(wb, wsResumenMes, "Resumen por Mes");

      // Exportar el libro de trabajo
      writeFile(wb, "VentasPorMaterialidad.xlsx");
    }
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: "20px" }}
          >
            Exportar a Excel
          </Button>
          {datosProcesados ? (
            <MaterialTable
              columns={
                [
                  {
                    title: "Descripción del Material",
                    field: "material_description",
                  },
                  { title: "Código del Producto", field: "product_code" },
                  {
                    title: "Descripción del Producto",
                    field: "product_description",
                  },
                  {
                    title: "Descripción de la Familia",
                    field: "family_description",
                  },
                  {
                    title: "Descripción de la Categoría",
                    field: "category_description",
                  },
                  { title: "Mes", field: "mes", type: "numeric" },
                  { title: "Año", field: "anio", type: "numeric" },
                  {
                    title: "Cantidad Facturada",
                    field: "total_cantidad",
                    render: (rowData) => Math.round(rowData.total_cantidad),
                  },
                  {
                    title: "Total Ventas",
                    field: "total_sales",
                    type: "currency",
                  },
                ] as Column<DatosProcesados>[]
              }
              options={{
                toolbar: true,
                exportButton: false, // Desactivado porque estamos añadiendo un botón personalizado
                search: true,
                paging: true,
                pageSize: 100,
                pageSizeOptions: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={datosProcesados}
              title="Ventas por Materialidad"
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  filterRow: { filterTooltip: "Filtrar" },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: { actions: "Acciones" },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default VentasPorMaterialidadList;
