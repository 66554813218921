import React from "react";
import "../../shared/components/style.scss";

const CustomerCategoriesBadges = (props: any) => {
  return (
    <div>
      {props.customerCategories.map(
        (category: {
          id: number;
          description: string;
          customer_name: string;
        }) => (
          <div key={category.id} className="badge-container">
            <span className="badge-category">{category.description}</span>
            <div className="badge-name-container">
              <span className="badge-name">{category.customer_name}</span>
              <button
                className="badge-close-btn"
                onClick={() => props.handleDelete(category.id)}
              >
                &times;
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CustomerCategoriesBadges;
