import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./styles.scss";
import * as FaIcons from "react-icons/fa";

import { useTranslation } from "react-i18next";
import { getsellers } from "../services/chartsService";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import SellersList from "../shared/components/SellersList";

import {
  getAlerts,
  getReportsByDate,
  getReportsByUser,
  getReportsByUserDate,
  getReportsToday,
  newEvent,
} from "../services/reportsService";
import SellerHeaderReports from "../shared/components/SellerHeaderReports";
import Reports2 from "../shared/components/Reports2";

import "moment/locale/es";
import EditAgentModal from "./components/Projects/ProjectDetail/Modals/EditAgentModal";
import EditPromotoreModal from "./components/Projects/ProjectDetail/Modals/EditPromotoreModal";
moment.locale("es");

const localizer = momentLocalizer(moment);

interface IEvent {
  title: string;
  start: Date;
  end: Date;
  fullname?: string;
  customerName?: string;
  id_user: string;
  // ...otros campos que puedan tener tus eventos
}

interface IUserColors {
  [key: string]: string;
}

interface FullscreenDiv extends HTMLDivElement {
  mozRequestFullScreen?: () => Promise<void>;
  webkitRequestFullscreen?: () => Promise<void>;
  msRequestFullscreen?: () => Promise<void>;
}

/*
interface FullscreenDocument extends Document {
  mozCancelFullScreen?: () => Promise<void>;
  webkitExitFullscreen?: () => Promise<void>;
  msExitFullscreen?: () => Promise<void>;
} */

const userColors: IUserColors = {
  "1": "#FF5733", // Rojo Naranja
  "2": "#C70039", // Rojo Granate
  "3": "#900C3F", // Rojo Oscuro
  "4": "#581845", // Púrpura Oscuro
  "5": "#FFC300", // Amarillo Dorado
  "6": "#FF5733", // Naranja
  "7": "#FF6384", // Rosa
  "8": "#36A2EB", // Azul Claro
  "9": "#9966FF", // Púrpura Claro
  "10": "#4BC0C0", // Turquesa
  "11": "#FFCE56", // Amarillo Claro
  "12": "#2ECC71", // Verde Claro
  "13": "#1F618D", // Azul Marino
  "14": "#F39C12", // Naranja Amarillento
  "15": "#D35400", // Naranja Oscuro
  "16": "#7D3C98", // Púrpura
  "17": "#AAB7B8", // Gris Claro
  "18": "#34495E", // Azul Oscuro
  "19": "#16A085", // Verde Agua
  "20": "#138D75", // Verde Esmeralda
  "21": "#154360", // Azul Petróleo
  "22": "#0E6251", // Verde Bosque
  "23": "#7B241C", // Marrón
  "24": "#4A235A", // Púrpura Oscuro
  "25": "#1B2631", // Azul Noche
  "26": "#17202A", // Negro Azulado
  "27": "#212F3D", // Azul Grisáceo
  "28": "#784212", // Marrón Tierra
  "29": "#6E2C00", // Marrón Oscuro
  "30": "#7E5109", // Marrón Caramelo
};

const eventStyleGetter = (event: { id_user: string | number }) => {
  const backgroundColor = userColors[event.id_user] || "defaultColor";
  const style = {
    backgroundColor: backgroundColor,
    borderRadius: "0px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
  };
  return { style: style };
};

const EventComponent: React.FC<{ event: IEvent }> = ({ event }) => {
  return (
    <div>
      {event.fullname && <div>{event.fullname}</div>}
      {/* Aquí puedes agregar más campos si lo necesitas */}
    </div>
  );
};
const ReportsSellers = () => {
  const { t } = useTranslation(["home", "projects"]);

  const [start_date, setstart_date] = useState<any | null>();
  const [end_date, setend_date] = useState<any | null>();

  const [events, setEvents] = useState([]);

  const [datos16, setdatos16] = useState([]);
  const [datos, setdatos] = useState([]);

  const [id_seller, setid_seller] = useState<any | null>();

  const [reports, setreports] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();

  const [newEventModalOpen, setNewEventModalOpen] = useState(false);
  const [newEventDate, setNewEventDate] = useState<any | null>();
  const [newEventTime, setNewEventTime] = useState<Date | null>(new Date());

  const [newEventDescription, setNewEventDescription] = useState("");
  const [newEventUser, setNewEventUser] = useState("");
  const [newEventCustomer, setNewEventCustomer] = useState("");

  const [openEditAgentModal, setEditAgentModal] = useState(false);

  const [openEditPromotoraModal, setEditPromotoraModal] = useState(false);

  const [username, setusername] = useState("");
  const [customername, setcustomername] = useState("");

  const [username_id, setusernam_id] = useState("");
  const [customername_id, setcustomername_id] = useState("");

  const calendarRef = useRef<FullscreenDiv>(null);

  const [chartsyear, setchartsyear] = useState(new Date().getFullYear());
  //const documentWithFullscreen = document as FullscreenDocument;

  const handleEventSelect = (event: IEvent) => {
    setSelectedEvent(event);
    setModalOpen(true); // Abre el modal al seleccionar un evento
  };

  useEffect(() => {
    refreshCustomers();
    getsellers(chartsyear);
    getalert();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos]);

  const toggleNewEventModal = () => setNewEventModalOpen(!newEventModalOpen);

  const handleSaveNewEvent = async () => {
    // Verifica que se hayan proporcionado la fecha, la hora y la descripción
    if (
      newEventDate &&
      newEventTime &&
      newEventDescription.trim() &&
      username_id
    ) {
      // Crea un objeto Date con la fecha y la hora seleccionadas
      const eventDateTime = new Date(
        newEventDate.getFullYear(),
        newEventDate.getMonth(),
        newEventDate.getDate(),
        newEventTime.getHours(),
        newEventTime.getMinutes()
      );

      // Prepara el objeto con los datos del evento
      const eventData = {
        selectedDate: eventDateTime
          .toISOString()
          .slice(0, 19)
          .replace("T", " "), // Formato compatible con SQL DATETIME
        description: newEventDescription,
        userid: username_id,
        id_customer: customername_id || null, // Puede ser null si no se seleccionó cliente
      };

      try {
        const result = await newEvent(eventData);
        if (result) {
          getalert();
        }
        // Si todo va bien, puedes cerrar el modal y actualizar tu estado/UI como sea necesario
        setNewEventDescription("");
        setusernam_id("");
        setNewEventDate(null);
        setNewEventTime(null);
        setNewEventModalOpen(false);
        // Opcional: actualizar la lista de eventos, etc.
      } catch (error) {
        // Maneja el error, por ejemplo, mostrando un mensaje al usuario
        console.log(error);
      }

      // Envía los datos del evento al backend
      // Puedes usar fetch o Axios para enviar una solicitud POST, por ejemplo
    } else {
      // Alerta al usuario de que faltan datos necesarios
      alert("Por favor, completa todos los campos obligatorios.");
    }
  };

  const toggle_agente = () => {
    setEditAgentModal(!openEditAgentModal);
  };

  const getalert = () => {
    getAlerts()
      .then((response) => {
        if (response?.data.ok) {
          console.table(response.data.contactResults);
          const formattedEvents = response.data.contactResults.map(
            (alert: {
              description: any;
              date_alert: string | number | Date;
              id: any;
              id_customer: any;
              id_user: any;
              fullname: any;
              name: any;
              id_calendar: any;
              id_event: any;
              type: any;
            }) => ({
              title: alert.description,
              start: new Date(alert.date_alert),
              end: new Date(alert.date_alert), // o tu fecha de fin, si la tienes
              // Agregar información adicional aquí
              id: alert.id,
              id_customer: alert.id_customer,
              id_user: alert.id_user,
              fullname: alert.fullname, // nombre completo del usuario
              customerName: alert.name, // nombre del cliente
              id_calendar: alert.id_calendar,
              id_event: alert.id_event,
              type: alert.type,
            })
          );
          setEvents(formattedEvents);
          setEvents(formattedEvents);
        }
      })
      .catch((error) => {
        console.error("Error al obtener alertas:", error);
      });
  };

  const refreshCustomers = () => {
    getReportsToday().then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.reportResults);
        setreports(response?.data.reportResults);
      }
    });
    getsellers(chartsyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos16(response?.data);
      } else {
      }
    });
  };

  const offer_handler = (data: any) => {
    setdatos(data);
    loadCharts(data);
  };

  const loadCharts = (data: any) => {
    setid_seller(data.id);
    getReportsByUser(data.id).then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.reportResults);
        setreports(response?.data.reportResults);
      }
    });
  };

  const onchangeStartDate = (date: any) => {
    setstart_date(date);
  };

  const onchangeDate = (date: any) => {
    setend_date(date);
  };

  const onClickGenerarReporte = () => {
    if (id_seller) {
      getReportsByUserDate(id_seller, start_date, end_date).then((response) => {
        if (response?.data.ok) {
          console.table(response?.data.reportResults);
          setreports(response?.data.reportResults);
        }
      });
    } else {
      getReportsByDate(start_date, end_date).then((response) => {
        if (response?.data.ok) {
          console.table(response?.data.reportResults);
          setreports(response?.data.reportResults);
        }
      });
    }
  };

  const getcustomerid = (item: any) => {
    if (item.type === 1) {
      window.open(`/layout/customer/${item.customerid}`);
    }
    if (item.type === 2) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 3) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 4) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 5) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 6) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 7) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 8) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 9) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 10) {
      window.open(`/layout/channel/${item.customerid}`);
    }
    if (item.type === 11) {
      window.open(`/layout/channel/${item.customerid}`);
    }
  };
  /*
  const toggleFullScreen = () => {
    const calendarElement = calendarRef.current;

    if (calendarElement) {
      if (!document.fullscreenElement) {
        // Intenta entrar en pantalla completa
        if (calendarElement.requestFullscreen) {
          calendarElement.requestFullscreen();
        } else if (calendarElement.mozRequestFullScreen) {
          // Firefox
          calendarElement.mozRequestFullScreen();
        } else if (calendarElement.webkitRequestFullscreen) {
          // Chrome, Safari y Opera
          calendarElement.webkitRequestFullscreen();
        } else if (calendarElement.msRequestFullscreen) {
          // IE/Edge
          calendarElement.msRequestFullscreen();
        }
        calendarElement.classList.add("fullscreen-calendar");
      } else {
        // Intenta salir de pantalla completa
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (documentWithFullscreen.mozCancelFullScreen) {
          // Firefox
          documentWithFullscreen.mozCancelFullScreen();
        } else if (documentWithFullscreen.webkitExitFullscreen) {
          // Chrome, Safari y Opera
          documentWithFullscreen.webkitExitFullscreen();
        } else if (documentWithFullscreen.msExitFullscreen) {
          // IE/Edge
          documentWithFullscreen.msExitFullscreen();
        }
        calendarElement.classList.remove("fullscreen-calendar");
      }
    }
  }; */

  const editAgent = (item: any) => {
    console.table(item);

    setusername(item.name + " " + item.last_name);
    setusernam_id(item.id);
    toggle_agente();
  };

  const toggle = () => {
    setEditPromotoraModal(!openEditPromotoraModal);
  };

  const editPromoter = (item: any) => {};

  const addCustomerCategory = (item: any, type_id: number) => {
    setcustomername(item.name);
    setcustomername_id(item.id);
    toggle();
  };

  const messages = {
    allDay: "Todo el día",
    previous: "Anterior",
    next: "Próximo",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    // ... cualquier otra etiqueta que necesites traducir
  };

  //const selectCustomer = () => {};

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            {t("reports.projectsTitle")}
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-8">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>{t("reports.projectsBreadcrum")}</BreadcrumbItem>
              <BreadcrumbItem>{t("reports.listBreadcrum")}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Button color="primary" onClick={toggleNewEventModal}>
                  Agregar Evento
                </Button>
              </CardHeader>
              <CardBody>
                <CardText>
                  <div ref={calendarRef}>
                    <Calendar
                      localizer={localizer}
                      events={events}
                      startAccessor="start"
                      endAccessor="end"
                      eventPropGetter={eventStyleGetter}
                      onSelectEvent={handleEventSelect}
                      style={{ height: 600 }}
                      components={{
                        event: EventComponent, // Usar tu componente personalizado aquí
                      }}
                      messages={messages}

                      // ... otras props
                    />
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  {" "}
                  {datos16 ? (
                    <SellersList
                      datos={datos16}
                      offer_handler={offer_handler}
                    />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs="10">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  <Row>
                    <Col xs="4">
                      <SellerHeaderReports datos={datos} />
                    </Col>

                    <Col xs="2">
                      <FormGroup>
                        <div className="datepicker-container">
                          <DatePicker
                            selected={start_date}
                            onChange={(date) => onchangeStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            locale={es}
                            placeholderText="Fecha Desde"
                            className="form-control"
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col xs="2">
                      {" "}
                      <FormGroup>
                        <div className="datepicker-container">
                          <DatePicker
                            selected={end_date}
                            onChange={(date) => onchangeDate(date)}
                            dateFormat="yyyy-MM-dd"
                            locale={es}
                            placeholderText="Fecha Hasta"
                            className="form-control"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        <div className="datepicker-container">
                          <Button
                            onClick={() => {
                              onClickGenerarReporte();
                            }}
                          >
                            Buscar Reportes
                          </Button>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>
                      <Card>
                        {reports ? (
                          <Reports2
                            reports={reports}
                            getcustomerid={getcustomerid}
                          />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            Detalles del Evento
          </ModalHeader>
          <ModalBody>
            {selectedEvent && (
              <>
                <InputGroup className="mb-2">
                  <InputGroupText>Usuario</InputGroupText>
                  <Input value={selectedEvent.fullname} readOnly />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroupText>Título</InputGroupText>
                  <Input value={selectedEvent.title} readOnly />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroupText>Cliente</InputGroupText>
                  <Input value={selectedEvent.customerName} readOnly />
                </InputGroup>
                {/* ... otros detalles ... */}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModalOpen(!modalOpen)}>
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={newEventModalOpen} toggle={toggleNewEventModal}>
          <ModalHeader toggle={toggleNewEventModal}>
            Agregar Nuevo Evento
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="newEventDate">Fecha del Evento</Label>
                  <DatePicker
                    id="newEventDate"
                    selected={newEventDate}
                    onChange={(date) => setNewEventDate(date)}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    locale={es}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="newEventTime">Hora del Evento</Label>
                  <DatePicker
                    id="newEventTime"
                    selected={newEventTime}
                    onChange={(time) => setNewEventTime(time)}
                    className="form-control"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="h:mm aa"
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label for="newEventDescription">Descripción</Label>
              <Input
                type="textarea"
                id="newEventDescription"
                value={newEventDescription}
                onChange={(e) => setNewEventDescription(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Input
                  placeholder="Usuario"
                  value={username}
                  onChange={(e) => setNewEventUser(e.target.value)}
                  readOnly
                />
                <Button onClick={toggle_agente}>Seleccionar</Button>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <Input
                  placeholder="Cliente"
                  value={customername}
                  onChange={(e) => setNewEventCustomer(e.target.value)}
                  readOnly
                />
                <Button onClick={toggle}>Seleccionar</Button>
              </InputGroup>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSaveNewEvent}>
              Guardar
            </Button>{" "}
            <Button color="secondary" onClick={toggleNewEventModal}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>

        {openEditAgentModal ? (
          <EditAgentModal
            isOpen={openEditAgentModal}
            toggle_agente={toggle_agente}
            editAgent={editAgent}
          />
        ) : null}

        {openEditPromotoraModal ? (
          <EditPromotoreModal
            isOpen={openEditPromotoraModal}
            toggle={toggle}
            editPromoter={editPromoter}
            addCustomerCategory={addCustomerCategory}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ReportsSellers;
