import React, { useEffect, useRef, useState } from "react";
import backgroundImage from "./../../assets/user-png-33842.png";

import assistantIcon from "./../../assets/assistant.png";
// Definición de la interfaz para los props del componente de chat
interface ChatMessage {
  id: number;
  threadId: string;
  userId: number;
  id_assistant: string;
  content: string;
  fromid: "user" | "assistant";
  responseType: "text" | "image" | "url";
  createdAt: string;
  updatedAt: string;
  id_enterprise: number;
  assistantDescription: string;
  username: string;
}

interface AssistantChatProps {
  chats: ChatMessage[];
}

const AssistantChat = ({ chats }: AssistantChatProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chats]); // Este efecto se ejecuta cada vez que 'chats' cambia

  const handleImageClick = (imageSrc: string) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const renderTextContent = (text: string) => {
    const textSegments = text.split("\n").map((item, index) => (
      <React.Fragment key={index}>
        <span>{item}</span>
        <br />
      </React.Fragment>
    ));
    return <div>{textSegments}</div>;
  };
  const renderContent = (chat: ChatMessage) => {
    switch (chat.responseType) {
      case "text":
        return renderTextContent(chat.content);
      case "image":
        return (
          <img
            src={chat.content}
            alt="Imagen enviada"
            onClick={() => handleImageClick(chat.content)}
            style={{ cursor: "pointer" }}
          />
        );
      case "url":
        return (
          <a href={chat.content} target="_blank" rel="noopener noreferrer">
            {chat.content}
          </a>
        );
      default:
        return <span>Contenido no soportado</span>;
    }
  };

  return (
    <div
      className="chat-container"
      //@ts-ignore
      style={{ "--background-image": `url(${backgroundImage})` }}
    >
      {chats.map((chat) => (
        <div key={chat.id} className={`message ${chat.fromid}`}>
          {chat.fromid === "assistant" ? (
            <img
              src={assistantIcon}
              alt="Assistant Icon"
              className="assistant-icon"
            />
          ) : (
            <img src={backgroundImage} alt="User Icon" className="user-icon" />
          )}
          <div className="message-content">{renderContent(chat)}</div>
          <div className="message-info">
            <span>
              {chat.fromid === "user"
                ? chat.username
                : chat.assistantDescription}
            </span>
            <span> {new Date(chat.createdAt).toLocaleTimeString()}</span>
          </div>
        </div>
      ))}

      {isModalOpen && (
        <div
          className="modal"
          onClick={closeModal}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={selectedImage!}
            alt="Imagen ampliada"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </div>
      )}
    </div>
  );
};

export default AssistantChat;
