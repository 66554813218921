import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

type ChartOptions = {
  chart: {
    type: string;
  };
  credits: {
    enabled: boolean;
  };
  title: {
    text: string;
  };
  yAxis: {
    min: number;
    title: {
      text: string;
    };
  };
  xAxis: {
    categories: string[];
  };
  series: {
    name: string;
    data: number[];
  }[];
};

type DatosProducto = {
  anio: number;
  nombreProducto: string;
  totalVentas: number;
  cantidad: number;
}[];

const ProductSalesByYearChart = (props: any) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Ventas por Producto y Año",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Ventas Totales",
      },
    },
    series: [],
  });

  useEffect(() => {
    let datosConvertidos: DatosProducto = [];

    if (typeof props.datos === "string") {
      try {
        datosConvertidos = JSON.parse(props.datos) as DatosProducto;
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else if (Array.isArray(props.datos)) {
      // Si props.datos ya es un arreglo, usarlo directamente
      datosConvertidos = props.datos as DatosProducto;
    } else {
      // Si no es una cadena ni un arreglo, probablemente hay un error
      console.error("Formato de datos desconocido");
      return;
    }

    // Extrayendo los años y los nombres de los productos
    const anios = [
      ...new Set(datosConvertidos.map((item) => item.anio.toString())),
    ];
    const productos = [
      ...new Set(datosConvertidos.map((item) => item.nombreProducto)),
    ];

    // Creando las series para el gráfico
    const series = productos.map((producto) => ({
      name: producto,
      data: anios.map((anio) => {
        const item = datosConvertidos.find(
          (d) => d.anio.toString() === anio && d.nombreProducto === producto
        );
        return item ? item.totalVentas : 0;
      }),
    }));

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        categories: anios,
      },
      series: series,
    }));
  }, [props.datos]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default ProductSalesByYearChart;
