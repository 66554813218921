import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
//import "../Login.scss";
import backgroundImage from "../assets/login-bg2.png";
import "./styles.scss";
import { signIn } from "../services/loginService";
import { AuthContext } from "../AuthContext";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Login2 = (props: any) => {
  const history = useHistory();
  const { setIsAuthenticated } = useContext(AuthContext);
  const { t, i18n } = useTranslation(["home", "login"]);

  const [email, setemail] = useState("");
  const [password, setpasword] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onClickLanguageChange = (e: any) => {
    const language = e.target.value;
    i18n.changeLanguage(language); //change the language
    localStorage.setItem("language", language);
  };

  const emailChanged = (e: any) => {
    setemail(e.target.value);
  };

  const passwordChanged = (e: any) => {
    setpasword(e.target.value);
  };

  const login = (e: any) => {
    e.preventDefault();
    const item = {
      email,
      password,
    };
    signIn(item).then((response) => {
      if (response.data.ok) {
        setIsAuthenticated(true);
        history.push("/layout/home");
      } else {
        alert(`${t("login.message")}`);
      }
    });
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div className="login">
      <img
        src={backgroundImage}
        alt="Fondo de inicio de sesión"
        className="login__bg"
      />

      <form className="login__form" onSubmit={login}>
        <h1 className="login__title">
          {t("login.logintitle")} <strong>NeoBizz</strong>
        </h1>

        <div className="login__inputs">
          <div className="login__box">
            <input
              type="text"
              placeholder={t("login.usernamePlaceHolder") as string}
              className="login__input"
              id="username"
              onChange={emailChanged}
            />
            <i className="ri-mail-fill"></i>
          </div>

          <div className="login__box">
            <input
              type="password"
              placeholder={t("login.passwordPlaceHolder") as string}
              className="login__input"
              id="password"
              onChange={passwordChanged}
            />
            <i className="ri-lock-2-fill"></i>
          </div>
        </div>

        <div className="login__check">
          {/* Aquí puedes agregar opciones como "Recuérdame" si lo necesitas */}
        </div>

        <button type="submit" className="login__button">
          {t("login.buttomLogin") as string}
        </button>

        <div className="version">
          Version 1.10.0.1 - {t("login.updatedOn")} 02-09-2024
        </div>
        <div>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="language-dropdown"
          >
            <DropdownToggle caret className="language-toggle">
              {i18n.language.toUpperCase()}
            </DropdownToggle>
            <DropdownMenu className="language-menu">
              <DropdownItem value="en" onClick={onClickLanguageChange}>
                English
              </DropdownItem>
              <DropdownItem value="es" onClick={onClickLanguageChange}>
                Español
              </DropdownItem>
              <DropdownItem value="fr" onClick={onClickLanguageChange}>
                Français
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </form>

      {/* Aquí puedes agregar el dropdown para cambio de idioma */}
    </div>
  );
};

export default Login2;
