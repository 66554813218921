import React from "react";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";

const SellerHeader = (props: any) => {
  return (
    <div>
      <Card>
        <CardBody>
          <CardText>
            <Row>
              <Col xs="4">
                <div style={{ fontSize: "0.9em", color: "#34495E" }}>
                  <strong>Nombre:</strong>
                </div>
              </Col>
              <Col>
                <span style={{ color: "#5D6D7E" }}>
                  {props.datos.full_name}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <div style={{ fontSize: "0.9em", color: "#34495E" }}>
                  <strong>Ventas Acumuladas:</strong>
                </div>
              </Col>
              <Col>
                <span style={{ color: "#27AE60" }}>
                  € {props.datos.total_ventas}
                </span>
              </Col>
            </Row>
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default SellerHeader;
