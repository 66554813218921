import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { Collapse } from "reactstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { obtenerCategorias } from "../services/customerService";

interface Categoria {
  type_id: number;
  description: string;
  // Agrega aquí otras propiedades según sean necesarias
}

const Sidebar = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    // Supongamos que tienes los datos del usuario almacenados en algún lugar
    obtenerCategorias().then((data) => {
      setCategorias(data);
    });
  }, []);

  const menuCanales = () => {
    setIsOpen(false);
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div>
      {props.active ? (
        <>
          <div className="nav-header-sidebar ">
            <span>
              <img
                className="profile-picture"
                src="https://neobizztech.net/images/1542594631448.jpeg"
                alt="rank"
              ></img>
            </span>

            <div>
              <span>
                <strong className="font-bold">
                  {localStorage.getItem("enterprisename")}
                </strong>
              </span>
            </div>
          </div>
          <div className="sidebar">
            <ul>
              <li>
                <NavLink
                  to="/layout/home"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaHome className="me-2" /> {t("sidebar.Dashboard")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/assistants"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaBrain className="me-2" /> {t("sidebar.Assistants")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/sellers"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaChartBar className="me-2" /> {t("sidebar.Sellers")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/productskpi"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaChartArea className="me-2" />{" "}
                  {t("sidebar.Productskpi")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/layout/materials"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaBarcode className="me-2" />{" "}
                  {t("sidebar.Materials")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/layout/reports"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaTasks className="me-2" /> {t("sidebar.Reports")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/offers"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaMoneyBill className="me-2" /> {t("sidebar.Offers")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/customersall"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaUser className="me-2" /> {t("sidebar.Customers")}
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/layout/contactsall"
                  className="rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaAddressBook className="me-2" />{" "}
                  {t("sidebar.Contacts")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/customers"
                  className=" rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaBuilding className="me-2" />
                  {t("sidebar.Projects")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/layout/products"
                  className=" rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                  onClick={menuCanales}
                >
                  <FaIcons.FaBarcode className="me-2" />
                  {t("sidebar.Products")}
                </NavLink>
              </li>

              <div>
                <li>
                  <button
                    className=" rounded py-2 w100 d-inline-block px-3 buttonSideBar"
                    onClick={toggle}
                  >
                    <FaIcons.FaAngleRight className="me-2" />
                    {t("sidebar.Channels")}
                  </button>
                </li>

                <Collapse isOpen={isOpen} className="menuBorderLeft">
                  {categorias.map((categoria) => (
                    <li key={categoria.type_id}>
                      <NavLink
                        to={`/layout/${categoria.description.toLowerCase()}`}
                        className="rounded py-2 w100 d-inline-block px-3"
                        activeClassName="active"
                      >
                        <FaIcons.FaAngleRight className="me-2" />
                        {capitalizeFirstLetter(categoria.description)}
                      </NavLink>
                    </li>
                  ))}
                  {/* <li>
                    <NavLink
                      to="/layout/promoters"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Promoters")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/installers"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Installers")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/arquitects"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Arquitects")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/decorators"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Decorators")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/dealers"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Dealers")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/hotels"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Hotels")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/builders"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.ConstructionCompany")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/retails"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.DIY1")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/hardwarestores"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.DIY")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/layout/traditionals"
                      className=" rounded py-2 w100 d-inline-block px-3"
                      activeClassName="active"
                    >
                      <FaIcons.FaAngleRight className="me-2" />
                      {t("sidebar.Traditional")}
                    </NavLink>
                  </li> */}
                </Collapse>
              </div>

              <li>
                <NavLink
                  to="/layout/users"
                  className=" rounded py-2 w100 d-inline-block px-3"
                  activeClassName="active"
                >
                  <FaIcons.FaUsers className="me-2" />
                  {t("sidebar.Users")}
                </NavLink>
              </li>
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default Sidebar;
