import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Button,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { getCustomers, addCustomer } from "../services/customerService";
import MapArquitects from "./components/shared/Map/Map";
//import ListArquitects from "./components/Arquitects/ListArquitects/ListArquitects";
import ListArquitects from "./components/shared/List/List";
import NewArquitectModal from "./components/Arquitects/NewArquitect/NewArquitectModal";

const Arquitectos = () => {
  const history = useHistory();
  const [customers, setcustomers] = useState([]);

  const [openNewPromoterModal, setopenNewPromoterModal] = useState(false);
  const toggle = () => {
    setopenNewPromoterModal(!openNewPromoterModal);
  };

  useEffect(() => {
    refreshCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddNewPromoter = (item: any) => {
    addCustomer(item).then((data) => {
      refreshCustomers();
    });
  };

  const show = (data: any) => {
    window.open(`/layout/channel/${data.id}`);
  };

  const refreshCustomers = () => {
    getCustomers(4).then((response) => {
      if (response?.data.ok) {
        setcustomers(response?.data.customersResults);
        setopenNewPromoterModal(false);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaDraftingCompass className="me-2" />
            Arquitectos
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Arquitectos</BreadcrumbItem>
              <BreadcrumbItem>Lista</BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="col-lg-2">
            <Col className="text-center text-md-right">
              <Button size="sm" block onClick={toggle}>
                Nuevo Arquitecto
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Georeferencia</CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              <MapArquitects customers={customers} show={show} />
              {customers ? (
                <ListArquitects customers={customers} show={show} />
              ) : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
      {openNewPromoterModal ? (
        <NewArquitectModal
          isOpen={openNewPromoterModal}
          toggle={toggle}
          AddNewPromoter={AddNewPromoter}
        />
      ) : null}
    </div>
  );
};

export default Arquitectos;
