import React, { createContext, useState, useEffect, ReactNode } from "react";
import { checkToken } from "./services/loginService";

// Define el tipo para el contexto
interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

// Crea el contexto con un valor predeterminado
export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  setIsAuthenticated: () => {}, // Función vacía como marcador de posición
});

// Define el tipo de las props para AuthProvider
interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkToken().then((response) => {
      setIsAuthenticated(response?.data?.msg);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <div>Cargando...</div>; // Muestra algo mientras carga
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
