import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Col,
  Input,
  Progress
} from "reactstrap";
import axios from "axios";

const NewImageModal = (props) => {
  
  const [file, setFile] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  
  
    const handleChange =(event) =>{
      setFile(event.target.files[0]);
    }

    const handleSubmit = async (event)=> {
      event.preventDefault();
      const url = "https://neobizztech.net/rest/uploadFile.php";
      const formData = new FormData();
      formData.append('query', 'image');
      formData.append('customer_id', props.customer_id);
      formData.append('token', localStorage.getItem('token'));
      formData.append('userid', localStorage.getItem('userid'));
      formData.append('enterprise', 1);
      formData.append("file", file);
      formData.append("fileName", file.name);
     

      try {

       const res = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    setUploadPercentage(
                        parseInt(
                            Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        )
                    );

                    // Clear percentage
                    setTimeout(() => setUploadPercentage(0), 10000000);
                }
       });
        
        console.log(res.data);
        props.toggle_images();
        props.addNewImage();
        
      } catch (err) {
        alert(err);
      }
    }


  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_images}>
        <ModalHeader>Nueva Imagen</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup row>
             
              <Col sm={10}>
                <Input
                  id="newimage"
                  name="image"
                  placeholder="Ingresa Nombre"
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                />
              </Col>
              <Col sm={2}>
                 <Button type="submit" className="btn btn-success" block>SUBIR</Button>
              </Col>
             
            </FormGroup>
            <FormGroup row>
              <Col sm={12}> <Progress
                color="danger"
                value={uploadPercentage}
              /></Col>
             
            </FormGroup>
           
           </Form>
        </ModalBody>
         <ModalFooter>
          <Button onClick={props.toggle_images}>CANCELAR</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewImageModal;