import axios from "axios";
import config from "./confserver.json";


export const getContacts = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/contacts', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getContactsAll = async () => {
  const data = {
    token: localStorage.getItem("token"),

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/contactsall', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteContacts = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/deletecontact', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getCharges = async () => {
  const data = {
    token: localStorage.getItem("token")

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/contacttypes', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const newContact = async (item:any,id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    name: item.name,
    last_name: item.lastname,
    phone: item.phone,
    email: item.email,
    type_contacts: item.charge,
    id_customer: id,
    id_enterprise :1 

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/newcontact', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}