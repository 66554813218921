/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  addColaborator,
  deletecolaborator,
  deleteCustomer,
  deleteCustomerCategory,
  editAdressCustomer,
  editProjectData,
  getcolaborators,
  getCustomerById,
  getCustomerCategories,
  newCustomerCategory,
} from "../services/customerService";
import { getReports, newReport } from "../services/reportsService";
import Header from "./components/Projects/ProjectDetail/Header";
import Description from "./components/Projects/ProjectDetail/Description";
import EditPromotoreModal from "./components/Projects/ProjectDetail/Modals/EditPromotoreModal";
import EditAgentModal from "./components/Projects/ProjectDetail/Modals/EditAgentModal";
import EditPhaseProjectModal from "./components/Projects/ProjectDetail/Modals/EditPhaseProjectModal";
import {
  getContacts,
  newContact,
  deleteContacts,
} from "../services/contactsService";
import { getImages } from "../services/imagesServices";
import {
  getDocuments,
  deleteDocument,
  deleteImage,
  deleteCustomerImages,
  deleteCustomerFiles,
  deleteCustomerReportFiles,
} from "../services/documentService";
import ContactList from "../shared/components/ContactList";
import NewContactModal from "../shared/components/NewContactModal";
import Reports from "../shared/components/Reports";
import NewReportModal from "../shared/components/NewReportModal";
import NewImageModal from "../shared/components/Gallery/NewImageModal";

import DocumentList from "../shared/components/DocumentList/DocumentList";
import NewDocumentModal from "../shared/components/DocumentList/NewDocumentModal";

import EditAdressModal from "./components/shared/EditAdressModal/EditAdressModal";
import Offers from "../shared/components/Offers";
import {
  deleteOffer,
  getOffers,
  getOffersFinalCustomers,
  updateEndDateOffer,
  updateStatusOffer,
} from "../services/offersServices";
import OfferDetailsModal from "../shared/components/OfferDetailsModal";
import { useTranslation } from "react-i18next";
import HeaderMapProject from "./components/Projects/ProjectDetail/HeaderMapProject";
import FinalCustomers from "../shared/components/FinalCustomers";
import EditNameProjectModal from "./components/shared/EditNameModal/EditNameProjectModal";
import MyImageGallery from "../shared/components/Gallery/MyImageGallery";
import EditColaboratorModal from "./components/Projects/ProjectDetail/Modals/EditColaboratorModal";

interface CustomerCategories {
  id: number;
  description: string;
  customer_name: string;
  customer_id: number;
  selected_record_id: number;
  // Agrega aquí otras propiedades según sean necesarias
}

const CustomerDetail = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const [customers, setcustomers] = useState([]);

  const [contacts, setcontacts] = useState([]);
  const [colaborators, setcolaborators] = useState([]);
  const [reports, setreports] = useState([]);
  const [images, setimages] = useState([]);
  const [documents, setdocument] = useState([]);
  const [offers, setoffers] = useState([]);
  const [offersfinalcustomers, setoffersfinalcustomers] = useState([]);

  const [openContactModal, setopenContactModal] = useState(false);

  const [openEditPromotoraModal, setEditPromotoraModal] = useState(false);
  const [openEditConstructoraModal, setopenEditConstructoraModal] =
    useState(false);

  const [openEditInstallerModal, setEditInstakllerModal] = useState(false);
  const [openEditArquitectModal, setEditArquitectModal] = useState(false);
  const [openEditDecoradorModal, setopenEditDecoradorModal] = useState(false);
  const [openEditHotelesModal, setopenEditHotelesModal] = useState(false);
  const [openEditAgentModal, setEditAgentModal] = useState(false);
  const [openEditColaboratorModal, setEditColaboratorModal] = useState(false);
  const [openEditPhaseModal, setEditPhaseModal] = useState(false);
  const [openreport, setopenreport] = useState(false);
  const [openImageModal, setopenImageModal] = useState(false);
  const [openDocumentModal, setopenDocumentModal] = useState(false);
  const [openEditAdressModal, setopenEditAdressModal] = useState(false);

  const [customerCategories, setCustomerCategories] = useState<
    CustomerCategories[]
  >([]);

  const [openEditNameProjectModal, setopenEditNameProjectModal] =
    useState(false);

  const [openEditTraditionalModal, setopenEditTraditionalModal] =
    useState(false);
  const [openEditHotels2Modal, setopenEditHotels2Modal] = useState(false);

  const [openOfferModal, setopenOfferModal] = useState(false);

  const [idoffer, setidoffer] = useState(null);
  const [offeritem, setofferitem] = useState([]);

  const toggle = () => {
    setEditPromotoraModal(!openEditPromotoraModal);
  };

  const toggle_constructora = () => {
    setopenEditConstructoraModal(!openEditConstructoraModal);
  };

  const toggle_instalador = () => {
    setEditInstakllerModal(!openEditInstallerModal);
  };

  const toggle_arquitecto = () => {
    setEditArquitectModal(!openEditArquitectModal);
  };

  const toggle_decorador = () => {
    setopenEditDecoradorModal(!openEditDecoradorModal);
  };

  const toggle_dealers = () => {
    setopenEditHotelesModal(!openEditHotelesModal);
  };

  const toggle_tradicional = () => {
    setopenEditTraditionalModal(!openEditTraditionalModal);
  };

  const toggle_hoteles2 = () => {
    setopenEditHotels2Modal(!openEditHotels2Modal);
  };

  const toggle_agente = () => {
    setEditAgentModal(!openEditAgentModal);
  };

  const toggle_colaborator = () => {
    setEditColaboratorModal(!openEditColaboratorModal);
  };

  const toggle_phase = () => {
    setEditPhaseModal(!openEditPhaseModal);
  };

  const toggle_images = () => {
    setopenImageModal(!openImageModal);
  };

  const toggle_document = () => {
    setopenDocumentModal(!openDocumentModal);
  };

  const toggle_offer = () => {
    setopenOfferModal(!openOfferModal);
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getCustomerById(id).then((response) => {
      if (response?.data.ok) {
        setcustomers(response?.data.customers);
        callCustomerColaborators();
        getOfferstListFinalCustomers();

        getContactList();
        getOfferstList();

        callCustomerCategories();

        getReportsCustomer();
        getCustomerImages();
        getDocumentsCustomer();
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  const toggle_editadress = () => {
    setopenEditAdressModal(!openEditAdressModal);
  };

  const toggle_editprojectname = () => {
    setopenEditNameProjectModal(!openEditNameProjectModal);
  };

  const editAdress = (item: any) => {
    alert("editando");
    editAdressCustomer(item).then((response) => {
      if (response?.data.ok) {
        setopenEditAdressModal(false);
        refresh();
      }
    });
  };

  const editName = (item: any) => {
    editProjectData(id, item, 104).then((response) => {
      if (response?.data.ok) {
        setopenEditNameProjectModal(false);
        refresh();
      }
    });
  };

  const editPromoter = (item: any) => {
    editProjectData(id, item, 2).then((response) => {
      if (response?.data.ok) {
        setEditPromotoraModal(false);
        refresh();
        // alert(item.id);
      }
    });
  };

  const addCustomerCategory = (item: any, type_id: number) => {
    newCustomerCategory(id, item, type_id).then((response) => {
      if (response?.data.ok) {
        setEditPromotoraModal(false);
        refresh();
      }
    });
  };

  const callCustomerCategories = () => {
    getCustomerCategories(id).then((response) => {
      if (response?.data.ok) {
        setCustomerCategories(response?.data.categoriesResults);
        //refresh();
      }
    });
  };

  const editAgent = (item: any) => {
    editProjectData(id, item, 0).then((response) => {
      if (response?.data.ok) {
        setEditAgentModal(false);
        refresh();
      }
    });
  };

  const callCustomerColaborators = () => {
    getcolaborators(id).then((response) => {
      if (response?.data.ok) {
        setcolaborators(response?.data.colabotatorsResult);
        //refresh();
      }
    });
  };

  const editColaborator = (item: any) => {
    addColaborator(id, item.id).then((response) => {
      if (response?.data.ok) {
        callCustomerColaborators();
        setEditColaboratorModal(false);
      } else {
        alert(response?.data.message);
      }
    });
  };

  const handleDeleteColaborator = (userId: number) => {
    // Llamar a la API para eliminar la categoría
    deletecolaborator(id, userId).then((response) => {
      if (response?.data.ok) {
        callCustomerColaborators();
      } else {
        alert(response?.data.message);
      }
    });
  };

  const editPhase = (item: any) => {
    editProjectData(id, item, 100).then((response) => {
      if (response?.data.ok) {
        setEditPhaseModal(false);
        refresh();
      }
    });
  };

  const getContactList = () => {
    getContacts(id).then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.contacts);
        setcontacts(response?.data.contactResults);
      } else {
        setcontacts([]);
      }
    });
  };

  const getOfferstList = () => {
    getOffers(id).then((response) => {
      console.table(response);
      if (response?.data.ok) {
        console.table(response?.data.offersResults);
        setoffers(response?.data.offersResults);
      } else {
        setoffers([]);
      }
    });
  };

  const getOfferstListFinalCustomers = () => {
    getOffersFinalCustomers(id).then((response) => {
      console.table(response);
      if (response?.data.ok) {
        console.table(response?.data.offersResults);
        setoffersfinalcustomers(response?.data.offersResults);
      } else {
        setoffersfinalcustomers([]);
      }
    });
  };

  const toggle_contact = () => {
    setopenContactModal(!openContactModal);
  };

  const addNewContact = (item: any) => {
    newContact(item, id).then((response) => {
      if (response?.data.ok) {
        setopenContactModal(false);
        getContactList();
      } else {
        setopenContactModal(false);
      }
    });
  };

  const deleteContact = (item: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres Eliminar este Contacto")) {
      deleteContacts(item.id).then((response) => {
        if (response?.data.ok) {
          getContactList();
        }
      });
    }
  };

  const toggle_reports = () => {
    setopenreport(!openreport);
  };

  const getReportsCustomer = () => {
    getReports(id).then((response) => {
      if (response?.data.ok) {
        setreports(response?.data.reportResults);
      }
    });
  };
  const addNewReport = (item: any) => {
    newReport(item, id).then((response) => {
      if (response?.data.ok) {
        setopenreport(false);
        getReportsCustomer();
      } else {
        setopenreport(false);
        getReportsCustomer();
      }
    });
  };

  const addNewImage = () => {
    getCustomerImages();
  };

  const getCustomerImages = () => {
    getImages(id).then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.imagesResults);
        setimages(response?.data.imagesResults);
      }
    });
  };

  const deleteCustomerImage = (id: any) => {
    if (window.confirm(`Quieres Eliminar la Imagen de la Biblioteca?`)) {
      deleteImage(id).then((response) => {
        if (response) {
          getCustomerImages();
        }
      });
    }
  };

  const addNewDocument = () => {
    setopenDocumentModal(false);
    getDocumentsCustomer();
    getOfferstList();
  };

  const getDocumentsCustomer = () => {
    getDocuments(id).then((response) => {
      if (response?.data.ok) {
        setdocument(response?.data.documentResults);
      }
    });
  };
  const deleteDocumentCustomer = (id: any, file_name: any) => {
    if (window.confirm(`Quieres Eliminar el documento? ${file_name}?`)) {
      deleteDocument(id).then((response) => {
        if (response) {
          getDocumentsCustomer();
        }
      });
    }
  };

  const offer_handler = (item: any) => {
    setidoffer(item.idoffer);
    setofferitem(item);
    setopenOfferModal(true);
  };

  const updateStatus = (
    idstatus: number,
    idoffer: string,
    idanulation: any
  ) => {
    updateStatusOffer(idstatus, idoffer, idanulation).then((response) => {
      if (response?.data.ok) {
        getOfferstList();
        toggle_offer();
      }
    });
  };

  const updateEndDate = (end_date: any, idoffer: string) => {
    updateEndDateOffer(end_date, idoffer).then((response) => {
      if (response?.data.ok) {
        getOfferstList();
        toggle_offer();
      }
    });
  };

  const _delete_offer = (item: any) => {
    const userid = localStorage.getItem("userid");
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres ELIMINAR la Oferta " + item.idoffer + " ?")) {
      if (
        userid === "1" ||
        userid === "27" ||
        userid === "26" ||
        userid === "5" ||
        userid === "4" ||
        userid === "25"
      ) {
        deleteOffer(item.idoffer).then((response) => {
          if (response?.data.ok) {
            refresh();
          }
        });
      } else {
        alert("No autorizado para Eliminar Ofertas");
      }
    }
  };

  const deletePoint = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres ELIMINAR este Proyecto?")) {
      deleteCustomerImages(id).then((response) => {
        deleteCustomerFiles(id).then((response2) => {
          deleteCustomerReportFiles(id).then((response3) => {
            deleteCustomer(id).then((response) => {
              if (response?.data.ok) {
                window.close();
              } else {
                alert(response?.data.msg);
              }
            });
          });
        });
      });
    }
  };

  const handleDelete = (categoryId: number) => {
    // Llamar a la API para eliminar la categoría
    deleteCustomerCategory(categoryId).then((response) => {
      if (response?.data.ok) {
        refresh();
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            {t("CustomerDetail.title")}

            <div className="Button">
              {" "}
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={deletePoint}
              >
                {t("SharedComponents.DeleteButtom")}
              </button>
            </div>
          </h4>
        </div>

        <Breadcrumb listTag="div">
          <BreadcrumbItem>
            {t("CustomerDetail.projectsBreadcrum")}
          </BreadcrumbItem>
          <BreadcrumbItem>{t("CustomerDetail.projectdetail")}</BreadcrumbItem>
        </Breadcrumb>
      </div>

      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <Row xs="2">
            <Col>
              <Header
                customer={customers}
                toggle={toggle}
                toggle_instalador={toggle_instalador}
                toggle_arquitecto={toggle_arquitecto}
                toggle_decorador={toggle_decorador}
                toggle_dealers={toggle_dealers}
                toggle_tradicional={toggle_tradicional}
                toggle_hoteles2={toggle_hoteles2}
                toggle_agente={toggle_agente}
                toggle_colaborator={toggle_colaborator}
                toggle_phase={toggle_phase}
                toggle_constructora={toggle_constructora}
                toggle_editadress={toggle_editadress}
                toggle_editprojectname={toggle_editprojectname}
                customerCategories={customerCategories}
                colaborators={colaborators}
                handleDelete={handleDelete}
                handleDeleteColaborator={handleDeleteColaborator}
              />
            </Col>
            <Col>
              <HeaderMapProject customer={customers} />
            </Col>
          </Row>

          <Description customer={customers} />
        </div>
      </div>
      <div className="content-page">
        <br />

        <Row>
          <Col>
            {offersfinalcustomers ? (
              <FinalCustomers offers={offersfinalcustomers} />
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {t("SharedComponents.Images")}
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_images}
                  >
                    {t("SharedComponents.NewButton")}
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                {images ? (
                  <MyImageGallery
                    images={images}
                    deleteCustomerImage={deleteCustomerImage}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {t("SharedComponents.Documents")}
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_document}
                  >
                    {t("SharedComponents.NewButton")}
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                {documents ? (
                  <DocumentList
                    documents={documents}
                    deleteDocumentCustomer={deleteDocumentCustomer}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Ofertas
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_document}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {offers ? (
                  <Offers
                    offers={offers}
                    offer_handler={offer_handler}
                    _delete_offer={_delete_offer}
                  />
                ) : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>
                {t("SharedComponents.Contacts")}
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_contact}
                  >
                    {t("SharedComponents.NewButton")}
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>

                <CardText>
                  {contacts ? (
                    <ContactList
                      contacts={contacts}
                      deleteContact={deleteContact}
                    />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                {t("SharedComponents.Reports")}
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_reports}
                  >
                    {t("SharedComponents.NewButton")}
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {reports ? <Reports reports={reports} /> : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {openContactModal ? (
        <NewContactModal
          isOpen={openContactModal}
          toggle_contact={toggle_contact}
          addNewContact={addNewContact}
        />
      ) : null}
      {openEditPromotoraModal ? (
        <EditPromotoreModal
          isOpen={openEditPromotoraModal}
          toggle={toggle}
          editPromoter={editPromoter}
          addCustomerCategory={addCustomerCategory}
        />
      ) : null}

      {openEditAgentModal ? (
        <EditAgentModal
          isOpen={openEditAgentModal}
          toggle_agente={toggle_agente}
          editAgent={editAgent}
        />
      ) : null}

      {openEditColaboratorModal ? (
        <EditColaboratorModal
          isOpen={openEditColaboratorModal}
          toggle_colaborator={toggle_colaborator}
          editColaborator={editColaborator}
        />
      ) : null}

      {openEditPhaseModal ? (
        <EditPhaseProjectModal
          isOpen={openEditPhaseModal}
          toggle_phase={toggle_phase}
          editPhase={editPhase}
        />
      ) : null}

      {openreport ? (
        <NewReportModal
          isOpen={openreport}
          toggle_reports={toggle_reports}
          addNewReport={addNewReport}
        />
      ) : null}
      {openImageModal ? (
        <NewImageModal
          isOpen={openImageModal}
          toggle_images={toggle_images}
          addNewImage={addNewImage}
          customer_id={id}
        />
      ) : null}

      {openDocumentModal ? (
        <NewDocumentModal
          isOpen={openDocumentModal}
          toggle_images={toggle_document}
          addNewDocument={addNewDocument}
          customer_id={id}
        />
      ) : null}
      {openEditAdressModal ? (
        <EditAdressModal
          isOpen={openEditAdressModal}
          toggle_editadress={toggle_editadress}
          editAdress={editAdress}
          customer_id={id}
          customer={customers}
        />
      ) : null}

      {openEditNameProjectModal ? (
        <EditNameProjectModal
          isOpen={openEditNameProjectModal}
          toggle_editprojectname={toggle_editprojectname}
          editName={editName}
          customer_id={id}
          customer={customers}
        />
      ) : null}

      {openOfferModal ? (
        <OfferDetailsModal
          isOpen={openOfferModal}
          toggle_offer={toggle_offer}
          editAdress={editAdress}
          idoffer={idoffer}
          offeritem={offeritem}
          updateStatus={updateStatus}
          updateEndDate={updateEndDate}
        />
      ) : null}
    </div>
  );
};

export default CustomerDetail;
