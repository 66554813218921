import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../App.scss";
import NavbarTop from "./NavbarTop";
import Sidebar from "./Sidebar";

import Inicio from "../pages/Inicio";
import Alumnos from "../pages/Alumnos";
import Promotoras from "../pages/Promotoras";
import Instaladores from "../pages/Instaladores";
import CustomerDetail from "../pages/CustomerDetail";

import Arquitectos from "../pages/Arquitectos";
import DetailArquitect from "../pages/components/Arquitects/DetailArquitect/DetailArquitect";
import Decoradores from "../pages/Decoradores";

import Hoteles from "../pages/Hoteles";

import Distribuidores from "../pages/Distribuidores";
import Constructoras from "../pages/Constructoras";

import Bricolajes from "../pages/Bricolajes";

import Ferreterias from "../pages/Ferreterias";
import Products from "../pages/Products";
import Footer from "./Footer";
import Tradicional from "../pages/Tradicional";
import Users from "../pages/Users";
import Offers from "../pages/Offers";
import CustomersAll from "../pages/CustomersAll";
import Sellers from "../pages/Sellers";
import ProductsKPI from "../pages/ProductsKPI";
import ReportsSellers from "../pages/ReportsSellers";
import { obtenerCategorias } from "../services/customerService";
import GenericCategoryComponent from "../pages/GenericCategoryComponent";
import Asistentes from "../pages/Asistentes";
import Materiales from "../pages/Materiales";
import Contacts from "../pages/Contacts";

interface Categoria {
  type_id: number;
  description: string;
  // Agrega aquí otras propiedades según sean necesarias
}

const Layout = () => {
  const [active, setactive] = useState(true);
  const [categorias, setCategorias] = useState<Categoria[]>([]);

  useEffect(() => {
    // Supongamos que tienes los datos del usuario almacenados en algún lugar
    obtenerCategorias().then((data) => {
      setCategorias(data);
    });
  }, []);

  const changeState = (value: any) => {
    setactive(value);
  };
  return (
    <>
      <Router>
        <div className="flex">
          <Sidebar active={active} />

          <div className="content w-100 background-page">
            <NavbarTop changeState={changeState} active={active} />
            <Switch>
              {categorias.map((categoria) => (
                <Route
                  key={categoria.type_id}
                  path={`/layout/${categoria.description.toLowerCase()}`}
                  render={(props) => (
                    <GenericCategoryComponent
                      {...props}
                      categoryId={categoria.type_id}
                      categoryName={categoria.description}
                    />
                  )}
                />
              ))}
              <Route exact path="/layout/home" component={Inicio} />
              <Route exact path="/layout/assistants" component={Asistentes} />
              <Route exact path="/layout/sellers" component={Sellers} />
              <Route exact path="/layout/productskpi" component={ProductsKPI} />
              <Route exact path="/layout/materials" component={Materiales} />
              <Route exact path="/layout/contactsall" component={Contacts} />
              <Route exact path="/layout/reports" component={ReportsSellers} />
              <Route exact path="/layout/offers" component={Offers} />
              <Route exact path="/layout/customers" component={Alumnos} />
              <Route
                exact
                path="/layout/customersall"
                component={CustomersAll}
              />
              <Route exact path="/layout/products" component={Products} />
              <Route exact path="/layout/promoters" component={Promotoras} />
              <Route exact path="/layout/installers" component={Instaladores} />
              <Route exact path="/layout/arquitects" component={Arquitectos} />
              <Route exact path="/layout/decorators" component={Decoradores} />
              <Route exact path="/layout/dealers" component={Distribuidores} />
              <Route exact path="/layout/hotels" component={Hoteles} />
              <Route exact path="/layout/builders" component={Constructoras} />
              <Route exact path="/layout/retails" component={Bricolajes} />
              <Route
                exact
                path="/layout/traditionals"
                component={Tradicional}
              />
              <Route
                exact
                path="/layout/hardwarestores"
                component={Ferreterias}
              />
              <Route exact path="/layout/users" component={Users} />
            </Switch>
            <Route
              exact
              path="/layout/customer/:id"
              component={CustomerDetail}
            />
            <Route
              exact
              path="/layout/channel/:id"
              component={DetailArquitect}
            />
          </div>
        </div>
      </Router>

      <Footer />
    </>
  );
};

export default Layout;
