import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Button,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import MapComponent from "./components/shared/Map/Map";
import ListComponent from "./components/shared/List/List";

import NewItemModal from "./components/Promoters/NewPromoter/NewPromoterModal";
import { getCustomers, addCustomer } from "../services/customerService";
import { useHistory } from "react-router-dom";

interface Categoria {
  categoryId: number;
  categoryName: string;
  // Agrega aquí otras propiedades según sean necesarias
}

const GenericCategoryComponent: React.FC<Categoria> = ({
  categoryName,
  categoryId,
}) => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [openNewItemModal, setOpenNewItemModal] = useState(false);

  const toggleNewItemModal = () => {
    setOpenNewItemModal(!openNewItemModal);
  };

  useEffect(() => {
    refreshItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewItem = (item: any) => {
    // Suponiendo que addCustomer es genérico o cambia según la categoría
    console.table(item);
    addCustomer(item).then(() => {
      refreshItems();
    });
  };

  const refreshItems = () => {
    // Suponiendo que getCustomers es genérico o cambia según la categoría
    getCustomers(categoryId).then((response) => {
      console.table(response?.data.customersResults);
      if (response?.data.ok) {
        setItems(response?.data.customersResults);
        setOpenNewItemModal(false);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  const showItemDetails = (item: any) => {
    window.open(`/layout/channel/${item.id}`);
  };

  const capitalizeFirstLetter = (string: string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            {React.createElement(FaIcons.FaCity, { className: "me-2" })}
            {capitalizeFirstLetter(categoryName)}
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>
                {capitalizeFirstLetter(categoryName)}
              </BreadcrumbItem>
              <BreadcrumbItem>Lista</BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="col-lg-2">
            <Col className="text-center text-md-right">
              <Button size="sm" block onClick={toggleNewItemModal}>
                Nuevo {capitalizeFirstLetter(categoryName)}
              </Button>
            </Col>
          </Col>
        </Row>
      </div>

      <div className="content-page">
        <Card>
          <CardHeader>Georeferencia</CardHeader>
          <CardBody>
            <MapComponent customers={items || []} show={showItemDetails} />
            {items ? (
              <ListComponent customers={items || []} show={showItemDetails} />
            ) : null}
          </CardBody>
        </Card>
      </div>

      {openNewItemModal && (
        <NewItemModal
          isOpen={openNewItemModal}
          toggle={toggleNewItemModal}
          AddNewItem={addNewItem}
          categoryId={categoryId}
        />
      )}
    </div>
  );
};

export default GenericCategoryComponent;
