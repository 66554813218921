import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";

import { obtenerVentasPorMaterialidad } from "../services/chartsService";
import TendenciaVentasMensualesChart from "../shared/components/charts/TendenciaVentasMensualesChart";
import TendenciaVentasMensualesPorCategoriaChartProps from "../shared/components/charts/TendenciaVentasMensualesPorCategoriaChartProps";
import PieChartVentasPorMaterial from "../shared/components/charts/PieChartVentasPorMaterial";
import PieChartVentasPorCategoria from "../shared/components/charts/PieChartVentasPorCategoria";
import VentasPorMaterialidadList from "../shared/components/VentasPorMaterialidadList";
const Materiales = () => {
  const [productsmaterialssells, setproductsmaterialssells] = useState([]);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    obtenerVentasPorMaterialidad().then((response) => {
      if (response?.data) {
        setproductsmaterialssells(response?.data);
      } else {
      }
    });
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBarcode className="me-2" />
            Materialidades
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Productos</BreadcrumbItem>
              <BreadcrumbItem>Materialidades</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Row>
          <Col xs="6">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {productsmaterialssells ? (
                  <TendenciaVentasMensualesChart
                    datos={productsmaterialssells}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {productsmaterialssells ? (
                  <TendenciaVentasMensualesPorCategoriaChartProps
                    datos={productsmaterialssells}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {productsmaterialssells ? (
                  <PieChartVentasPorMaterial datos={productsmaterialssells} />
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {productsmaterialssells ? (
                  <PieChartVentasPorCategoria datos={productsmaterialssells} />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                {productsmaterialssells ? (
                  <VentasPorMaterialidadList datos={productsmaterialssells} />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Materiales;
