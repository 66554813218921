import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";

import {
  getAssistantChat,
  getAssistants,
  sendMessageToAssistant,
} from "../services/assistantsService";
import AssistantChat from "../shared/components/AssistantChat";
import AssistantChatInput from "../shared/components/AssistantChatInput";
interface Assistant {
  id: number;
  assistant_id: string;
  description: string;
}
const Asistentes = () => {
  const [assistants, setassistants] = useState<Assistant[]>([]);
  const [selectedAssistantId, setSelectedAssistantId] = useState("");
  const [assistantChats, setAssistantChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const messagesEndRef = useRef(null);

  // Función para desplazarse al final del contenedor de mensajes
  const scrollToBottom = () => {
    //@ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Efecto para desplazarse al último mensaje cada vez que se actualicen los chats
  useEffect(() => {
    scrollToBottom();
  }, [assistantChats]); // Depende de la actualización de los chats

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getAssistants()
      .then((response) => {
        console.log(response); // Esto te ayudará a ver la estructura real de la respuesta
        if (Array.isArray(response?.data.assistants)) {
          setassistants(response?.data.assistants);
        } else {
          console.error(
            "Received data is not an array:",
            response?.data.assistants
          );
          setassistants([]); // Reinicia a un estado seguro si los datos no son un array
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setassistants([]); // Manejo de errores: reiniciar a un estado seguro
      });
  };

  const handleAssistantChange = (event: any) => {
    const assistantId = event.target.value;
    setSelectedAssistantId(assistantId);

    if (assistantId) {
      getAssistantChat(assistantId).then((response) => {
        if (response?.data) {
          setAssistantChats(response.data.assistantChats);
        }
      });
    } else {
      setAssistantChats([]); // Limpiar los chats si no hay un asistente seleccionado
    }
  };
  const handleSendMessage = (message: string) => {
    console.log("Enviar mensaje:", message);
    if (!selectedAssistantId) {
      alert("Por favor, seleccione un asistente antes de enviar un mensaje.");
      return; // Detiene la función si no hay un asistente seleccionado
    }
    if (!message.trim()) {
      alert("El mensaje no puede estar vacío.");
      return; // Detiene la función si el mensaje está vacío
    }

    setIsLoading(true);
    sendMessageToAssistant(selectedAssistantId, message.trim())
      .then((response) => {
        getAssistantChat(selectedAssistantId)
          .then((response) => {
            if (response?.data) {
              setIsLoading(false);
              setAssistantChats(response.data.assistantChats);
            }
          })
          .catch((error) => {
            console.error("Error al cargar el chat del asistente:", error);
            setIsLoading(false); // Asegura que se desactive la indicación de carga si hay un error
          });
      })
      .catch((error) => {
        console.error("Error al enviar mensaje al asistente:", error);
        setIsLoading(false); // Asegura que se desactive la indicación de carga si hay un error
      });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBrain className="me-2" />
            Asistentes IA
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Asitentes IA</BreadcrumbItem>
              <BreadcrumbItem>Chat</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>
            <select
              className="form-control"
              value={selectedAssistantId}
              onChange={handleAssistantChange}
            >
              <option value="">Seleccione un asistente</option>
              {assistants.map((assistant) => (
                <option key={assistant.id} value={assistant.assistant_id}>
                  {assistant.description}
                </option>
              ))}
            </select>
            <AssistantChatInput onSendMessage={handleSendMessage} />
          </CardHeader>
          <CardBody>
            <CardText>
              <div></div>
              <div>
                <AssistantChat chats={assistantChats} />
                <div ref={messagesEndRef} />
              </div>
              {isLoading && (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000, // Asegúrate de que sea suficientemente alto
                  }}
                >
                  <div className="spinner"></div>
                </div>
              )}
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Asistentes;
