import axios from "axios";
import config from "./confserver.json";




export const getImages = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/images', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getProductsImages = async (id: any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/getproductsimages',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteImageProduct = async (id: any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    id: id,
    query: 'deleteFileImageProduct',
  };
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};



