import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Col,
  Row,
  Spinner,
  ModalHeader,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md"; // Para el icono de "X"
import "./ImageGallery.css"; // Archivo CSS para los estilos

interface ProductImage {
  id: number;
  src: string;
  thumbnail: string;
  thumbnailWidth: number;
  thumbnailHeight: number;
  caption: string;
  hash: string;
}
interface ImageModalProps {
  images: ProductImage[];
  isOpen: boolean;
  toggle: () => void;
  onUpload: () => void;
  handleDelete: (id: number) => void;
  productName: string | null; // Nueva prop para el nombre del producto
}

const ImagesProducts: React.FC<ImageModalProps> = ({
  images,
  isOpen,
  toggle,
  onUpload,
  productName,
  handleDelete,
}) => {
  const [selectedImage, setSelectedImage] = useState<ProductImage | null>(null);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga

  const handleImageClick = (image: ProductImage) => {
    setSelectedImage(image);
    setLoading(true); // Comienza a cargar la imagen grande
  };

  const handleClose = () => {
    setSelectedImage(null);
    toggle();
  };

  const handleCloseLargeImageModal = () => {
    setSelectedImage(null); // Solo cierra el modal de la imagen grande
  };

  const handleDeleteImage = () => {
    if (selectedImage) {
      handleDelete(selectedImage.id); // Llama a la función handleDelete pasando el id de la imagen
      console.log("Eliminar imagen con id:", selectedImage.id);
      setSelectedImage(null); // Cierra el modal de la imagen grande
    }
  };

  // Método para indicar que la imagen ha terminado de cargar
  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="xl">
      <ModalHeader>
        <Row className="mt-2">
          <Col className="text-center">
            <h5 className="modal-title text-center mb-4">{productName}</h5>
          </Col>
          <Col className="text-center text-md-right pull-right">
            <Button color="primary" onClick={onUpload}>
              <FaIcons.FaUpload className="me-2" />
            </Button>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <div className="gallery-container">
              {images.map((image) => (
                <div key={image.id} className="gallery-item">
                  <img
                    src={image.src}
                    alt={image.caption}
                    onClick={() => handleImageClick(image)}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </ModalBody>

      {/* Modal para mostrar la imagen seleccionada en grande */}
      {selectedImage && (
        <Modal isOpen={true} toggle={handleClose} size="lg">
          <ModalHeader>
            <h5 className="modal-title text-center mb-4">{productName}</h5>
          </ModalHeader>
          <ModalBody>
            <div className="selected-image-container">
              {loading && (
                <div className="image-loader">
                  <Spinner color="primary" />
                </div>
              )}
              <img
                src={selectedImage.src}
                alt={selectedImage.caption}
                className="full-size-image"
                style={{ display: loading ? "none" : "block" }} // Oculta la imagen mientras carga
                onLoad={handleImageLoad} // Llama a handleImageLoad cuando la imagen se haya cargado
              />
            </div>
            <div className="modal-buttons text-center mt-3">
              <Button
                color="danger"
                onClick={handleDeleteImage}
                className="me-2"
              >
                <FaIcons.FaTrash />
              </Button>
              <Button color="secondary" onClick={handleCloseLargeImageModal}>
                <MdIcons.MdClose />
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </Modal>
  );
};

export default ImagesProducts;
