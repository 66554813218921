import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import {
  getCustomers,
  getCustomersAll,
  obtenerCategorias,
} from "../../../../../services/customerService";
//import PromotersList from "./Lists/PromotersList";
import PromotersList from "./Lists/PromotersList";
import { useTranslation } from "react-i18next";

interface Categoria {
  type_id: number;
  description: string;
  // Agrega aquí otras propiedades según sean necesarias
}

const EditPromotoreModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [customers, setcustomers] = useState([]);
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getCategoriesData();
  }, []);

  const refresh = (id: number) => {
    setIsLoading(true); // Inicia la carga
    const fetchFunction = id === 0 ? getCustomersAll : () => getCustomers(id);

    fetchFunction()
      .then((response) => {
        console.table(response?.data.customers);
        if (response?.data.ok) {
          setcustomers(response?.data.customersResults);
        } else {
          // Manejo de error, opcionalmente puedes establecer un estado para mostrar un mensaje de error
        }
        setIsLoading(false); // Finaliza la carga
      })
      .catch((error) => {
        // En caso de error también finaliza la carga
        console.error("Error fetching customers:", error);
        setIsLoading(false);
      });
  };

  const getCategoriesData = () => {
    obtenerCategorias().then((data) => {
      const categoriaTodos = { type_id: 0, description: "TODOS" };
      // Agrega la categoría "TODOS" al inicio del array de categorías
      setCategorias([categoriaTodos, ...data]);
      //setCategorias(data);
    });
  };
  const edit = (item: any) => {
    if (selectedCategoryId !== null) {
      props.addCustomerCategory(item, selectedCategoryId);
    } else {
      // Manejo del caso en que no se ha seleccionado ninguna categoría
      console.error("No category selected");
    }
  };

  const handleCategoryChange = (e: { target: { value: any } }) => {
    const selectedCategoryId = parseInt(e.target.value);
    setSelectedCategoryId(selectedCategoryId);
    refresh(selectedCategoryId);
  };

  return (
    <>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader>
          {t("CustomerDetail.Modal.PromoterModal.Title")}
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <div className="loader-container">
              <span>Espera un momento..</span>
              <div className="loader"></div>
            </div>
          ) : (
            <div>
              <div className="mb-3">
                {" "}
                {/* Clase para margin-bottom */}
                <label htmlFor="category-select" className="form-label"></label>
                <select
                  id="category-select"
                  className="form-select"
                  onChange={handleCategoryChange}
                >
                  <option value="">
                    {t("CustomerDetail.Modal.PromoterModal.SelectCategory")}
                  </option>{" "}
                  {/* Opción inicial */}
                  {categorias.map((categoria) => (
                    <option key={categoria.type_id} value={categoria.type_id}>
                      {categoria.description}
                    </option>
                  ))}
                </select>
              </div>
              {customers ? (
                <PromotersList customers={customers} edit={edit} />
              ) : null}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle}>
            {t("CustomerDetail.Modal.PromoterModal.List.CancelButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditPromotoreModal;
