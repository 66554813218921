import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
require("highcharts/modules/exporting")(Highcharts);

const Facturation2Chart = (props: any) => {
  const [chartOptions, setChartOptions] = useState<{
    chart: {
      type: string;
    };
    colors: string[];
    credits: {
      enabled: boolean;
    };
    title: {
      text: string;
    };
    yAxis: {
      title: {
        text: string;
      };
    };
    xAxis: {
      categories: string[];
    };
    series: {
      name: string;
      data: number[];
      dataLabels: {
        enabled: boolean;
      };
    }[];
  }>({
    chart: {
      type: "bar",
    },
    colors: [
      "#A3CEE7",
      "#1AA588",
      "#6600cc",
      "#0099e6",
      "#0066ff",
      "#005EBB",
      "#ffe680",
      "#ff9900",
      "#ff3300",
      "#000d1a",
    ],
    credits: {
      enabled: false,
    },
    title: {
      text: "TOP 1O Clientes",
    },
    yAxis: {
      title: {
        text: "Clientes", // cambiar la propiedad "text" de "title" para la etiqueta del eje Y
      },
    },
    xAxis: {
      categories: [],
    },

    series: [
      {
        name: "Ventas 2024",
        data: [],
        dataLabels: {
          enabled: true, // habilitar las etiquetas de datos
        },
      },
      {
        name: "Ventas 2023",
        data: [],
        dataLabels: {
          enabled: true, // habilitar las etiquetas de datos
        },
      },
    ],
  });

  useEffect(() => {
    // Configurar los datos del gráfico con los datos recibidos

    const data = Object.entries(props.datos).map(
      ([key, value]: [string, any]) => {
        return {
          name: key,
          ventas2022: value.ventas2022 ?? 0,
          ventas2023: value.ventas2023 ?? 0,
        };
      }
    );

    //const sortedData = data.sort((a, b) => b.ventas2023 - a.ventas2023);

    const sortedData = data.sort((a, b) => a.ventas2023 - b.ventas2023);

    const categories = sortedData.map((item) => item.name);
    const ventas2022 = sortedData.map((item) => item.ventas2022);
    const ventas2023 = sortedData.map((item) => item.ventas2023);

    setChartOptions({
      chart: {
        type: "bar",
      },
      colors: [
        "#3d5a76",
        "#7BB4EC",
        "#0099e6",
        "#0066ff",
        "#005EBB",
        "#ffe680",
        "#ff9900",
        "#ff3300",
        "#000d1a",
      ],
      credits: {
        enabled: false,
      },
      title: {
        text: "TOP 1O Clientes",
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        title: {
          text: "Ventas",
        },
      },

      series: [
        {
          name: "Ventas 2024",
          data: ventas2023, // Cambiar el tipo de datos a number[]
          dataLabels: {
            enabled: true, // habilitar las etiquetas de datos
          },
        },
        {
          name: "Ventas 2023",
          data: ventas2022, // Cambiar el tipo de datos a number[]
          dataLabels: {
            enabled: true, // habilitar las etiquetas de datos
          },
        },
      ],
    });
  }, [props.datos]);

  return (
    <>
      <div>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    </>
  );
};

export default Facturation2Chart;
