import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const VentasVendedores = (props: any) => {
  const { series } = props;
  let dataParsed = [];

  try {
    dataParsed = JSON.parse(series);
  } catch (error) {
    console.error("Error al analizar los datos JSON:", error);
  }

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Ventas por Comercial",
    },
    xAxis: {
      categories: dataParsed.map((dataPoint: any) => dataPoint.name),
    },
    yAxis: {
      title: {
        text: "Ventas",
      },
    },
    tooltip: {
      pointFormat: "<b>{point.name}</b>: {point.y}",
    },
    series: [
      {
        name: "2023",
        data: dataParsed.map((dataPoint: any) => dataPoint.data[0]),
        dataLabels: {
          enabled: true,
          rotation: 0,
          inside: false,
          verticalAlign: "top",
          format: "{point.y}",
          style: {
            fontSize: "8px",
            fontWeight: "bold",
          },
        },
      },
      {
        name: "2024",
        data: dataParsed.map((dataPoint: any) => dataPoint.data[1]),
        dataLabels: {
          enabled: true,
          rotation: 0,
          inside: false,
          verticalAlign: "top",
          format: "{point.y}",
          style: {
            fontSize: "8px",
            fontWeight: "bold",
          },
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default VentasVendedores;
