import React from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { FaEye } from "react-icons/fa";
const ListProduct = (props: any) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.products ? (
            <MaterialTable
              columns={[
                {
                  title: "Codigo",
                  field: "nmc_code",
                  width: 20,
                },
                {
                  title: "Nombre",
                  field: "description",
                  width: 20,
                },
                { title: "Aplicación", field: "family_category", width: "80%" },
                {
                  title: "Colección",
                  field: "family_description",
                  width: "80%",
                },
                { title: "EAN", field: "ean", width: 0 },
                { title: "Unidades pack", field: "units_pack", width: 0 },
                { title: "Cant/Caja", field: "cant_box", width: 0 },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: false,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.products}
              title=""
              actions={[
                (rowData) => ({
                  icon: () => <FaEye />,
                  tooltip: "Ver",
                  onClick: (event, rowData) => props.showImages(rowData),
                }),
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
export default ListProduct;
