import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import ChargeList from "./ChargeList";
import { getCharges } from "../../services/contactsService";
import { useTranslation } from "react-i18next";

const ChargeListModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [charges, setcharges] = useState([]);

  useEffect(() => {
    getCharges().then((response) => {
      if (response?.data.ok) {
        setcharges(response?.data.Contactstypes);
      }
    });
  }, []);

  return (
    <div>
      <Modal size="xs" isOpen={props.isOpen} toggle={props.charge_toogle}>
        <ModalHeader>
          {t("SharedComponents.NewContact.SelectChargeModal.Title")}
        </ModalHeader>
        <ModalBody>
          <ChargeList charges={charges} charge_handler={props.charge_handler} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.charge_toogle}>
            {" "}
            {t("SharedComponents.NewContact.cancelButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ChargeListModal;
