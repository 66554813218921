import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Definición de la interfaz para los datos de ventas
interface DatosVentas {
  product_code: string;
  material_description: string;
  product_description: string;
  family_description: string;
  category_description: string;
  material_id: string;
  anio: string;
  mes: string;
  total_sales: string;
}

interface PieChartVentasPorMaterialProps {
  datos: DatosVentas[];
}

// Colores accesibles para personas con daltonismo
const accessibleColors = [
  "#1b9e77",
  "#d95f02",
  "#7570b3",
  "#e7298a",
  "#66a61e",
  "#e6ab02",
  "#a6761d",
  "#666666",
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
  "#1f78b4",
  "#33a02c",
  "#e31a1c",
  "#ff7f00",
  "#6a3d9a",
];

const PieChartVentasPorMaterial: React.FC<PieChartVentasPorMaterialProps> = ({
  datos,
}) => {
  const [options, setOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    // Sumar ventas totales por material
    const ventasPorMaterial = datos.reduce((acc, item) => {
      const material = item.material_description;
      const ventas = parseFloat(item.total_sales);
      if (!acc[material]) {
        acc[material] = 0;
      }
      acc[material] += ventas;
      return acc;
    }, {} as Record<string, number>);

    // Convertir datos a formato requerido por Highcharts
    const seriesData = Object.entries(ventasPorMaterial).map(
      ([material, ventas], index) => ({
        name: material,
        y: ventas,
        color: accessibleColors[index % accessibleColors.length], // Asignar color accesible
      })
    );

    // Configuración del gráfico
    setOptions({
      chart: {
        type: "pie",
      },
      title: {
        text: "Porcentaje Acumulado de Ventas por Material",
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          type: "pie", // Asegurarse de que el tipo esté especificado
          name: "Material",

          data: seriesData,
        },
      ],
    });
  }, [datos]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChartVentasPorMaterial;
