import React from "react";
import { Button } from "@material-ui/core";
import { Card, CardHeader } from "reactstrap";
import * as FaIcons from "react-icons/fa";
import "./MapHeader.scss";
import { Edit } from "@material-ui/icons";
//import { t } from "i18next";
import { CardBody } from "reactstrap";
import CustomerColaboratorsBadges from "../../../../shared/components/CustomerColaboratorsBadges";

const Header = (props: any) => {
  return (
    <div>
      <h5>
        <FaIcons.FaCity className="me-2" />
        {props.customer.name}
      </h5>
      <div>
        <div> {props.customer.address}</div>

        <div className="headerButton">
          <Button
            size="small"
            variant="contained"
            onClick={props.toggle_editadress}
          >
            Editar
          </Button>
        </div>
      </div>
      <hr />
      <table className="detailprojectTable">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{props.customer.id}</td>
          </tr>
          <tr>
            <th>Nombre</th>
            <td>{props.customer.alias}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  variant="contained"
                  onClick={props.toggle_alias}
                >
                  Editar
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <th>NIF/CIF</th>
            <td>{props.customer.nif}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  variant="contained"
                  onClick={props.toggle_nif}
                >
                  Editar
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <th>Ciudad</th>
            <td>{props.customer.city}</td>
          </tr>
          <tr>
            <th>Pais</th>
            <td>{props.customer.country}</td>
          </tr>
          <tr>
            <th>NMC Codigo</th>
            <td>{props.customer.nmc_code}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  variant="contained"
                  onClick={props.toggle_internalcode}
                >
                  Editar
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <th>Zona Comercial</th>
            <td>{props.customer.comercial_zone}</td>
          </tr>
          <tr>
            <th>Agente</th>
            <td>{props.customer.agente}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  variant="contained"
                  onClick={props.toggle_agente}
                >
                  Editar
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <Card>
        <CardHeader>
          Colaboradores
          <div className="btn_edition">
            <Button
              size="small"
              startIcon={<Edit />}
              variant="contained"
              onClick={props.toggle_colaborator}
            >
              Editar
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <CustomerColaboratorsBadges
            colaborators={props.colaborators}
            handleDeleteColaborator={props.handleDeleteColaborator}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default Header;
