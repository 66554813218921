import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { useHistory } from "react-router-dom";
import { getContactsAll } from "../services/contactsService";
import ListContacts from "./components/contacts/ListContacts";
const Contacts = () => {
  const history = useHistory();
  const [contacts, setcontacts] = useState([]);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const show = (data: any) => {
    if (data.type_id === 1) {
      window.open(`/layout/customer/${data.id_customer}`);
    }
    if (data.type_id === 2) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 3) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 4) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 5) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 6) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 7) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 8) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 9) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 10) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
    if (data.type_id === 11) {
      window.open(`/layout/channel/${data.id_customer}`);
    }
  };

  const refresh = () => {
    getContactsAll().then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.contactResults);
        setcontacts(response?.data.contactResults);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaAddressBook className="me-2" />
            Contactos
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Contactos</BreadcrumbItem>
              <BreadcrumbItem>Lista de Contactos</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={refresh}
              >
                <MdIcons.MdRefresh className="me-2" />
                Refrescar
              </button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Lista de Contactos</CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              {contacts ? (
                <ListContacts products={contacts} show={show} />
              ) : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Contacts;
