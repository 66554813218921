/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { getCustomersAll } from "../../../services/customerService";
import CustomerList from "./CustomerList";

const SelectCustomerModal = (props: any) => {
  const [customers, setcustomers] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getCustomersAll().then((response) => {
      console.table(response?.data.customers);
      if (response?.data.ok) {
        setcustomers(response?.data.customersResults);
        setloading(!loading);
      } else {
      }
    });
  };
  const edit = (item: any) => {
    props.ediCustomer(item);
  };
  return (
    <>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_customer}>
        <ModalHeader>Clientes</ModalHeader>
        <ModalBody>
          {loading ? (
            <CustomerList customers={customers} edit={edit} />
          ) : (
            <>
              <Button color="primary" disabled>
                <Spinner size="sm">Loading...</Spinner>
                <span> Cargando Clientes, por favor espera</span>
              </Button>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_customer}>CANCELAR</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SelectCustomerModal;
