import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

// Importa los módulos necesarios para gráficos polares y de tipo viento
import highchartsMore from "highcharts/highcharts-more";
import highchartsWindbarb from "highcharts/modules/windbarb";
highchartsMore(Highcharts);
highchartsWindbarb(Highcharts);

type ChartOptions = {
  chart: {
    polar: boolean;
    type: string;
  };
  credits: {
    enabled: boolean;
  };
  title: {
    text: string;
  };
  xAxis: {
    categories: string[];
  };
  series: {
    name: string;
    data: number[];
  }[];
};

type DatosVentasVendedor = Array<{
  nombreVendedor: string;
  tipoCliente: string;
  totalVentas: number;
}>;

type VentasPorVendedor = {
  [nombreVendedor: string]: {
    [tipoCliente: string]: number;
  };
};

const SalesWindRoseChart = (props: any) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    chart: {
      polar: true,
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Ventas por Vendedor y Canal",
    },
    xAxis: {
      categories: [],
    },
    series: [],
  });

  useEffect(() => {
    let datosConvertidos: DatosVentasVendedor | null = null;

    if (typeof props.datos === "string") {
      try {
        datosConvertidos = JSON.parse(props.datos);
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else {
      console.error("Formato de datos desconocido");
      return;
    }

    // Crear un mapa para agrupar las ventas por vendedor
    const ventasPorVendedor = datosConvertidos!.reduce<VentasPorVendedor>(
      (acc, { nombreVendedor, tipoCliente, totalVentas }) => {
        if (!acc[nombreVendedor]) {
          acc[nombreVendedor] = {};
        }
        acc[nombreVendedor][tipoCliente] = totalVentas;
        return acc;
      },
      {}
    );

    // Extraer los tipos de clientes (categorias del eje X)
    const tiposClientes = [
      ...new Set(datosConvertidos!.map((item) => item.tipoCliente)),
    ];

    // Crear las series para Highcharts
    const series = Object.keys(ventasPorVendedor).map((nombreVendedor) => {
      return {
        name: nombreVendedor,
        data: tiposClientes.map(
          (tipoCliente) => ventasPorVendedor[nombreVendedor][tipoCliente] || 0
        ),
      };
    });

    setChartOptions((prevOptions) => ({
      ...prevOptions,
      xAxis: {
        ...prevOptions.xAxis,
        categories: tiposClientes,
      },
      series: series,
    }));
  }, [props.datos]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default SalesWindRoseChart;
