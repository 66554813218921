/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { getOfferDetails } from "../../services/offersServices";
import OfferDetailList from "./OfferDetailList";

import "./style.scss";

const OfferDetailsModal = (props: any) => {
  const [offers, setoffers] = useState([]);
  const [end_date, setend_date] = useState<any | null>();
  const [showAnulationModal, setShowAnulationModal] = useState(false);
  const [anulationReason, setAnulationReason] = useState(0);
  const [src, setsrc] = useState<string>("");

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPdfSrc, setSelectedPdfSrc] = useState<string | null>(null);
  const [ext, setExt] = useState<string>("");

  useEffect(() => {
    getOfferDetails(props.idoffer).then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.offersResults);
        setsrc(response?.data.offersResults[0]?.src);
        setoffers(response?.data.offersResults);
      }
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const selectedDate = parseDate(props.offeritem.end_date);
    setend_date(addDays(selectedDate, 1));

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const parseDate = (date: any) => {
    const parsedDate = Date.parse(date);
    return new Date(parsedDate);
  };

  const addDays = (date: any, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const onchangeStatus = (event: any) => {
    if (event.target.value === "2") {
      setShowAnulationModal(true);
    } else {
      setShowAnulationModal(false);
      setAnulationReason(0);
      props.updateStatus(event.target.value, props.idoffer, 0);
    }
  };

  const onchangeDate = (date: any) => {
    const end_date = moment(date);
    const formattedDate = end_date.format("YYYY-MM-DD");
    props.updateEndDate(formattedDate, props.idoffer);
  };

  const onchangeAnulationReason = (event: any) => {
    setAnulationReason(event.target.value);
  };

  const toggleAnulationModal = () => {
    setShowAnulationModal(!showAnulationModal);
  };

  const onAnulateOffer = () => {
    if (anulationReason === 0) {
      alert("Selecciona el motivo");
    } else {
      props.updateStatus(2, props.idoffer, anulationReason);
      setShowAnulationModal(false);
      setAnulationReason(0);
    }
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const checkPDF = (src_doc: string, ext: string) => {
    setSelectedPdfSrc(src_doc);
    setExt(ext);
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_offer}>
        <ModalHeader>
          <Button onClick={() => checkPDF(src, "pdf")}>
            OFERTA {props.idoffer}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="exampleEmail">Cliente</Label>
                <Input
                  placeholder="with a placeholder"
                  type="text"
                  value={props.offeritem.name}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">Estado Actual</Label>
                <Input
                  placeholder="password placeholder"
                  type="text"
                  disabled
                  value={props.offeritem.status_offer_description}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">Editar Estado</Label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  onChange={onchangeStatus}
                >
                  <option selected>SELECCIONE</option>
                  <option value="0">OFERTA</option>
                  <option value="1">PEDIDO</option>
                  <option value="3">ENTREGADA</option>
                  <option value="2">ANULADA</option>
                </select>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">Editar Fecha Vencimiento</Label>
                <div className="datepicker-container">
                  <DatePicker
                    selected={end_date}
                    onChange={(date) => onchangeDate(date)}
                    dateFormat="yyyy-MM-dd"
                    locale={es}
                    className="form-control"
                  />
                </div>
              </FormGroup>
              {/* {showAnulationModal && (
                <Button color="secondary" onClick={toggleAnulationModal}>
                  Seleccionar motivo de anulación
                </Button>
              )} */}
            </Col>
          </Row>
          {offers ? <OfferDetailList offers={offers} /> : null}
          <br></br>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Button color="light" className="pull-right" disabled>
                  Total: {props.offeritem.total}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          {" "}
          <Modal isOpen={showAnulationModal} toggle={toggleAnulationModal}>
            <ModalHeader>Seleccionar motivo de anulación</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="anulationReason">Motivo de anulación</Label>
                <Input
                  type="select"
                  name="anulationReason"
                  id="anulationReason"
                  onChange={onchangeAnulationReason}
                  value={anulationReason}
                >
                  <option value="0">SELECCIONE</option>
                  <option value="1">Precio</option>
                  <option value="2">Competencia</option>
                  <option value="3">Disponibilidad</option>
                  <option value="4">Otros</option>
                </Input>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={onAnulateOffer}>
                Anular oferta
              </Button>{" "}
              <Button color="secondary" onClick={toggleAnulationModal}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </ModalFooter>
      </Modal>
      <Modal size="xl" isOpen={isOpen} toggle={handleOverlayClick}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          {selectedPdfSrc && ext === "pdf" ? (
            <iframe src={selectedPdfSrc} width="100%" height="600px"></iframe>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OfferDetailsModal;
