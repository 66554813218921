import axios from "axios";
import config from "./confserver.json";


export const getOffers = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/offersbyid', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getOffersFinalCustomers = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/offersfinalcustomer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getOffersInternalcode = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/offersbyinternalid', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getOffersAll = async () => {
  const data = {
    token: localStorage.getItem("token"),
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getofferall', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const getOffersByStatus = async (id:number) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getofferbystatus', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getOfferDetails = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getofferdetails', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const updateStatusOffer = async (id:number,idoffer:string,idannulation:any) => {
  const data = {
    token: localStorage.getItem("token"),
    idstatus: id,
    idoffer: idoffer,
    idannulation:idannulation
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/updatestatusoffer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const deleteOffer = async (idoffer:string) => {
  const data = {
    token: localStorage.getItem("token"),
    idoffer: idoffer,
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/deleteOffer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const updateEndDateOffer = async (date:any,idoffer:string) => {
  const data = {
    token: localStorage.getItem("token"),
    end_date: date,
    idoffer:idoffer
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/updateenddateoffer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}




