import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Asegúrate de tener react-icons instalado

interface ChangePasswordModalProps {
  isOpen: boolean;
  toggle: () => void;
  onChangePassword: (currentPassword: string, newPassword: string) => void;
}

const ChangePasswordModal = ({
  isOpen,
  toggle,
  onChangePassword,
}: ChangePasswordModalProps) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleToggle = () => {
    // Limpia los campos al cerrar el modal
    setCurrentPassword("");
    setNewPassword("");
    toggle();
  };

  const handleChangePassword = () => {
    if (currentPassword === "" || newPassword === "") {
      alert("Por favor, rellena todos los campos.");
      return;
    }

    // Enviar al componente padre
    onChangePassword(currentPassword, newPassword);
    setCurrentPassword("");
    setNewPassword("");
    // Cierra el modal
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader toggle={handleToggle}>Cambiar Contraseña</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="currentPassword">Contraseña Actual</Label>
          <InputGroup>
            <Input
              type={showCurrentPassword ? "text" : "password"}
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <InputGroupText
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
            >
              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroupText>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label for="newPassword">Nueva Contraseña</Label>
          <InputGroup>
            <Input
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputGroupText
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroupText>
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleChangePassword}>
          Cambiar
        </Button>
        <Button color="secondary" onClick={handleToggle}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePasswordModal;
