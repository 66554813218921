/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  Dropdown,
} from "reactstrap";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ReactCountryFlag from "react-country-flag";
import * as FaIcons from "react-icons/fi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { changePass, signOut } from "../services/loginService";
import ChangePasswordModal from "../shared/components/ChangePasswordModal";

const NavbarTop = (props: any) => {
  const { t, i18n } = useTranslation(["home", "login"]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  useEffect(() => {
    const storedLang = localStorage.getItem("language");
    if (storedLang) {
      if (storedLang === "es") {
        setSelectedLanguage("Spanish");
      }
      if (storedLang === "en") {
        setSelectedLanguage("English");
      }
      if (storedLang === "fr") {
        setSelectedLanguage("French");
      }

      i18n.changeLanguage(storedLang);
    } else {
      setSelectedLanguage("English");
      i18n.changeLanguage("es");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLanguageChange = (language: any) => {
    let lang;
    if (language === "English") {
      lang = "en";
    }
    if (language === "Spanish") {
      lang = "es";
    }
    if (language === "French") {
      lang = "fr";
    }
    localStorage.setItem("language", lang as string);
    i18n.changeLanguage(lang);

    setSelectedLanguage(language);
    console.log(selectedLanguage);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const logOut = () => {
    signOut().then((response) => {
      if (response.data.ok) {
        window.location.reload();
      } else {
      }
    });
  };

  const changePassword = () => {
    toggleModal();
  };

  const handleChangePassword = (
    currentPassword: string,
    newPassword: string
  ) => {
    changePass(currentPassword, newPassword)
      .then((response) => {
        if (response?.status === 200) {
          // Operación exitosa
          console.log("Contraseña cambiada con éxito");
          logOut(); // Desconectar al usuario
        } else {
          // Manejar otras respuestas
          console.log(
            "Error al cambiar la contraseña:",
            response?.data.message
          );
          alert(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Respuestas de error del servidor
          console.log("Error del servidor:", error.response.data.message);
          alert(error.response.data.message);
        } else if (error.request) {
          // La petición fue hecha pero no hubo respuesta
          console.log("El servidor no respondió");
        } else {
          // Algo más causó el error
          console.log("Error:", error.message);
        }
      });
  };

  const toggle = () => setDropdownOpen(!dropdownOpen);
  return (
    <div>
      <Navbar color="blue2" expand="md" light>
        <a
          className="btn btn-warning hamburger-menu"
          onClick={() => props.changeState(!props.active)}
        >
          <FaIcons.FiMenu className="me-0" />
        </a>
        <NavbarToggler onClick={function noRefCheck() {}} />

        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar></Nav>
        <Nav className="ms-auto" navbar>
          {t("Navbartop.Language")} :
        </Nav>
        <Nav className="ms-auto" navbar>
          <div className="form-group">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret className="bg-light text-dark btn-sm">
                {i18n.language === "en"
                  ? "English"
                  : i18n.language === "es"
                  ? "Español"
                  : " Français"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleLanguageChange("English")}>
                  English
                </DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange("Spanish")}>
                  Español
                </DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange("French")}>
                  Français
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Nav>

        <Nav className="ms-auto" navbar>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              {localStorage.getItem("fullname")}
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem onClick={() => changePassword()}>
                Cambiar Contraseña
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={() => logOut()}>Salir</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
      <ChangePasswordModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onChangePassword={handleChangePassword}
      />
    </div>
  );
};
export default NavbarTop;
