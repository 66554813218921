import React from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import MaterialTable from "material-table";
import { utils, writeFile } from "xlsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { FindInPage } from "@material-ui/icons";

const ListContacts = (props: any) => {
  const exportToExcel = () => {
    const ws = utils.json_to_sheet(props.products);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Contacts");
    writeFile(wb, "contacts.xlsx");
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={exportToExcel}>
            Exportar a Excel
          </Button>
          {props.products ? (
            <MaterialTable
              columns={[
                { title: "Nombre", field: "name", width: 20 },
                { title: "Apellido", field: "last_name", width: 20 },
                { title: "Cargo", field: "charge", width: "80%" },
                { title: "Email", field: "email" },
                { title: "Teléfono", field: "phone" },
                { title: "Cliente", field: "customer_name", width: 0 },
                { title: "Canal", field: "customer_type", width: 0 },
              ]}
              options={{
                toolbar: true,
                exportButton: false, // Desactiva el botón de exportación incorporado
                selection: false,
                search: false,
                paging: true,
                pageSize: 500,
                pageSizeOptions: [10, 20, 30, 40, 50, 100, 200, 300, 400, 500],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.products}
              title=""
              actions={[
                {
                  icon: () => <FindInPage />,

                  onClick: (event, rowData) => props.show(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: { filterTooltip: "Filtrar" },
                  editRow: {
                    deleteText: "¿Estás seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Guardar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Página Previa",
                  previousTooltip: "Página Previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar Columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default ListContacts;
