import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import {
  getCustomersOfferPieByProduct,
  getCustomersOfferPieByProductDetail,
  gettotalProductOfferByStatus,
} from "../../../services/chartsService";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import OffersDetailsProductList from "../../../shared/components/charts/OffersDetailsProductList";
require("highcharts/modules/exporting")(Highcharts);

const ChartProductsOffersAnulados = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenDetalle, setIsModalOpenDetalle] = useState<boolean>(false);
  const [pieData, setpieData] = useState([]);
  const [totalY, settotalY] = useState(null);
  const [productName, setproductName] = useState("");
  const [referenceName, setreferenceName] = useState("");
  const [offers, setoffers] = useState([]);
  const [productsoffersanulada, setproductsoffersanulada] = useState([]);
  const [totalY_anulado, settotalY_anulado] = useState(0);
  const [loading, setLoading] = useState<boolean>(true); // Estado para el loader

  useEffect(() => {
    gettotalProductOfferByStatus(2).then((response) => {
      if (response?.data) {
        let totalObj = response?.data.find((item: any) => item.total_y);
        settotalY_anulado(totalObj);
        setproductsoffersanulada(response?.data);
      }
      setLoading(false); // Cambia el estado del loader a false una vez que los datos se cargan
    });
  }, []);

  const ProductOffersInformation = (name: string) => {
    setLoading(true); // Mostrar loader al cargar información del producto
    getCustomersOfferPieByProduct(2, name).then((response) => {
      setproductName(name);
      if (response?.data) {
        let totalObj = response?.data.find((item: any) => item.total_y);
        settotalY(totalObj);
        setpieData(response?.data);
        setIsModalOpen(true);
      }
      setLoading(false); // Ocultar loader después de cargar la información del producto
    });
  };

  const ProductOffersInformationDetail = (name: string) => {
    setLoading(true); // Mostrar loader al cargar detalles del producto
    setreferenceName(name);
    getCustomersOfferPieByProductDetail(2, productName, name).then(
      (response) => {
        if (response?.data) {
          setoffers(response?.data);
          setIsModalOpenDetalle(true);
        }
        setLoading(false); // Ocultar loader después de cargar los detalles del producto
      }
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setpieData([]);
  };
  const toggleModalDetalle = () => {
    setIsModalOpenDetalle(!isModalOpenDetalle);
  };

  const options = {
    colors: [
      "#C0392B",
      "#9900ff",
      "#6600cc",
      "#0099e6",
      "#0066ff",
      "#005EBB",
      "#ffe680",
      "#ff9900",
      "#ff3300",
      "#000d1a",
    ],
    chart: {
      type: "column",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Productos Anulados",
    },
    subtitle: {
      //@ts-ignore
      text: `Total: ${totalY_anulado?.total_y}`,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    xAxis: {
      type: "category",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              ProductOffersInformation(
                // @ts-ignore
                this.name
              );
            },
          },
        },
      },
    },
    series: [{ name: "€ Total", data: productsoffersanulada }],
    loading: false,
  };

  const ChartPieoptions = {
    colors: [
      "#FF0000",
      "#FF4000",
      "#FF8000",
      "#FFBF00",
      "#FFFF00",
      "#BFFF00",
      "#80FF00",
      "#00FF00",
      "#00FF80",
      "#00FFFF",
      "#0080FF",
      "#0000FF",
      "#4000FF",
      "#8000FF",
      "#BF00FF",
      "#FF00FF",
      "#FF40FF",
      "#FF80FF",
      "#FFC0CB",
      "#FF8C69",
      "#FF6EC7",
      "#8B0000",
      "#8B4513",
      "#808080",
    ],
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: productName,
    },
    subtitle: {
      // @ts-ignore
      text: `TOTAL: ${totalY?.total_y}`,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: <b>{point.percentage:.1f} %</b>",
          useHTML: true,
        },
        showInLegend: true,
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              // @ts-ignore
              ProductOffersInformationDetail(this.name);
            },
          },
        },
      },
    },
    series: [{ data: pieData }],
    loading: false,
  };

  return (
    <>
      <div>
        {loading ? (
          <Spinner color="primary" /> // Componente de carga
        ) : (
          <>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <Modal size="xl" isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}></ModalHeader>
              <ModalBody>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={ChartPieoptions}
                />
              </ModalBody>
            </Modal>
            <Modal
              size="xl"
              isOpen={isModalOpenDetalle}
              toggle={toggleModalDetalle}
            >
              <ModalHeader toggle={toggleModalDetalle}></ModalHeader>
              <ModalBody>
                <OffersDetailsProductList
                  offers={offers}
                  referenceName={referenceName}
                  showCustomer={props.showCustomer}
                />
              </ModalBody>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default ChartProductsOffersAnulados;
