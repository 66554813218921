import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import heatmap from "highcharts/modules/heatmap";

// Inicializa el módulo heatmap
heatmap(Highcharts);

const HeathMapChart = (props: any) => {
  let datosProcesados;

  // Manejar diferentes tipos de entrada para 'props.data'
  if (typeof props.data === "string") {
    try {
      datosProcesados = JSON.parse(props.data);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      return <p>Error al procesar los datos.</p>;
    }
  } else if (Array.isArray(props.data)) {
    datosProcesados = props.data;
  } else {
    console.error("Formato de datos desconocido");
    return <p>Formato de datos desconocido.</p>;
  }

  const getYAxisCategories = (data: any[]) => {
    // Anotación de tipo para `uniqueYears`
    const uniqueYears: { [key: string]: boolean } = {};
    data.forEach((item) => {
      uniqueYears[item.anio.toString()] = true;
    });

    return Object.keys(uniqueYears)
      .map(Number)
      .sort((a, b) => a - b);
  };

  const processData = (data: any[]) => {
    return data.map((item) => {
      const yearIndex = item.anio - Math.min(...data.map((d) => d.anio));
      return [item.mes, yearIndex, item.totalVentas];
    });
  };

  const chartData = processData(datosProcesados);
  const yAxisCategories = getYAxisCategories(datosProcesados);

  const { colorScheme } = props;

  const options = {
    chart: {
      type: "heatmap",
      plotBorderWidth: 1,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Mapa de Calor - Ventas Mensuales por Año",
    },
    xAxis: {
      categories: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    },
    yAxis: {
      categories: yAxisCategories,
      title: null,
    },
    colorAxis: {
      min: 0,
      minColor: colorScheme.minColor || "#FFFFFF",
      maxColor: colorScheme.maxColor || "#B03A2E",
      stops: colorScheme.stops || [
        [0, "#FFFFFF"],
        [0.5, "#FFEDA0"],
        [1, "#B03A2E"],
      ],
    },
    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },
    tooltip: {
      // @ts-ignore
      formatter: function () {
        return `<b>${
          // @ts-ignore
          this.series.xAxis.categories[this.point.x]
          // @ts-ignore
        }</b> ventas: <b>${this.point.value}</b><br/>Año: <b>${
          // @ts-ignore
          this.series.yAxis.categories[this.point.y]
        }</b>`;
      },
    },
    series: [
      {
        name: "Ventas por mes",
        data: chartData,
        dataLabels: {
          enabled: true,
          color: "#000000",
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HeathMapChart;
