import React, { useEffect, useState } from "react";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Definición de la interfaz para los datos de ventas
interface DatosVentas {
  product_code: string;
  material_description: string;
  product_description: string;
  family_description: string;
  category_description: string;
  material_id: string;
  anio: string;
  mes: string;
  total_sales: string;
}

interface TendenciaVentasMensualesPorCategoriaChartProps {
  datos: DatosVentas[];
}

// Colores accesibles para personas con daltonismo
const accessibleColors = [
  "#1b9e77",
  "#d95f02",
  "#7570b3",
  "#e7298a",
  "#66a61e",
  "#e6ab02",
  "#a6761d",
  "#666666",
  "#8dd3c7",
  "#ffffb3",
  "#bebada",
  "#fb8072",
  "#80b1d3",
  "#fdb462",
  "#b3de69",
  "#fccde5",
  "#d9d9d9",
  "#bc80bd",
  "#ccebc5",
  "#ffed6f",
  "#1f78b4",
  "#33a02c",
  "#e31a1c",
  "#ff7f00",
  "#6a3d9a",
];

const TendenciaVentasMensualesPorCategoriaChart: React.FC<
  TendenciaVentasMensualesPorCategoriaChartProps
> = ({ datos }) => {
  const [options, setOptions] = useState<Highcharts.Options>({});

  useEffect(() => {
    // Obtener lista de categorías únicas
    const categorias = [...new Set(datos.map((d) => d.category_description))];

    // Generar series de datos para el gráfico
    const series: SeriesOptionsType[] = categorias.map((categoria, index) => {
      // Filtrar datos para la categoría específica
      const datosFiltrados = datos.filter(
        (d) => d.category_description === categoria
      );

      // Crear un arreglo de 12 meses con sumas de ventas
      const data = Array.from({ length: 12 }, (_, i) => {
        const mesData = datosFiltrados.filter((d) => Number(d.mes) === i + 1);
        return mesData.length > 0
          ? mesData.reduce((sum, d) => sum + parseFloat(d.total_sales), 0)
          : null;
      });

      return {
        type: "line",
        name: categoria,
        data,
        color: accessibleColors[index % accessibleColors.length], // Asignar color accesible
      };
    });

    // Configuración del gráfico
    setOptions({
      chart: {
        type: "line",
      },
      title: {
        text: "Tendencia de Ventas Mensuales por Categoría",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      },
      yAxis: {
        title: {
          text: "Total Ventas",
        },
        labels: {
          formatter: function () {
            return Number(this.value).toFixed(2); // Formatear a 2 decimales
          },
        },
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.y:.2f}</b>", // Formatear a 2 decimales
        valueDecimals: 2, // Asegurar 2 decimales
      },
      series,
    });
  }, [datos]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TendenciaVentasMensualesPorCategoriaChart;
