/* eslint-disable no-useless-concat */
import React from "react";
import { Button, Grid } from "@material-ui/core";
import FindInPage from "@material-ui/icons/FindInPage";
import MaterialTable from "material-table";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx";
const ListProject = (props: any) => {
  const renderColorSquare = (rowData: any) => {
    const color = rowData.CustomerColorReport;
    return (
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: color,
          border: "1px solid #000",
        }}
      ></div>
    );
  };
  const formatDate = (dateString: string | null) => {
    return dateString ? dateString.split(" ")[0] : "";
  };
  const exportToExcel = () => {
    const data = props.customers.map((customer: any) => ({
      ID: customer.id,
      Nombre: customer.name,
      Comercial: customer.comercial,
      "Zona Comercial": customer.zone_comercial,
      Alias: customer.alias,
      "Codigo NMC": customer.nmc_code,
      "NIF/CIF": customer.NIF,
      Dirección: customer.address,
      "Cliente Asociado": customer.associatedCustomerName,
      Canal: customer.customertypename,
      "Días U. Gestión": customer.diffDays,
      "Prox. Gestión": formatDate(customer.nextVisit),
      Estado: customer.CustomerColorReport, // Assuming you want to export the color value
    }));

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Proyectos");
    const fileName = `proyectos.xlsx`;
    writeFile(wb, fileName);
  };
  const { t } = useTranslation(["home", "projects"]);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: "10px" }}
          >
            Exportar a Excel
          </Button>
          {props.customers ? (
            <MaterialTable
              columns={[
                { title: "ID", field: "id", width: "2%" },

                {
                  title: `${t("projects.ListProject.comercialZoneTitle")}`,
                  field: "zone_comercial",
                  width: 20,
                },
                {
                  title: `${t("projects.ListProject.nameTitle")}`,
                  field: "name",
                  width: "80%",
                },
                {
                  title: `${t("projects.ListProject.comercialTitle")}`,
                  field: "comercial",
                  width: "80%",
                },
                {
                  title: `${t("projects.ListProject.addressTitle")}`,
                  field: "address",
                  width: "80%",
                },

                {
                  title: "Cliente",
                  field: "associatedCustomerName",
                  width: "80%",
                },
                {
                  title: "Canal",
                  field: "customertypename",
                  width: "80%",
                },
                { title: "Días U. Gestión", field: "diffDays", width: "80%" },
                {
                  title: "Prox. Gestión",
                  field: "nextVisit",
                  width: "80%",
                  render: (rowData) => formatDate(rowData.nextVisit),
                },
                {
                  title: "Estado",
                  field: "state",
                  width: "80%",
                  render: renderColorSquare,
                },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: false,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  background: "#F5F5F5",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.customers}
              title=""
              actions={[
                {
                  icon: () => <FindInPage />,
                  tooltip: `${t("projects.ListProject.tooltipTitle")}`,
                  onClick: (event, rowData) => props.show(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: `${t(
                    "MaterialTable.emptyDataSourceMessage"
                  )}`,
                  addTooltip: `${t("MaterialTable.addTooltip")}`,
                  deleteTooltip: `${t("MaterialTable.deleteTooltip")}`,
                  editTooltip: `${t("MaterialTable.editTooltip")}`,
                  filterRow: {
                    filterTooltip: `${t("MaterialTable.filterTooltip")}`,
                  },
                  editRow: {
                    deleteText: `${t("MaterialTable.deleteText")}`,
                    cancelTooltip: `${t("MaterialTable.cancelTooltip")}`,
                    saveTooltip: `${t("MaterialTable.saveTooltip")}`,
                  },
                },
                grouping: {
                  placeholder: `${t("MaterialTable.placeholder")}`,
                  groupedBy: `${t("MaterialTable.groupedBy")}`,
                },
                header: {
                  actions: `${t("MaterialTable.actions")}`,
                },
                pagination: {
                  // eslint-disable-next-line no-useless-concat
                  labelDisplayedRows:
                    "{from}-{to}" +
                    `${t("MaterialTable.labelDisplayedRows")}` +
                    "{count}",
                  labelRowsSelect: `${t("MaterialTable.labelRowsSelect")}`,
                  labelRowsPerPage: `${t("MaterialTable.labelRowsPerPage")}`,
                  firstAriaLabel: `${t("MaterialTable.firstAriaLabel")}`,
                  firstTooltip: `${t("MaterialTable.firstTooltip")}`,
                  previousAriaLabel: `${t("MaterialTable.previousAriaLabel")}`,
                  previousTooltip: `${t("MaterialTable.previousTooltip")}`,
                  nextAriaLabel: `${t("MaterialTable.nextAriaLabel")}`,
                  nextTooltip: `${t("MaterialTable.nextTooltip")}`,
                  lastAriaLabel: `${t("MaterialTable.lastAriaLabel")}`,
                  lastTooltip: `${t("MaterialTable.lastTooltip")}`,
                },
                toolbar: {
                  addRemoveColumns: `${t("MaterialTable.addRemoveColumns")}`,
                  nRowsSelected: "{0}" + `${t("MaterialTable.nRowsSelected")}`,
                  showColumnsTitle: `${t("MaterialTable.showColumnsTitle")}`,
                  showColumnsAriaLabel: `${t(
                    "MaterialTable.showColumnsAriaLabel"
                  )}`,
                  exportTitle: `${t("MaterialTable.exportTitle")}`,
                  exportAriaLabel: `${t("MaterialTable.exportAriaLabel")}`,
                  searchTooltip: `${t("MaterialTable.searchTooltip")}`,
                  searchPlaceholder: `${t("MaterialTable.searchPlaceholder")}`,
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
export default ListProject;
