import React, { useRef } from "react";
import MaterialTable from "material-table";
import { Button, Grid } from "@material-ui/core";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { utils, writeFile } from "xlsx";

const OffersList = (props: any) => {
  const tableRef = useRef<any>(null);

  const exportToExcel = (data: unknown[], fileName: string) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleExportExcel = () => {
    const filteredData = tableRef.current?.dataManager?.filteredData || [];
    console.log("Filtered Data:", filteredData);

    const exportData = filteredData.flatMap((offer: any) => {
      const baseOfferInfo = {
        id: offer.customerid,
        oferta: offer.idoffer,
        codigo_interno: offer.reference,
        cliente: offer.reference_name,
        nombre: offer.name,
        comercial: offer.fullname,
        fecha_oferta: offer.date_offer,
        vencimiento: offer.end_date,
        estado: offer.status_offer_description,
        total: offer.total,
      };

      // Generar columnas de comentarios dinámicamente
      const comments = offer.reports
        .map((report: any, index: number) => ({
          [`comentario${index + 1}`]: report.description,
        }))
        .reduce((acc: any, cur: any) => ({ ...acc, ...cur }), {});

      return offer.productsDetails.map((product: any) => ({
        ...baseOfferInfo,
        ...comments, // Añadir comentarios a la exportación
        id_producto: product.id_product,
        descripcion_producto: product.productDescription,
        um: product.um,
        precio: product.price,
        cantidad: product.quantity,
        descuento: product.discount,
        iva: product.iva,
        total_producto: product.total,
      }));
    });

    const fileName = "OFERTAS_DETALLES";
    exportToExcel(exportData, fileName);
  };

  return (
    <div>
      {props.offers.length > 0 && (
        <div>
          <Button className="custom-export-button" onClick={handleExportExcel}>
            Exportar a Excel
          </Button>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.offers && (
            <MaterialTable
              tableRef={tableRef}
              columns={[
                {
                  title: "ID",
                  field: "customerid",
                  render: (rowData) => (
                    <Button onClick={() => props.showCustomer(rowData)}>
                      {rowData.customerid}
                    </Button>
                  ),
                },
                { title: "OFERTA", field: "idoffer" },
                { title: "CLIENTE", field: "reference_name" },
                { title: "NOMBRE", field: "name" },
                { title: "TIPO", field: "customertypename" },
                { title: "COMERCIAL", field: "fullname" },
                { title: "FECHA OFERTA", field: "date_offer" },
                { title: "VENCIMIENTO", field: "end_date" },
                { title: "ESTADO", field: "status_offer_description" },
                { title: "TOTAL", field: "total" },
              ]}
              options={{
                toolbar: true,
                exportButton: false,
                selection: false,
                search: true,
                paging: false,
                pageSize: 15,
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => {
                  const expirationDate = new Date(rowData.end_date);
                  const today = new Date();

                  return {
                    fontSize: 13,
                    color: expirationDate > today ? "#676A6C" : "red",
                    background: "#F5F5F5",
                    backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                  };
                },
                filtering: true,
              }}
              data={props.offers}
              title="Ofertas"
              actions={[
                {
                  icon: () => <FaEye />,
                  tooltip: "Detalle",
                  onClick: (event, rowData) => props.offer_handler(rowData),
                },
                {
                  icon: () => <FaTrashAlt />,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) => props.delete_offer(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default OffersList;
