import React, { useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./Map_shared.scss";

import MapPopUp from "./MapPopUp";
const blueIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const redIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const yellowIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const greenIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const blackIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-black.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const orangeIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-orange.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const violetIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const greyIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

const goldIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-gold.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

interface Categoria {
  type_id: string;
  description: string;
  // Agrega cualquier otra propiedad necesaria
}

interface MapLegendProps {
  categorias: Categoria[];
}

const colorMapping: { [key: string]: string } = {
  "1": "#CDC53B", // Color asociado a blueIcon
  "2": "#FFD700", // Color para goldIcon (dorado)
  "3": "#FFFF00", // Color para yellowIcon (amarillo)
  "4": "#FF0000", // Color para redIcon (rojo)
  "5": "#008000", // Color para greenIcon (verde)
  "6": "#FFA500", // Color para orangeIcon (naranja)
  "7": "#808080", // Color para greyIcon (gris)
  "8": "#EE82EE", // Color para violetIcon (violeta)
  "9": "#000000", // Color para blackIcon (negro)
  "10": "#0000FF", // Otro color asociado a blueIcon (azul)
  "11": "#FFD700", // Otro color para goldIcon (dorado)
  // Puedes ajustar estos colores según tus necesidades
};

// Función para obtener el color basado en typeId
const getColorForTypeId = (typeId: string): string => {
  return colorMapping[typeId] || "#000000"; // Devuelve un color por defecto si no se encuentra el typeId
};

const getIconForTypeId = (typeId: any) => {
  switch (typeId) {
    case "1":
      return blueIcon;
    case "2":
      return goldIcon;
    case "3":
      return yellowIcon;
    case "4":
      return redIcon;
    case "5":
      return greenIcon;
    case "6":
      return orangeIcon;
    case "7":
      return greyIcon;
    case "8":
      return violetIcon;
    case "9":
      return blackIcon;
    case "10":
      return blueIcon;
    case "11":
      return goldIcon;
    default:
      return blueIcon; // Ícono por defecto si no coincide ningún typeId
  }
};

const MapLegend2 = ({ categorias }: MapLegendProps) => {
  return (
    <div className="map-legend">
      <h4>Canales</h4>
      <ul>
        {categorias.map((categoria, index) => (
          <li key={index}>
            <span
              className="legend-icon"
              style={{ backgroundColor: getColorForTypeId(categoria.type_id) }}
            ></span>
            {categoria.description}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Map = (props: any) => {
  const [position] = useState({
    lat: 39.707186656826565,
    lng: -4.746093750000001,
  });

  let datosConvertidos;

  if (typeof props.datos === "string") {
    try {
      datosConvertidos = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      //return;
    }
  } else if (Array.isArray(props.datos)) {
    datosConvertidos = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    //return;
  }

  return (
    <div>
      <MapContainer className="mapDetails_shared" center={position} zoom={6}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {datosConvertidos.map(
          (items: {
            typeId: any;
            latitude: any;
            longitude: any;
            id: number;
            nombreCliente: string;
            tipoClienteDesc: string;
            address: string;
            country: string;
            totalVentas: any;
          }) => (
            <Marker
              position={[items.latitude, items.longitude]}
              // eslint-disable-next-line no-eval
              icon={getIconForTypeId(items.typeId)}
              key={items.id}
              draggable={false}
            >
              <Popup className="custom-popup">
                <MapPopUp
                  show={props.show}
                  name={items.nombreCliente}
                  description={items.tipoClienteDesc}
                  id={items.id}
                  address={items.address}
                  country={items.country}
                  totalVentas={items.totalVentas}
                />
              </Popup>
            </Marker>
          )
        )}
        <MapLegend2 categorias={props.categorias} />
      </MapContainer>
    </div>
  );
};

export default Map;
