import axios from "axios";
import config from "./confserver.json";


export const getReports = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/reports', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getReportsByUser = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id_enterprise: 1,
    id_user:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/reportsbyuser', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getReportsByUserDate = async (id:any,date1:any,date2:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id_enterprise: 1,
    id_user: id,
    startDate: date1,
    endDate:date2
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/reportsbyuserdate', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const getReportsByDate = async (date1:any,date2:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id_enterprise: 1,
    startDate: date1,
    endDate:date2
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/reportsbydate', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getReportsToday = async () => {
  const data = {
    token: localStorage.getItem("token"),
    id_enterprise: 1,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/reportstoday',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};


export const getAlerts = async () => {
  const data = {
    token: localStorage.getItem("token"),
    id_enterprise: 1,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/getalerts',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};


export const newEvent = async (eventData: { id_customer: any; description: any; selectedDate: any; userid: any; }) => {
  // Extrae las propiedades necesarias del objeto eventData
  const { id_customer, description, selectedDate, userid } = eventData;
  const token = localStorage.getItem("token"); // Asume que el token ya está almacenado en localStorage
  const createdby = localStorage.getItem("userid");

  // Prepara el objeto de datos para la solicitud POST
  const data = {
    token,
    id_customer,
    description,
    selectedDate, // Asegúrate de que esta fecha tenga el formato correcto para tu backend
    userid,
    createdby
  
  };

  try {
    const response = await axios.post(
      `${config.SERVER_URL}/api/newevent`, // Asegúrate de que la URL sea correcta
      data,
    );
    
    // Verifica si la respuesta es correcta
    if (response.status === 200 && response.data.ok) {
      return response.data;
    } else {
      // Maneja la situación en la que el servidor responde con un código diferente a 200 o la propiedad ok es false
      throw new Error('Error al crear el evento');
    }
  } catch (e) {
    // Puede ser una buena idea manejar errores específicos o simplemente re-lanzarlos
    console.error(e);
    throw e; // Re-lanza el error para manejarlo en una capa superior si es necesario
  }
};




export const newReport = async (item:any,id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    description: item.report,
    id_customer: id,
    id_enterprise: 1,
    id_user: localStorage.getItem("userid"),
    multimedia_id:1

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/newreport', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}