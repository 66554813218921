import axios from "axios";
import config from "./confserver.json";


export const getUsers = async () => {
  const data = {
    token: localStorage.getItem("token")
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/users', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getUserscharges = async () => {
  const data = {
    token: localStorage.getItem("token")
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getuserscharges', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const saveUserEdition = async (id:number, name:string, last_name:string, fone:string, email:string , code:string , dep:number,isManager:boolean,operation:boolean,enabled:boolean,is_internal:boolean) => {
  const data = {
    token: localStorage.getItem("token"),
    id,
    name,
    last_name,
    fone,
    email,
    code,
    dep,
    isManager,
    operation,
    enabled,
    is_internal
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/edituser', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const saveNewUser = async (name: string, last_name: string, fone: string, email: string, code: string, dep: number, isManager: boolean, operation: boolean, enabled: boolean, is_internal: boolean) => {
  const data = {
    token: localStorage.getItem("token"),
    name,
    last_name,
    fone,
    email,
    code,
    dep,
    isManager,
    operation,
    enabled,
    is_internal
  }
  try {
    const response = await axios.post(config.SERVER_URL + '/api/newuser', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};