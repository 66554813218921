import axios from "axios";
import config from "./confserver.json";

export const signIn = async (credentials: any) => {
  
  try {
    const response = await axios.post(config.SERVER_URL+'/api/login', credentials);
    if (response.status === 200) {
      if (response.data.data.ok) {
        console.log("el nuevo token es "+response.data.data.token);
        const full_name = response.data.data.name + " " + response.data.data.last_name
        localStorage.setItem("userid", response.data.data.id);
        localStorage.setItem("username", response.data.data.email);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("fullname", full_name);
        localStorage.setItem("enterprise", response.data.data.enterprise);
        localStorage.setItem("enterprisename", response.data.data.enterprise_name);
  
        return response.data;
      } else {
         return response.data;
      }
     
    }
  } catch (e) {
    console.log("usuario o contraseña incorrecta");
  }
  
}

export const signOut = async () => {
  
   const data = {
    token: localStorage.getItem("token"),
  }

    try {
        const response = await axios.post(config.SERVER_URL + '/api/logout', data);

        if (response.status === 200) {
            // Limpiar localStorage
            localStorage.removeItem("userid");
            localStorage.removeItem("username");
            localStorage.removeItem("token");
            localStorage.removeItem("fullname");
            localStorage.removeItem("enterprise");
            localStorage.removeItem("enterprisename");

            return response.data;
        }
    } catch (e) {
        console.log("Error during logout", e);
    }
}


export const checkToken = async () => {

  console.log("Verificando token " + localStorage.getItem("token"));
  const data = {
    token: localStorage.getItem("token"),
    iduser: localStorage.getItem("userid"),
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/checktoken', data);
    if (response.status === 200) {
    
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const changePass = async (currentPassword: string, newPassword: string) => {
  const data = {
    userId: localStorage.getItem("userid"),
    currentPassword,
    newPassword,
  };

  try {
    const response = await axios.post(`${config.SERVER_URL}/api/changepass`, data);
    return response;
  } catch (error) {
    // Puedes lanzar el error o manejarlo aquí mismo
    throw error;
  }
};
