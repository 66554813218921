import axios from "axios";
import config from "./confserver.json";

export const getCustomers = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    type:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/customers', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersDesktop = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    type:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/customersdesktop', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomersAll = async () => {
  const data = {
    token: localStorage.getItem("token"),
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getcustomerall', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomerById = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/customer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getCustomerByStore = async (type: any, id: any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    type: type,
    id: id,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/projectsbystore',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export const addCustomer = async (item:any) => {
  const data = {
    token: localStorage.getItem("token"),
    name: item.promotername,
    alias:item.alias,
    address: item.promoteraddress,
    codenmc: item.codenmc,
    nif: item.promoternif,
    lat: item.latitud,
    lng: item.longitud,
    type:item.type
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/newcustomer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const addNewProject = async (item:any) => {
  const data = {
    token: localStorage.getItem("token"),
    name: item.name,
    address: item.address,
    lat: item.latitud,
    lng: item.longitud,
    type:item.type
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/newproject', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const editProjectData = async (id:any,item:any,type:number) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
    id_item: item.id,
    type:type
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/editproject', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const newCustomerCategory = async (id:any,item:any,type:number) => {
  const data = {
    token: localStorage.getItem("token"),
    id_proyect: id,
    selected_record_id: item.id,
    category_id:type
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/newcustomercategory', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const getCustomerCategories = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
  
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getcustomercategories', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteCustomerCategory = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
  
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/deletecustomercategory', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const editAdressCustomer = async (item :any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: item.id,
    finaladress: item.finaladress,
    lat: item.latitud,
    lng:item.longitud
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/editadress', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const editNameCustomer = async (item :any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: item.id,
    finaldata: item.finaldata,
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/editprojectname', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const getPhases = async () => {
  const data = {
    token: localStorage.getItem("token")
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/phases', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const getCustomerType = async () => {
  const data = {
    token: localStorage.getItem("token")
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getcustomerstype', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const deleteCustomer = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/deleteCustomer', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const obtenerCategorias = async () => {
  const data = {
    token: localStorage.getItem("token")
    
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getcategories', data);
    if (response.status === 200) {
      return response.data.categoriesResults;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const addColaborator = async (id: any, usertoadd: number) => {
  const data = {
    token: localStorage.getItem("token"),
    customer_id: id,
    usertoadd: usertoadd,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/addcolaborator',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export const deletecolaborator = async (id_customer: any, id: any) => {
  const data = {
    token: localStorage.getItem("token"),
    id: id,
    id_customer: id_customer,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/deletecolaborator',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export const getcolaborators = async (id: any) => {
  const data = {
    token: localStorage.getItem("token"),
    id_customer: id,
  };
  try {
    const response = await axios.post(
      config.SERVER_URL + '/api/getcolaborators',
      data,
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};