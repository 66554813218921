import React, { useEffect, useState } from "react";
import "./gallery.scss";

const MyImageGallery: React.FC<any> = (props) => {
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);

  useEffect(() => {
    // Agregar el manejador de eventos al montar el componente
    document.addEventListener("keydown", handleKeyPress);

    // Eliminar el manejador de eventos al desmontar el componente
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageClick = (id: string) => {
    setSelectedImageId(id);
  };

  const handleDeleteImage = (id: string) => {
    // Aquí puedes implementar la lógica para eliminar la imagen según su ID
    //console.log(`Eliminando imagen con ID: ${id}`);

    props.deleteCustomerImage(id);
    handleClose();
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      // Cerrar solo si se hace clic en el contenedor exterior (overlay)
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedImageId(null);
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      // Cerrar si se presiona la tecla Escape (ESC)
      handleClose();
    }
  };

  return (
    <div>
      <div className="image-grid">
        {props.images.map((image: any) => (
          <img
            key={image.id}
            src={image.thumbnail}
            alt={image.caption}
            onClick={() => handleImageClick(image.id)}
            style={{
              width: image.thumbnailWidth,
              height: image.thumbnailHeight,
              cursor: "pointer",
            }}
          />
        ))}
      </div>
      {selectedImageId && (
        <div className="selected-image-overlay" onClick={handleOverlayClick}>
          <div className="selected-image-container">
            <img
              src={
                props.images.find((image: any) => image.id === selectedImageId)
                  ?.src
              }
              alt={
                props.images.find((image: any) => image.id === selectedImageId)
                  ?.caption
              }
              className="selected-image"
            />
            <p>
              {
                props.images.find((image: any) => image.id === selectedImageId)
                  ?.caption
              }
            </p>
            <div className="button-container">
              <button onClick={() => handleDeleteImage(selectedImageId)}>
                Eliminar
              </button>
              <button
                className="close-button"
                onClick={() => setSelectedImageId(null)}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyImageGallery;
