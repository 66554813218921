import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import {
  getProductOfferDetailPieByUser,
  getProductOfferPieByUser,
  gettotalSellerOfferByStatus,
} from "../../../services/chartsService";
import OffersDetailsList from "./OffersDetailsList";

require("highcharts/modules/exporting")(Highcharts);

const OffersSellerChart = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenDetalle, setIsModalOpenDetalle] = useState<boolean>(false);
  const [pieData, setpieData] = useState([]);
  const [totalY, settotalY] = useState(null);
  const [sellerName, setsellerName] = useState("");
  const [codigouser, setiduser] = useState(null);
  const [statusOffer, setstatusOffer] = useState(null);
  const [offers, setoffers] = useState([]);
  const [totalselleroffer, settotalselleroffer] = useState(null);
  const [loading, setLoading] = useState<boolean>(true); // Estado para el loader

  useEffect(() => {
    gettotalSellerOfferByStatus(1).then((response) => {
      if (response?.data) {
        settotalselleroffer(response?.data);
      }
      setLoading(false); // Cambia el estado del loader a false una vez que los datos se cargan
    });
  }, []);

  const sellerOffersInformation = (
    iduser: any,
    status_offer: any,
    name: string
  ) => {
    setLoading(true); // Mostrar loader al cargar información del vendedor
    setsellerName(name);
    setiduser(iduser);
    setstatusOffer(status_offer);
    getProductOfferPieByUser(status_offer, iduser).then((response) => {
      if (response?.data) {
        let totalObj = response?.data.find((item: any) => item.total_y);
        settotalY(totalObj);
        setpieData(response?.data);
        setIsModalOpen(true);
      }
      setLoading(false); // Ocultar loader después de cargar la información del vendedor
    });
  };

  const sellerOffersInformationDetails = (name: string) => {
    setLoading(true); // Mostrar loader al cargar detalles del vendedor
    getProductOfferDetailPieByUser(codigouser, statusOffer, name).then(
      (response) => {
        if (response?.data) {
          setoffers(response?.data);
          setIsModalOpenDetalle(true);
        }
        setLoading(false); // Ocultar loader después de cargar los detalles del vendedor
      }
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setpieData([]);
  };

  const toggleModalDetalle = () => {
    setIsModalOpenDetalle(!isModalOpenDetalle);
  };

  const options = {
    colors: [props.color],
    chart: {
      type: "bar",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: props.title,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    xAxis: {
      type: "category",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              sellerOffersInformation(
                // @ts-ignore
                this.iduser,
                // @ts-ignore
                this.status_offer,
                // @ts-ignore
                this.name
              );
            },
          },
        },
      },
    },
    series: [{ name: "€ Total", data: totalselleroffer }],
    loading: false,
  };

  const ChartPieoptions = {
    colors: [
      "#FF0000",
      "#FF4000",
      "#FF8000",
      "#FFBF00",
      "#FFFF00",
      "#BFFF00",
      "#80FF00",
      "#00FF00",
      "#00FF80",
      "#00FFFF",
      "#0080FF",
      "#0000FF",
      "#4000FF",
      "#8000FF",
      "#BF00FF",
      "#FF00FF",
      "#FF40FF",
      "#FF80FF",
      "#FFC0CB",
      "#FF8C69",
      "#FF6EC7",
      "#8B0000",
      "#8B4513",
      "#808080",
    ],
    chart: {
      type: "pie",
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: sellerName,
    },
    subtitle: {
      // @ts-ignore
      text: `TOTAL: ${totalY?.total_y}`,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">Total: </td>' +
        '<td style="padding:0"><b> {point.y:,.0f}</b></td></tr>',
      footerFormat: "</table>",
      shared: false,
      useHTML: true,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: <b>{point.percentage:.1f} %</b>",
          useHTML: true,
        },
        showInLegend: true,
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function () {
              // @ts-ignore
              sellerOffersInformationDetails(this.name);
            },
          },
        },
      },
    },
    series: [{ data: pieData }],
    loading: false,
  };

  return (
    <>
      <div>
        {loading ? (
          <Spinner color="primary" /> // Componente de carga
        ) : (
          <>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <Modal size="xl" isOpen={isModalOpen} toggle={toggleModal}>
              <ModalHeader toggle={toggleModal}></ModalHeader>
              <ModalBody>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={ChartPieoptions}
                />
              </ModalBody>
            </Modal>

            <Modal
              size="xl"
              isOpen={isModalOpenDetalle}
              toggle={toggleModalDetalle}
            >
              <ModalHeader toggle={toggleModalDetalle}></ModalHeader>
              <ModalBody>
                <OffersDetailsList
                  offers={offers}
                  sellerName={sellerName}
                  showCustomer={props.showCustomer}
                />
              </ModalBody>
            </Modal>
          </>
        )}
      </div>
    </>
  );
};

export default OffersSellerChart;
