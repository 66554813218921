import React from "react";
import { Grid } from "@material-ui/core";
import TouchApp from "@material-ui/icons/TouchApp";
import MaterialTable from "material-table";

const CustomerList = (props: any) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.customers ? (
            <MaterialTable
              columns={[
                { title: "ID", field: "id", width: "2%" },
                {
                  title: "Cliente",
                  field: "name",
                  width: 20,
                },
                {
                  title: "Tipo",
                  field: "customertypename",
                  width: 20,
                },
                {
                  title: "NMC Codigo",
                  field: "nmc_code",
                  width: 20,
                },
                { title: "Dirección", field: "address", width: "80%" },
              ]}
              options={{
                toolbar: true,
                exportButton: false,
                selection: false,
                search: true,
                paging: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  background: "#F5F5F5",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.customers}
              title="Selecciona Cliente"
              actions={[
                {
                  icon: () => <TouchApp />,
                  tooltip: "Seleccionar",
                  onClick: (event, rowData) => props.edit(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerList;
