import React, { useState } from "react";

interface ChatInputProps {
  onSendMessage: (message: string) => void;
}

const AssistantChatInput: React.FC<ChatInputProps> = ({ onSendMessage }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage(""); // Limpiar el campo de entrada después de enviar
    }
  };

  return (
    <form onSubmit={handleSubmit} className="chat-input-form">
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Escribe un mensaje..."
        className="chat-input"
      />
      <button type="submit" className="send-button">
        Enviar
      </button>
    </form>
  );
};

export default AssistantChatInput;
