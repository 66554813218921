import React from "react";
import { Card, CardBody, CardText, Col, Row } from "reactstrap";

const ProductHeader = (props: any) => {
  return (
    <div>
      <Card>
        <CardBody>
          <CardText>
            <Row>
              <Col xs="12">
                <strong style={{ fontSize: "1.8em", color: "#5D6D7E" }}>
                  {props.datos.description}
                </strong>
                <Col>
                  <span style={{ color: "#27AE60" }}>€ {props.total}</span>
                </Col>
              </Col>
            </Row>
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductHeader;
