import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import mapModule from "highcharts/modules/map";
import mapDataSpain from "./spain_regions.json"; // Asumiendo que tienes los datos en un archivo JSON

// Inicializar el módulo de mapa
mapModule(Highcharts);

const MapRegion = (props: any) => {
  const [mapOptions, setMapOptions] = useState({
    chart: {
      map: "countries/es/es-all",
    },
    title: {
      text: "Ventas por Zona Comercial España",
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        alignTo: "spacingBox",
        x: 10,
      },
    },
    colorAxis: {
      min: 0,
      minColor: "#EAECEE", // Rojo claro
      maxColor: "#1B2631", // Rojo oscuro
    },
    legend: {
      layout: "vertical",
      align: "left",
      verticalAlign: "bottom",
    },
    series: [
      {
        data: [],
        mapData: mapDataSpain.mapData,
        joinBy: "fips",
        name: "Venta Total",
        states: {
          hover: {
            color: "#F4D03F",
          },
        },
      },
    ],
  });

  useEffect(() => {
    let datosProcesados: any;

    if (typeof props.datos === "string") {
      try {
        datosProcesados = JSON.parse(props.datos);
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else if (Array.isArray(props.datos)) {
      datosProcesados = props.datos;
    } else {
      console.error("Formato de datos desconocido");
      return;
    }

    setMapOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          ...prevOptions.series[0],
          data: datosProcesados,
        },
      ],
    }));
  }, [props.datos]);
  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"mapChart"}
        options={mapOptions}
      />
    </div>
  );
};

export default MapRegion;
