import React from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

const SellerPerformance = (props: any) => {
  const { description, total_ventas, total_registros, porcentaje_ventas } =
    props.item;

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5" style={{ color: "#5D6D7E", fontSize: "1.0em" }}>
          {description}
        </CardTitle>
        <div style={{ fontSize: "0.9em", color: "#34495E" }}>
          <strong>Ventas:</strong>{" "}
          <span style={{ color: "#27AE60" }}>€ {total_ventas}</span>
        </div>
        <div style={{ fontSize: "0.9em", color: "#34495E" }}>
          <strong>Clientes:</strong>{" "}
          <span style={{ color: "#2980B9" }}>{total_registros}</span>
        </div>
        <div style={{ fontSize: "0.9em", color: "#34495E" }}>
          <strong>% Ventas:</strong>{" "}
          <span style={{ color: "#8E44AD" }}>
            {porcentaje_ventas.toFixed(2)}%
          </span>
        </div>
        <Progress value={porcentaje_ventas} color="warning" />
      </CardBody>
    </Card>
  );
};

export default SellerPerformance;
