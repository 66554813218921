import { Grid, Paper } from "@material-ui/core";
import React from "react";
import "./style.scss";
import FinalCustomersList from "./FinalCustomersList";
const FinalCustomers = (props: any) => {
  return (
    <div>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <FinalCustomersList
              offers={props.offers}
              offer_handler={props.offer_handler}
              showCustomer={props.showCustomer}
              delete_offer={props._delete_offer}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default FinalCustomers;
