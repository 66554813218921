import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Col,
  Input,
} from "reactstrap";
import "./style.scss";
import { useTranslation } from "react-i18next";

const EditNifModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);

  const [finaldata, setfinaldata] = useState(props.customer.nif);

  const finaldata_handler = (e: any) => {
    setfinaldata(e.target.value);
  };

  const EditData = () => {
    if (finaldata) {
      const item = {
        idcustomer: props.customer.id,
        id: finaldata,
      };
      props.editNIF(item);
    } else {
      alert("Debes Ingresar NIF");
    }
  };
  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_nif}>
        <ModalHeader>
          {t("CustomerDetail.Modal.EditNifModal.Title")}{" "}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                {t("CustomerDetail.Modal.EditNifModal.EditNIFLabel")}
              </Label>
              <Col sm={10}>
                <Input
                  id="finaladress"
                  name="current"
                  placeholder={
                    t(
                      "CustomerDetail.Modal.EditNifModal.EditNIFLabel"
                    ) as string
                  }
                  type="text"
                  value={props.customer.nif}
                  disabled
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                {t("CustomerDetail.Modal.EditNifModal.FinalNifLabel")}
              </Label>
              <Col sm={10}>
                <Input
                  id="new"
                  name="finaladress"
                  placeholder={
                    t(
                      "CustomerDetail.Modal.EditNifModal.FinalNifLabel"
                    ) as string
                  }
                  type="text"
                  onChange={finaldata_handler}
                  value={finaldata}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_nif}>
            {t("CustomerDetail.Modal.EditAdressModal.CancelButton")}
          </Button>
          <Button onClick={EditData} color="success">
            {t("CustomerDetail.Modal.EditAdressModal.EditButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditNifModal;
