import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Button,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import Map from "./components/shared/Map/Map";
import List from "./components/shared/List/List";
import NewCustomer from "./components/shared/NewCustomer/NewCustomer";
import { getCustomers, addCustomer } from "../services/customerService";
import { useHistory } from "react-router-dom";
export const Distribuidores = () => {
  const history = useHistory();
  const [customers, setcustomers] = useState([]);
  const [openNewCustomerModal, setopenNewCustomerModal] = useState(false);
  const toggle = () => {
    setopenNewCustomerModal(!openNewCustomerModal);
  };

  useEffect(() => {
    refreshCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddNewCustomer = (item: any) => {
    addCustomer(item).then((data) => {
      refreshCustomers();
    });
  };

  const refreshCustomers = () => {
    getCustomers(6).then((response) => {
      console.table(response?.data.customersResults);
      if (response?.data.ok) {
        setcustomers(response?.data.customersResults);
        setopenNewCustomerModal(false);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  const show = (data: any) => {
    window.open(`/layout/channel/${data.id}`);
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaCity className="me-2" />
            Distribuidores
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Distribuidores</BreadcrumbItem>
              <BreadcrumbItem>Lista</BreadcrumbItem>
            </Breadcrumb>
          </Col>

          <Col className="col-lg-2">
            <Col className="text-center text-md-right">
              <Button size="sm" block onClick={toggle}>
                Nuevo Distribuidor
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Georeferencia</CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              <Map customers={customers} show={show} />
              {customers ? <List customers={customers} show={show} /> : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
      {openNewCustomerModal ? (
        <NewCustomer
          isOpen={openNewCustomerModal}
          toggle={toggle}
          AddNewCustomer={AddNewCustomer}
          customertype={6}
        />
      ) : null}
    </div>
  );
};
export default Distribuidores;
