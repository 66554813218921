/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import {
  addColaborator,
  deletecolaborator,
  deleteCustomer,
  editAdressCustomer,
  editProjectData,
  getcolaborators,
  getCustomerById,
  getCustomerByStore,
} from "../../../../services/customerService";
import Header from "../../shared/Header/Header";
import HeaderMap from "./DetailArquitectMap";
import {
  getContacts,
  newContact,
  deleteContacts,
} from "../../../../services/contactsService";
import ContactList from "../../../../shared/components/ContactList";
import NewContactModal from "../../../../shared/components/NewContactModal";
import {
  deleteImage,
  getDocuments,
  deleteDocument,
  deleteCustomerImages,
  deleteCustomerFiles,
  deleteCustomerReportFiles,
} from "../../../../services/documentService";
import { getImages } from "../../../../services/imagesServices";
import { getReports, newReport } from "../../../../services/reportsService";
import DocumentList from "../../../../shared/components/DocumentList/DocumentList";
import NewDocumentModal from "../../../../shared/components/DocumentList/NewDocumentModal";
import NewImageModal from "../../../../shared/components/Gallery/NewImageModal";
import NewReportModal from "../../../../shared/components/NewReportModal";
import Reports from "../../../../shared/components/Reports";
import EditAdressModal from "../../shared/EditAdressModal/EditAdressModal";
import EditAgentModal from "../../Projects/ProjectDetail/Modals/EditAgentModal";
import Offers from "../../../../shared/components/Offers";
import {
  deleteOffer,
  getOffersInternalcode,
  updateEndDateOffer,
  updateStatusOffer,
} from "../../../../services/offersServices";
import OfferDetailsModal from "../../../../shared/components/OfferDetailsModal";
import ListProjects from "../../../../shared/components/CustomerList/ListProjects";
import {
  detalleventasporcliente,
  getBillingData,
  obtenerDatosDeVentasCustomer,
  obtenerVentasPorMesYAnioByCustomer,
  obtenerVentasPorMesYAnioCustomer,
  obtenerVentasPorProductoYAnioPorCliente,
} from "../../../../services/chartsService";
import BillingChart from "../../../../shared/components/charts/BillingChart";
import EditNifModal from "../../shared/EditNifModal/EditNifModal";
import EditICModal from "../../shared/EditICModal/EditICModal";
import EditAliasModal from "../../shared/EditAliasModal/EditAliasModal";
import { Button } from "@material-ui/core";
import EditCustomerType from "../../Projects/ProjectDetail/Modals/EditCustomerType";
import { useTranslation } from "react-i18next";
import MyImageGallery from "../../../../shared/components/Gallery/MyImageGallery";
import HeathMapChart from "../../../../shared/components/charts/HeathMapChart";
import SalesTrendChart from "../../../../shared/components/charts/SalesTrendChart";
import MonthlySalesComparisonChart from "../../../../shared/components/charts/MonthlySalesComparisonChart";
import ProductSalesByYearChart from "../../../../shared/components/charts/ProductSalesByYearChart";
import ProductSalesList from "../../../../shared/components/ProductSalesList";
import SalesDetailsList from "../../../../shared/components/SalesDetailsList";
import EditColaboratorModal from "../../Projects/ProjectDetail/Modals/EditColaboratorModal";

const DetailArquitect = (props: any) => {
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation(["home", "projects"]);

  const [customers, setcustomers] = useState([]);
  const [colaborators, setcolaborators] = useState([]);
  const [projects, setprojects] = useState([]);
  const [contacts, setcontacts] = useState([]);
  const [reports, setreports] = useState([]);
  const [images, setimages] = useState([]);
  const [documents, setdocument] = useState([]);
  const [openContactModal, setopenContactModal] = useState(false);
  const [openreport, setopenreport] = useState(false);
  const [openImageModal, setopenImageModal] = useState(false);
  const [openDocumentModal, setopenDocumentModal] = useState(false);
  const [openEditAdressModal, setopenEditAdressModal] = useState(false);
  const [openEditAgentModal, setEditAgentModal] = useState(false);
  const [openEditColaboratorModal, setEditColaboratorModal] = useState(false);

  const [openEditNifModal, setEditNifModal] = useState(false);
  const [openEditICfModal, setEditICModal] = useState(false);
  const [openEditAliasModal, setEditAliasModal] = useState(false);

  const [offers, setoffers] = useState([]);
  const [openOfferModal, setopenOfferModal] = useState(false);

  const [idoffer, setidoffer] = useState(null);
  const [offeritem, setofferitem] = useState([]);
  const [billingdata, setbillingdata] = useState([]);

  const [nmc_code, setnmc_code] = useState(null);

  const [customer_type_description, setcustomer_type_description] =
    useState(null);

  const [openCustomerTypes, setopenCustomerTypes] = useState(false);

  const [datos11, setdatos11] = useState([]);
  const [datos12, setdatos12] = useState([]);
  const [datos8, setdatos8] = useState([]);
  const [datos9, setdatos9] = useState([]);
  const [datos10, setdatos10] = useState([]);
  const [useGrayScale, setUseGrayScale] = useState(false);

  useEffect(() => {
    refresh();
  }, []);

  const colorSchemes = {
    original: {
      minColor: "#FFFFFF",
      maxColor: "#B03A2E",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#FFEDA0"],
        [1, "#B03A2E"],
      ],
    },
    grayScale: {
      minColor: "#FFFFFF",
      maxColor: "#000000",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#CCCCCC"],
        [1, "#000000"],
      ],
    },
  };

  const toggleColorScheme = () => {
    setUseGrayScale(!useGrayScale);
  };

  const refresh = () => {
    getCustomerById(id).then((response) => {
      if (response?.data.ok) {
        setcustomers(response?.data.customers);
        setnmc_code(response?.data.customers.nmc_code);
        getOfferstList(response?.data.customers.nmc_code);
        getcustomerProyects();
        getContactList();
        getReportsCustomer();
        getCustomerImages();
        getDocumentsCustomer();
        getBilling(response?.data.customers.nmc_code);
        getHeatMap(response?.data.customers.nmc_code);
        datatendency(response?.data.customers.nmc_code);
        getventasmesyaniocustomer(response?.data.customers.nmc_code);
        getProductSales(response?.data.customers.nmc_code);
        getsalesdetails(response?.data.customers.nmc_code);

        setcustomer_type_description(
          response?.data.customers.customer_type_description
        );
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  const toggle_colaborator = () => {
    setEditColaboratorModal(!openEditColaboratorModal);
  };

  const getHeatMap = (nmc: string) => {
    obtenerVentasPorMesYAnioByCustomer(nmc).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos12(response?.data);
      } else {
      }
    });
  };

  const datatendency = (nmc: string) => {
    obtenerDatosDeVentasCustomer(nmc).then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        console.table(response?.data);
        setdatos8(response?.data);
      } else {
      }
    });
  };

  const getventasmesyaniocustomer = (nmc: string) => {
    obtenerVentasPorMesYAnioCustomer(nmc).then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        setdatos9(response?.data);
      } else {
      }
    });
  };

  const getsalesdetails = (nmc: string) => {
    detalleventasporcliente(nmc).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos11(response?.data);
      } else {
      }
    });
  };

  const getProductSales = (nmc: string) => {
    obtenerVentasPorProductoYAnioPorCliente(nmc).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos10(response?.data);
      } else {
      }
    });
  };

  const getBilling = (id: string) => {
    getBillingData(id).then((response) => {
      if (response?.data) {
        console.table(response?.data);
        setbillingdata(response?.data);
      } else {
      }
    });
  };

  const _delete_offer = (item: any) => {
    const userid = localStorage.getItem("userid");
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres ELIMINAR la Oferta " + item.idoffer + " ?")) {
      if (
        userid === "1" ||
        userid === "27" ||
        userid === "26" ||
        userid === "5" ||
        userid === "4" ||
        userid === "25"
      ) {
        deleteOffer(item.idoffer).then((response) => {
          if (response?.data.ok) {
            refresh();
          }
        });
      } else {
        alert("No autorizado para Eliminar Ofertas");
      }
    }
  };

  const editType = (item: any) => {
    // alert(item.id);

    editProjectData(id, item, 105).then((response) => {
      if (response?.data.ok) {
        setopenCustomerTypes(false);
        refresh();
      }
    });
  };

  const toggle_customertype = () => {
    setopenCustomerTypes(!openCustomerTypes);
  };

  const toggle_nif = () => {
    setEditNifModal(!openEditNifModal);
  };

  const editNIF = (item: any) => {
    editProjectData(id, item, 101).then((response) => {
      if (response?.data.ok) {
        setEditAgentModal(false);
        refresh();
      }
    });
  };

  const toggle_internalcode = () => {
    setEditICModal(!openEditICfModal);
  };

  const editInternalCode = (item: any) => {
    editProjectData(id, item, 102).then((response) => {
      if (response?.data.ok) {
        setEditICModal(false);
        refresh();
      }
    });
  };

  const toggle_alias = () => {
    setEditAliasModal(!openEditAliasModal);
  };

  const editAlias = (item: any) => {
    editProjectData(id, item, 103).then((response) => {
      if (response?.data.ok) {
        setEditAliasModal(false);
        refresh();
      }
    });
  };

  const toggle_editadress = () => {
    setopenEditAdressModal(!openEditAdressModal);
  };

  const editAdress = (item: any) => {
    editAdressCustomer(item).then((response) => {
      if (response?.data.ok) {
        setopenEditAdressModal(false);
        refresh();
      }
    });
  };

  const editAgent = (item: any) => {
    editProjectData(id, item, 0).then((response) => {
      if (response?.data.ok) {
        setEditAgentModal(false);
        refresh();
      }
    });
  };

  const toggle_agente = () => {
    setEditAgentModal(!openEditAgentModal);
  };

  const getContactList = () => {
    getContacts(id).then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.contactResults);
        setcontacts(response?.data.contactResults);
      } else {
        setcontacts([]);
      }
    });
  };

  const toggle_contact = () => {
    setopenContactModal(!openContactModal);
  };

  const addNewContact = (item: any) => {
    newContact(item, id).then((response) => {
      if (response?.data.ok) {
        setopenContactModal(false);
        getContactList();
      } else {
        setopenContactModal(false);
      }
    });
  };

  const deleteContact = (item: any) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres Eliminar este Contacto")) {
      deleteContacts(item.id).then((response) => {
        if (response?.data.ok) {
          getContactList();
        }
      });
    }
  };

  const toggle_reports = () => {
    setopenreport(!openreport);
  };

  const toggle_images = () => {
    setopenImageModal(!openImageModal);
  };

  const toggle_document = () => {
    setopenDocumentModal(!openDocumentModal);
  };

  const getReportsCustomer = () => {
    getReports(id).then((response) => {
      if (response?.data.ok) {
        setreports(response?.data.reportResults);
      }
    });
  };
  const addNewReport = (item: any) => {
    newReport(item, id).then((response) => {
      if (response?.data.ok) {
        setopenreport(false);
        getReportsCustomer();
      }
    });
  };

  const addNewImage = () => {
    getCustomerImages();
  };

  const getCustomerImages = () => {
    getImages(id).then((response) => {
      if (response?.data.ok) {
        setimages(response?.data.imagesResults);
      }
    });
  };

  const deleteCustomerImage = (id: any) => {
    if (window.confirm(`Quieres Eliminar la Imagen de la Biblioteca?`)) {
      deleteImage(id).then((response) => {
        if (response) {
          getCustomerImages();
        }
      });
    }
  };

  const addNewDocument = () => {
    setopenDocumentModal(false);
    getDocumentsCustomer();
  };

  const getDocumentsCustomer = () => {
    getDocuments(id).then((response) => {
      if (response?.data.ok) {
        setdocument(response?.data.documentResults);
      }
    });
  };
  const deleteDocumentCustomer = (id: any, file_name: any) => {
    if (window.confirm(`Quieres Eliminar el documento? ${file_name}?`)) {
      deleteDocument(id).then((response) => {
        if (response) {
          getDocumentsCustomer();
        }
      });
    }
  };

  const getOfferstList = (idcode: any) => {
    getOffersInternalcode(idcode).then((response) => {
      console.table(response);
      if (response?.data.ok) {
        console.table(response?.data.offersResults);
        setoffers(response?.data.offersResults);
      } else {
        setoffers([]);
      }
    });
  };

  const offer_handler = (item: any) => {
    setidoffer(item.idoffer);
    setofferitem(item);
    setopenOfferModal(true);
  };

  const toggle_offer = () => {
    setopenOfferModal(!openOfferModal);
  };

  const updateStatus = (
    idstatus: number,
    idoffer: string,
    idanulation: any
  ) => {
    updateStatusOffer(idstatus, idoffer, idanulation).then((response) => {
      if (response?.data.ok) {
        getOfferstList(nmc_code);
        toggle_offer();
      }
    });
  };

  const show = (data: any) => {
    window.open(`/layout/customer/${data.id}`);
  };

  const getcustomerProyects = () => {
    getCustomerByStore(4, id).then((response) => {
      console.table(response);
      if (response?.data.ok) {
        setprojects(response?.data.customersResults);
      } else {
      }
    });
  };

  const updateEndDate = (end_date: any, idoffer: string) => {
    updateEndDateOffer(end_date, idoffer).then((response) => {
      if (response?.data.ok) {
        getOfferstList(nmc_code);
        toggle_offer();
      }
    });
  };

  const showCustomer = (data: any) => {
    if (data.type === 1) {
      window.open(`/layout/customer/${data.customerid}`);
    }
    if (data.type === 2) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 3) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 4) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 5) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 6) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 7) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 8) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 9) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 10) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 11) {
      window.open(`/layout/channel/${data.customerid}`);
    }
  };

  const deletePoint = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres ELIMINAR este Proyecto?")) {
      const userid = localStorage.getItem("userid");
      if (userid === "1" || userid === "27" || userid === "26") {
        deleteCustomerImages(id).then((response) => {
          deleteCustomerFiles(id).then((response2) => {
            deleteCustomerReportFiles(id).then((response3) => {
              deleteCustomer(id).then((response) => {
                if (response?.data.ok) {
                  window.close();
                }
              });
            });
          });
        });
      } else {
        alert("No autorizado para Eliminar");
      }
    }
  };

  const callCustomerColaborators = () => {
    getcolaborators(id).then((response) => {
      if (response?.data.ok) {
        setcolaborators(response?.data.colabotatorsResult);
        //refresh();
      }
    });
  };

  const editColaborator = (item: any) => {
    addColaborator(id, item.id).then((response) => {
      if (response?.data.ok) {
        callCustomerColaborators();
        setEditColaboratorModal(false);
      } else {
        alert(response?.data.message);
      }
    });
  };

  const handleDeleteColaborator = (userId: number) => {
    // Llamar a la API para eliminar la categoría
    deletecolaborator(id, userId).then((response) => {
      if (response?.data.ok) {
        callCustomerColaborators();
      } else {
        alert(response?.data.message);
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaSplotch className="me-2" />
            <div className="headerButtonCustomerName">
              <Button
                size="small"
                variant="contained"
                onClick={toggle_customertype}
              >
                {customer_type_description}
              </Button>
            </div>
            <div className="Button">
              {" "}
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={deletePoint}
              >
                {t("SharedComponents.DeleteButtom")}
              </button>
            </div>
          </h4>
        </div>

        <Breadcrumb listTag="div">
          <BreadcrumbItem>{customer_type_description}</BreadcrumbItem>
          <BreadcrumbItem>Detalle</BreadcrumbItem>
        </Breadcrumb>
      </div>

      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <Row xs="2">
            <Col>
              <Header
                customer={customers}
                toggle_editadress={toggle_editadress}
                toggle_agente={toggle_agente}
                toggle_nif={toggle_nif}
                toggle_internalcode={toggle_internalcode}
                toggle_alias={toggle_alias}
                toggle_colaborator={toggle_colaborator}
                colaborators={colaborators}
                handleDeleteColaborator={handleDeleteColaborator}
              />
            </Col>
            <Col>
              <HeaderMap customer={customers} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="content-page">
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>Facturación Mensual</CardHeader>
              <CardBody>
                {billingdata ? (
                  <BillingChart
                    customers={customers}
                    billingdata={billingdata}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Mapa de Calor
                <div className="pull-right">
                  {" "}
                  {/* Alinea el botón a la derecha */}
                  <Button onClick={toggleColorScheme}>
                    {" "}
                    {/* Botón pequeño */}
                    {useGrayScale
                      ? "Usar colores originales"
                      : "Cambiar a escala de grises"}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {datos12 ? (
                  <HeathMapChart
                    data={datos12}
                    colorScheme={
                      useGrayScale
                        ? colorSchemes.grayScale
                        : colorSchemes.original
                    }
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>
                Análisis de Tendencias Temporales de las ventas{" "}
              </CardHeader>
              <CardBody>
                {datos8 ? <SalesTrendChart datos={datos8} /> : null}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>Comparación de Ventas Mensuales por Año </CardHeader>
              <CardBody>
                {datos9 ? <MonthlySalesComparisonChart datos={datos9} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>Ventas por Producto y Año</CardHeader>
              <CardBody>
                {datos10 ? <ProductSalesByYearChart datos={datos10} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />

        <Row>
          <Col>
            <Card>
              <CardHeader>
                Imagenes
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_images}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                {images ? (
                  <MyImageGallery
                    images={images}
                    deleteCustomerImage={deleteCustomerImage}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Documentos
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_document}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                {documents ? (
                  <DocumentList
                    documents={documents}
                    deleteDocumentCustomer={deleteDocumentCustomer}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Ofertas
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_document}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {offers ? (
                  <Offers
                    offers={offers}
                    offer_handler={offer_handler}
                    showCustomer={showCustomer}
                    _delete_offer={_delete_offer}
                  />
                ) : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>Proyectos</CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {projects ? (
                  <ListProjects customers={projects} show={show} />
                ) : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>Facturación</CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {datos11 ? <SalesDetailsList datos={datos11} /> : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>Productos Vendidos</CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {datos10 ? <ProductSalesList datos={datos10} /> : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />

        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>
                Contactos
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_contact}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>

                <CardText>
                  {contacts ? (
                    <ContactList
                      contacts={contacts}
                      deleteContact={deleteContact}
                    />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                Actividades
                <div className="Button">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={toggle_reports}
                  >
                    Nuevo
                  </button>
                </div>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                {reports ? <Reports reports={reports} /> : null}
                <CardText></CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      {openContactModal ? (
        <NewContactModal
          isOpen={openContactModal}
          toggle_contact={toggle_contact}
          addNewContact={addNewContact}
        />
      ) : null}
      {openreport ? (
        <NewReportModal
          isOpen={openreport}
          toggle_reports={toggle_reports}
          addNewReport={addNewReport}
        />
      ) : null}
      {openImageModal ? (
        <NewImageModal
          isOpen={openImageModal}
          toggle_images={toggle_images}
          addNewImage={addNewImage}
          customer_id={id}
        />
      ) : null}

      {openDocumentModal ? (
        <NewDocumentModal
          isOpen={openDocumentModal}
          toggle_images={toggle_document}
          addNewDocument={addNewDocument}
          customer_id={id}
        />
      ) : null}
      {openEditAdressModal ? (
        <EditAdressModal
          isOpen={openEditAdressModal}
          toggle_editadress={toggle_editadress}
          editAdress={editAdress}
          customer_id={id}
          customer={customers}
        />
      ) : null}
      {openEditNifModal ? (
        <EditNifModal
          isOpen={openEditNifModal}
          toggle_nif={toggle_nif}
          editNIF={editNIF}
          customer_id={id}
          customer={customers}
        />
      ) : null}
      {openEditICfModal ? (
        <EditICModal
          isOpen={openEditICfModal}
          toggle_internalcode={toggle_internalcode}
          editInternalCode={editInternalCode}
          customer_id={id}
          customer={customers}
        />
      ) : null}
      {openEditAliasModal ? (
        <EditAliasModal
          isOpen={openEditAliasModal}
          toggle_alias={toggle_alias}
          editAlias={editAlias}
          customer_id={id}
          customer={customers}
        />
      ) : null}
      {openEditAgentModal ? (
        <EditAgentModal
          isOpen={openEditAgentModal}
          toggle_agente={toggle_agente}
          editAgent={editAgent}
        />
      ) : null}

      {openCustomerTypes ? (
        <EditCustomerType
          isOpen={openCustomerTypes}
          toggle_customertype={toggle_customertype}
          editType={editType}
        />
      ) : null}
      {openOfferModal ? (
        <OfferDetailsModal
          isOpen={openOfferModal}
          toggle_offer={toggle_offer}
          editAdress={editAdress}
          idoffer={idoffer}
          offeritem={offeritem}
          updateStatus={updateStatus}
          updateEndDate={updateEndDate}
        />
      ) : null}
      {openEditColaboratorModal ? (
        <EditColaboratorModal
          isOpen={openEditColaboratorModal}
          toggle_colaborator={toggle_colaborator}
          editColaborator={editColaborator}
        />
      ) : null}
    </div>
  );
};

export default DetailArquitect;
