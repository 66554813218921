import React, { useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "react-leaflet-fullscreen/dist/styles.css";
import "./MapProject.scss";
import CustomerMapPopUp from "./CustomerMapPopUp";

const colorMap: { [key: string]: string } = {
  "#C70138":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  "#229954":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png",
  "#FFC300":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-yellow.png",
  "#9104FA":
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-violet.png",
};

const createIcon = (color: string) => {
  return L.icon({
    iconUrl: colorMap[color] || colorMap["#C70138"], // Usa rojo por defecto si no coincide
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41],
  });
};
const MapLegend2 = () => {
  return (
    <div className="map-legend-canal">
      <h4>Ultima Gestión</h4>
      <ul>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#C70138" }}
          ></span>
          Mayor a 6 Meses o sin Actividad
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#FFC300" }}
          ></span>
          de 31 Días a 6 Meses
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#229954" }}
          ></span>
          de 0 a 30 Días
        </li>
        <li>
          <span
            className="legend-icon-canal"
            style={{ backgroundColor: "#9104FA" }}
          ></span>
          Gestión Programada
        </li>
      </ul>
    </div>
  );
};
const MapProject = (props: any) => {
  const [position] = useState({
    lat: 39.707186656826565,
    lng: -4.746093750000001,
  });
  return (
    <div>
      {props.customers ? (
        <MapContainer className="mapDetails" center={position} zoom={6}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {props.customers.map(
            (items: {
              latitud: any;
              longitud: any;
              id: any;
              name: any;
              description: any;
              address: any;
              country: any;
              CustomerColorReport: string;
              comercial: string;
              nextVisit: any;
              diffDays: any;
            }) => (
              <Marker
                position={[items.latitud, items.longitud]}
                // eslint-disable-next-line no-eval
                icon={createIcon(items.CustomerColorReport)}
                key={items.id}
                draggable={false}
              >
                <Popup>
                  <CustomerMapPopUp
                    show={props.show}
                    name={items.name}
                    description={items.description}
                    id={items.id}
                    address={items.address}
                    country={items.country}
                    CustomerColorReport={items.CustomerColorReport}
                    comercial={items.comercial}
                    nextVisit={items.nextVisit}
                    diffDays={items.diffDays}
                  />
                </Popup>
              </Marker>
            )
          )}
          <MapLegend2 />
        </MapContainer>
      ) : (
        <div>Cargando...</div>
      )}
    </div>
  );
};

export default MapProject;
