/* eslint-disable react-hooks/exhaustive-deps */
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import TreemapModule from "highcharts/modules/treemap";
import { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

TreemapModule(Highcharts);

type ChartOptions = {
  title: string;
  credits: { enabled: boolean };
  series: {
    type: string;
    layoutAlgorithm: string;
    data: {
      name: string;
      value: number;
      color?: string;
    }[];
  }[];
};

const VentasProductosTreemap = (props: any) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    title: "",
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        data: [],
      },
    ],
  });
  const [colorMode, setColorMode] = useState("original"); // 'original' o 'grayscale'
  useEffect(() => {
    let datosConvertidos;

    if (typeof props.datos === "string") {
      try {
        datosConvertidos = JSON.parse(props.datos);
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else if (Array.isArray(props.datos)) {
      datosConvertidos = props.datos;
    } else {
      console.error("Formato de datos desconocido");
      return;
    }

    if (Array.isArray(datosConvertidos)) {
      const datosTreemap = datosConvertidos.map((item) => {
        let color;
        // Asigna el color basado en el valor de las ventas
        if (colorMode === "original") {
          // Colores originales
          if (item.totalVentas < 1000) color = "#F9E79F";
          else if (item.totalVentas < 5000) color = "#F8C471";
          else if (item.totalVentas < 20000) color = "#EB984E";
          else if (item.totalVentas < 50000) color = "#DC7633";
          else if (item.totalVentas < 100000) color = "#D35400";
          else color = "#A04000";
        } else {
          // Escala de grises proporcional
          if (item.totalVentas < 1000) color = "#eeeeee";
          else if (item.totalVentas < 5000) color = "#dcdcdc";
          else if (item.totalVentas < 20000) color = "#bbbbbb";
          else if (item.totalVentas < 50000) color = "#999999";
          else if (item.totalVentas < 100000) color = "#777777";
          else color = "#555555";
        }
        return {
          name: item.nombreProducto,
          value: item.totalVentas,
          color,
        };
      });

      setChartOptions({
        ...chartOptions,
        series: [{ ...chartOptions.series[0], data: datosTreemap }],
      });
    }
  }, [props.datos, colorMode]);

  const toggleColorMode = () => {
    setColorMode((prevMode) =>
      prevMode === "original" ? "grayscale" : "original"
    );
  };

  return (
    <div>
      <Button onClick={toggleColorMode}>Cambiar Modo de Color</Button>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default VentasProductosTreemap;
