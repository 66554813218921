import React from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";

const SalesDetailsMap = (props: any) => {
  let datosProcesados: any;
  let errorMessage: string | null = null;

  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      errorMessage = "Error al analizar JSON";
    }
  } else if (Array.isArray(props.datos)) {
    datosProcesados = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    errorMessage = "Formato de datos desconocido";
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {datosProcesados ? (
            <MaterialTable
              columns={[
                {
                  title: "Cliente",
                  field: "customername",
                  width: 20,
                  headerStyle: {
                    background: "#EE952A ",
                  },
                },
                {
                  title: "Mes",
                  field: "mes",
                  width: 20,
                  headerStyle: {
                    background: "#EE952A ",
                  },
                },
                {
                  title: "Año",
                  field: "anio",
                  width: 20,
                  headerStyle: {
                    background: "#EE952A ",
                  },
                },

                {
                  title: "Total",
                  field: "totalVentas",
                  width: 20,
                  headerStyle: {
                    background: "#EE952A ",
                  },
                },
              ]}
              options={{
                toolbar: true,
                exportButton: false,
                selection: false,
                search: true,
                paging: true,
                pageSize: 20,
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),

                filtering: false,
              }}
              data={datosProcesados}
              title="Facturación Mensual"
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "¿Estás seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Guardar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Página previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesDetailsMap;
