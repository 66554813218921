import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
} from "reactstrap";

const NewReportModal = (props: any) => {
  const [report, setreport] = useState("");
  const report_handle = (e: any) => {
    setreport(e.target.value);
  };
  const NewReport = () => {
    if (report) {
      const item = {
        report: report,
      };
      props.addNewReport(item);
    } else {
      alert("Ingresa el Reporte");
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_reports}>
        <ModalHeader>Nuevo Reporte</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Input
                type="textarea"
                placeholder="Ingresa Reporte"
                defaultValue=""
                rows="5"
                onChange={report_handle}
              />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_reports}>CANCELAR</Button>
          <Button onClick={NewReport} color="success">
            INGRESAR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewReportModal;
