import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface DataPoint {
  name: string;
  y: number;
}

interface SalesData {
  sales2022: DataPoint[];
  sales2023: DataPoint[];
}

interface Props {
  datos: any;
}

const VentasCategorias: React.FC<Props> = (props: any) => {
  const { datos } = props;
  let sales2022: DataPoint[] = [];
  let sales2023: DataPoint[] = [];

  try {
    const salesData: SalesData = JSON.parse(datos);
    sales2022 = salesData.sales2022;
    sales2023 = salesData.sales2023;
  } catch (error) {
    console.error("Error al parsear los datos JSON:", error);
    return null; // O manejar el error de alguna otra manera apropiada para tu caso
  }

  const categories = sales2023.map((dataPoint: DataPoint) => dataPoint.name);

  const options: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Ventas por Canales",
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "Ventas",
      },
    },
    tooltip: {
      pointFormat: "<b>{point.name}</b>: {point.y:.0f}",
    },
    series: [
      {
        type: "bar",
        name: "2023",
        data: sales2022.map((dataPoint: DataPoint) => ({
          x: categories.indexOf(dataPoint.name),
          y: Math.round(dataPoint.y), // Redondear sin decimales
        })),
        dataLabels: {
          enabled: true, // habilitar las etiquetas de datos
        },
      },
      {
        type: "bar",
        name: "2024",
        data: sales2023.map((dataPoint: DataPoint) => ({
          x: categories.indexOf(dataPoint.name),
          y: Math.round(dataPoint.y), // Redondear sin decimales
        })),
        dataLabels: {
          enabled: true, // habilitar las etiquetas de datos
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default VentasCategorias;
