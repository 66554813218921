import { Grid, Paper } from "@material-ui/core";
import React from "react";
import ReportsList from "./ReportsList";
import "./style.scss";
const Reports = (props: any) => {
  return (
    <div>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <ReportsList reports={props.reports} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
