import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext"; // Importa el AuthProvider
import ProtectedRoute from "./ProtectedRoute";
import Layout from "./components/Layout";
import Login2 from "./pages/Login2";

const App = () => {
  return (
    <AuthProvider>
      {" "}
      {/* Envuelve tus componentes en AuthProvider */}
      <Router>
        <Switch>
          <Route exact path="/login" component={Login2} />
          <ProtectedRoute path="/layout" component={Layout} />
          <Route component={Login2} />
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default App;
