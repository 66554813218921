import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getUsers } from "../../../../../services/userService";
import AgentsList from "./Lists/AgentsList";
import { useTranslation } from "react-i18next";

const EditAgentModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [users, setusers] = useState([]);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getUsers().then((response) => {
      console.table(response?.data.usersResults);
      if (response?.data.ok) {
        setusers(response?.data.usersResults);
      } else {
      }
    });
  };
  const edit = (item: any) => {
    props.editAgent(item);
  };

  return (
    <>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_agente}>
        <ModalHeader>{t("CustomerDetail.Modal.SellerModal.Title")}</ModalHeader>
        <ModalBody>
          {users ? <AgentsList users={users} edit={edit} /> : null}
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_agente}>
            {" "}
            {t("CustomerDetail.Modal.SellerModal.List.CancelButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditAgentModal;
