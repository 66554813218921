import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Col,
  Row,
} from "reactstrap";

import * as FaIcons from "react-icons/fa";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SalesWindRoseChart from "../shared/components/charts/SalesWindRoseChart";
import {
  calcularRendimientoVendedor,
  getsellers,
  obtenerDatosDeVentasComercial,
  obtenerDatosParaGraficoRosaDeLosVientos,
  obtenerRelacionesProyectoCliente,
  obtenerVentasPorMesYAnioByComercial,
  obtenerVentasPorMesYAnioComercial,
  obtenerVentasPorProductoYAnioPorComercial,
} from "../services/chartsService";
import SellersList from "../shared/components/SellersList";
import SellerHeader from "../shared/components/SellerHeader";
import SalesTrendChart from "../shared/components/charts/SalesTrendChart";
import MonthlySalesComparisonChart from "../shared/components/charts/MonthlySalesComparisonChart";
import HeathMapChart from "../shared/components/charts/HeathMapChart";
import ProductSalesByYearChart from "../shared/components/charts/ProductSalesByYearChart";
import SellerPerformance from "../shared/components/Sellerperformance";
import VentasProductosTreemap from "../shared/components/charts/VentasProductosTreemap";
import NetworkGraphProjectComercial from "../shared/components/charts/NetworkGraphProjectComercials";
const Sellers = () => {
  const { t } = useTranslation(["home", "projects"]);

  const [datos8, setdatos8] = useState([]);
  const [datos9, setdatos9] = useState([]);
  const [datos15, setdatos15] = useState([]);
  const [datos16, setdatos16] = useState([]);
  const [datos, setdatos] = useState([]);
  const [datos11, setdatos11] = useState([]);
  const [datos12, setdatos12] = useState([]);
  const [datos10, setdatos10] = useState([]);

  const [datos17, setdatos17] = useState([]);

  const [useGrayScale, setUseGrayScale] = useState(false);

  const [chartsyear, setchartsyear] = useState(new Date().getFullYear());

  const [datarelations, setDataRelations] = useState([]);

  const years = Array.from(
    new Array(new Date().getFullYear() - 2021),
    (val, index) => 2022 + index
  );

  const colorSchemes = {
    original: {
      minColor: "#FFFFFF",
      maxColor: "#B03A2E",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#FFEDA0"],
        [1, "#B03A2E"],
      ],
    },
    grayScale: {
      minColor: "#FFFFFF",
      maxColor: "#000000",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#CCCCCC"],
        [1, "#000000"],
      ],
    },
  };

  const toggleColorScheme = () => {
    setUseGrayScale(!useGrayScale);
  };

  useEffect(() => {
    refreshCustomers();
    getsellers(chartsyear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos]);

  const refreshCustomers = () => {
    obtenerDatosParaGraficoRosaDeLosVientos(chartsyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos15(response?.data);
      } else {
      }
    });

    getsellers(chartsyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos16(response?.data);
      } else {
      }
    });

    obtenerRelacionesProyectoCliente().then((response) => {
      if (response?.data) {
        setDataRelations(response?.data);
      } else {
      }
    });
  };

  const offer_handler = (data: any) => {
    setdatos(data);
    loadCharts(data);
  };

  const loadCharts = (data: any) => {
    obtenerVentasPorMesYAnioByComercial(data.id).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos12(response?.data);
      } else {
      }
    });
    obtenerDatosDeVentasComercial(data.id).then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        console.table(response?.data);
        setdatos8(response?.data);
      } else {
      }
    });
    obtenerVentasPorMesYAnioComercial(data.id).then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        setdatos9(response?.data);
      } else {
      }
    });

    obtenerVentasPorProductoYAnioPorComercial(data.id, 20, chartsyear).then(
      (response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos10(response?.data);
        } else {
        }
      }
    );

    obtenerVentasPorProductoYAnioPorComercial(data.id, 1000, chartsyear).then(
      (response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos17(response?.data);
        } else {
        }
      }
    );

    calcularRendimientoVendedor(data.id, chartsyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos11(response?.data);
      } else {
      }
    });
  };

  const chartsyearschange = (e: any) => {
    setchartsyear(e.target.value);

    obtenerDatosParaGraficoRosaDeLosVientos(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos15(response?.data);
      } else {
      }
    });

    getsellers(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos16(response?.data);
      } else {
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            {t("seller.projectsTitle")}
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-8">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>{t("seller.projectsBreadcrum")}</BreadcrumbItem>
              <BreadcrumbItem>{t("seller.listBreadcrum")}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <select
                  id="1"
                  value={chartsyear}
                  onChange={chartsyearschange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                <CardText>
                  {" "}
                  {datos15 ? <SalesWindRoseChart datos={datos15} /> : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  {datarelations ? (
                    <NetworkGraphProjectComercial data={datarelations} />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="2">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  {" "}
                  {datos16 ? (
                    <SellersList
                      datos={datos16}
                      offer_handler={offer_handler}
                    />
                  ) : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs="10">
            <Card>
              <CardHeader></CardHeader>
              <CardBody>
                <CardText>
                  <Row>
                    <Col xs="4">
                      <SellerHeader datos={datos} />
                    </Col>
                    <Col xs="4"></Col>
                  </Row>
                  <br></br>
                  <Row>
                    {datos11 &&
                      datos11.map((item, index) => (
                        <Col key={index}>
                          <SellerPerformance item={item} />
                        </Col>
                      ))}
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col>
                      <Card>
                        {datos8 ? <SalesTrendChart datos={datos8} /> : null}
                      </Card>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col>
                      <Card>
                        <div className="pull-right">
                          {" "}
                          {/* Alinea el botón a la derecha */}
                          <Button onClick={toggleColorScheme}>
                            {" "}
                            {/* Botón pequeño */}
                            {useGrayScale
                              ? "CAMBIAR MODO DE COLOR"
                              : "CAMBIAR MODO DE COLOR"}
                          </Button>
                        </div>
                        {datos12 ? (
                          <HeathMapChart
                            data={datos12}
                            colorScheme={
                              useGrayScale
                                ? colorSchemes.grayScale
                                : colorSchemes.original
                            }
                          />
                        ) : null}
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        {datos9 ? (
                          <MonthlySalesComparisonChart datos={datos9} />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col>
                      <Card>
                        {datos10 ? (
                          <ProductSalesByYearChart datos={datos10} />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                  <br></br>
                  <Row>
                    <Col>
                      <Card>
                        {datos17 ? (
                          <VentasProductosTreemap datos={datos17} />
                        ) : null}
                      </Card>
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Sellers;
