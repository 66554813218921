import React from "react";

const ReportsList = (props: any) => {
  interface report {
    id: number;
    multimedia_id: number;
    description: string;
    fullname: string;
    createdAt: string;
    alias: string;
    name: string;
    src: string;
  }

  const messageType = (type: number, description: string, src: string) => {
    if (type === 1) {
      return <p className="m-b-xs">{description}</p>;
    } else {
      if (type === 2) {
        return (
          <>
            <br />
            <audio controls>
              <source src={src} type="audio/mpeg" />
            </audio>
            <br />
          </>
        );
      } else {
        return (
          <>
            <br />
            <div>
              <img
                alt="user"
                src={src}
                style={{ width: "200px", height: "auto" }}
              />
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      {props.reports.map(
        ({
          id,
          description,
          fullname,
          createdAt,
          name,
          multimedia_id,
          src,
        }: report) => (
          <div className="ibox-content" key={id}>
            <div className="chat-element">
              <div className="pull-left">
                <img
                  alt="user"
                  className="img-circle"
                  src="https://sug.buselcorp.com/ios/sug3/logo.png"
                />
              </div>
              <div className="media-body">
                <small className="pull-right text-navy"></small>
                <strong className="usernamecolor">{fullname}</strong>
                <strong className="ng-binding"></strong>
                <br />
                <strong className="proyectocolor">{name}</strong>
                {messageType(multimedia_id, description, src)}

                {/* <p className="m-b-xs">{description}</p> */}
                <small className="pull-right text-navy">{createdAt}</small>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ReportsList;
