import axios from "axios";
import config from "./confserver.json";


export const getAssistants = async () => {
  const data = {
    token: localStorage.getItem("token")

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getassistants', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const getAssistantChat = async (id:string) => {
  const data = {
      token: localStorage.getItem("token"),
      assistant_id: id

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/getassistantchat', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const sendMessageToAssistant = async (id:string,message:string) => {
  const data = {
      token: localStorage.getItem("token"),
      assistant_id: id,
      query:message

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/assistant', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}