import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getPhases } from "../../../../../services/customerService";
import PhasesList from "./Lists/PhasesList";
import { useTranslation } from "react-i18next";

const EditPhaseProjectModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [customers, setcustomers] = useState([]);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    getPhases().then((response) => {
      console.table(response?.data.phasedata);

      console.table(response?.data.phasedata);
      if (response?.data.ok) {
        setcustomers(response?.data.phasedata);
      } else {
      }
    });
  };
  const edit = (item: any) => {
    props.editPhase(item);
  };
  return (
    <>
      <Modal size="xs" isOpen={props.isOpen} toggle={props.toggle_phase}>
        <ModalHeader>
          {t("CustomerDetail.Modal.EditPhaseProjectModal.Title")}
        </ModalHeader>
        <ModalBody>
          {customers ? <PhasesList customers={customers} edit={edit} /> : null}
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_phase}>
            {t(
              "CustomerDetail.Modal.EditPhaseProjectModal.PhasesList.CancelButton"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditPhaseProjectModal;
