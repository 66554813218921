import React from "react";

import { utils, writeFile } from "xlsx";
import { Button, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { useRef } from "react";

const OffersListEntregada = (props: any) => {
   const tableRef = useRef<any>(null);
  const exportToExcel = (data: unknown[], fileName: string) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleExportExcel = () => {
   const filteredData = tableRef.current?.dataManager?.filteredData || [];
   console.log("Filtered Data:", filteredData);

   const exportData = filteredData.flatMap((offer: any) => {
     const baseOfferInfo = {
       id: offer.customerid,
       oferta: offer.idoffer,
       codigo_interno: offer.reference,
       cliente: offer.reference_name,
       nombre: offer.name,
       comercial: offer.fullname,
       fecha_oferta: offer.date_offer,
       vencimiento: offer.end_date,
       estado: offer.status_offer_description,
       total: offer.total,
     };

     const comments = offer.reports
       .map((report: any, index: number) => ({
         [`comentario${index + 1}`]: report.description,
       }))
       .reduce((acc: any, cur: any) => ({ ...acc, ...cur }), {});

     return offer.productsDetails.map((product: any) => ({
       ...baseOfferInfo,
       ...comments, // Añadir comentarios a la exportación
       id_producto: product.id_product,
       descripcion_producto: product.productDescription,
       um: product.um,
       precio: product.price,
       cantidad: product.quantity,
       descuento: product.discount,
       iva: product.iva,
       total_producto: product.total,
     }));
   });

    const fileName = "OFERTAS_ENTREGADAS";

    exportToExcel(exportData, fileName);
  };
  return (
    <div>
      {props.offers.length > 0 && (
        <div>
          <Button className="custom-export-button" onClick={handleExportExcel}>
            Exportar a Excel
          </Button>
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.offers ? (
            <MaterialTable
              tableRef={tableRef}
              columns={[
                {
                  title: "ID",
                  field: "customerid",
                  render: (rowData) => (
                    <Button onClick={() => props.showCustomer(rowData)}>
                      {rowData.customerid}
                    </Button>
                  ),
                },
                { title: "ID", field: "customerid", width: "2%", hidden: true },
                { title: "OFERTA", field: "idoffer", width: "2%" },
                { title: "CLIENTE", field: "reference_name", width: "2%" },
                { title: "NOMBRE", field: "name", width: "2%" },

                {
                  title: "TIPO CLIENTE",
                  field: "customertypename",
                  width: "2%",
                },

                { title: "COMERCIAL", field: "fullname", width: "2%" },
                { title: "FECHA OFERTA", field: "date_offer", width: "2%" },
                { title: "ACTUALIZACION", field: "updatedAt", width: "2%" },
                {
                  title: "ESTADO",
                  field: "status_offer_description",
                  width: "2%",
                },
                { title: "TOTAL", field: "total", width: "2%" },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: true,
                paging: false,
                pageSize: 15,
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",

                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  background: "#F5F5F5",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.offers}
              title="Entregadas"
              actions={[
                {
                  icon: () => <FaEye />,
                  tooltip: "Detalle",
                  onClick: (event, rowData) => props.offer_handler(rowData),
                },
                {
                  icon: () => <FaTrashAlt />,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) => props.delete_offer(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default OffersListEntregada;
