import React from "react";
import { Button, Grid } from "@material-ui/core";
import Eye from "@material-ui/icons/RemoveRedEye";
import MaterialTable from "material-table";
import { utils, writeFile } from "xlsx";
const List = (props: any) => {
  const renderColorSquare = (rowData: any) => {
    const color = rowData.CustomerColorReport;
    return (
      <div
        style={{
          width: 20,
          height: 20,
          backgroundColor: color,
          border: "1px solid #000",
        }}
      ></div>
    );
  };
  const formatDate = (dateString: string | null) => {
    return dateString ? dateString.split(" ")[0] : "";
  };
  const exportToExcel = () => {
    const data = props.customers.map((customer: any) => ({
      ID: customer.id,
      Nombre: customer.name,
      Comercial: customer.comercial,
      "Zona Comercial": customer.zone_comercial,
      Alias: customer.alias,
      "Codigo NMC": customer.nmc_code,
      "NIF/CIF": customer.NIF,
      Dirección: customer.address,
      "Días U. Gestión": customer.diffDays,
      "Prox. Gestión": formatDate(customer.nextVisit),
      Estado: customer.CustomerColorReport, // Assuming you want to export the color value
    }));

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Clientes Canal");
    const fileName = `clientes canal-${props.customers[0].type_id}.xlsx`;
    writeFile(wb, fileName);
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: "10px" }}
          >
            Exportar a Excel
          </Button>
          {props.customers ? (
            <MaterialTable
              columns={[
                { title: "ID", field: "id", width: "2%" },
                { title: "Nombre", field: "name", width: "80%" },
                { title: "Comercial", field: "comercial", width: "80%" },
                {
                  title: "Zona Comercial",
                  field: "zone_comercial",
                  width: "80%",
                },
                { title: "Alias", field: "alias", width: "80%" },
                { title: "Codigo NMC", field: "nmc_code", width: "80%" },
                {
                  title: "NIF/CIF",
                  field: "NIF",
                  width: 20,
                },

                { title: "Dirección", field: "address", width: "80%" },
                { title: "Días U. Gestión", field: "diffDays", width: "80%" },
                {
                  title: "Prox. Gestión",
                  field: "nextVisit",
                  width: "80%",
                  render: (rowData) => formatDate(rowData.nextVisit),
                },
                {
                  title: "Estado",
                  field: "state",
                  width: "80%",
                  render: renderColorSquare,
                },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: false,
                paging: true,
                pageSize: 50,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.customers}
              title=""
              actions={[
                {
                  icon: () => <Eye />,
                  tooltip: "Ver",
                  onClick: (event, rowData) => props.show(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default List;
