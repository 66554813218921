// @ts-nocheck
import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import more from "highcharts/highcharts-more";
more(Highcharts); // Necesario para gráficos de burbujas

const BubbleChart2 = (props: { datos: string | any[] }) => {
  const [cortePorcentaje, setCortePorcentaje] = useState(0.8);
  let datosProcesados;
  let serieMayorAporte = [];
  let serieMenorAporte = [];

  // Procesamiento de los datos
  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      return null;
    }
  } else if (Array.isArray(props.datos) && props.datos.length > 0) {
    datosProcesados = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    return null;
  }

  // Calcula el total de ventas
  const totalVentas = datosProcesados.reduce(
    (acc: any, { x }: any) => acc + x,
    0
  );
  // Encuentra el punto de corte para el 80%
  const corte = totalVentas * cortePorcentaje;

  // Inicializa la suma acumulada para clasificar las series
  let sumaAcumulada = 0;
  datosProcesados.sort((a: { x: number }, b: { x: number }) => b.x - a.x); // Ordena los datos por ventas de mayor a menor

  // Clasifica los datos en dos series
  for (let dato of datosProcesados) {
    sumaAcumulada += dato.x;
    if (sumaAcumulada <= corte) {
      serieMayorAporte.push(dato);
    } else {
      serieMenorAporte.push(dato);
    }
  }

  // Opciones de configuración del gráfico de burbujas
  const chartOptions = {
    chart: {
      type: "bubble",
      plotBorderWidth: 1,
      zoomType: "xy",
    },
    title: {
      text: "Análisis de Clientes por Ventas y Transacciones",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: "Ventas Totales",
      },
    },
    yAxis: {
      title: {
        text: "Número de Transacciones",
      },
    },
    // ...
    tooltip: {
      useHTML: true,
      formatter: function () {
        // @ts-ignore
        return `
      <table>
        <tr><th colspan="2"><h3>${this.point.name}</h3></th></tr>
        <tr><th>Ventas Totales:</th><td>${this.point.x.toLocaleString(
          "es-ES"
        )}</td></tr>
        <tr><th>Transacciones:</th><td>${this.point.y.toLocaleString(
          "es-ES"
        )}</td></tr>
    
        <tr><th>% del Total de Ventas:</th><td>${this.point.z
          .toFixed(2)
          .toLocaleString("es-ES")}%</td></tr>

        <tr><th>Promedio de Venta:</th><td>${this.point.promedioVenta.toLocaleString(
          "es-ES"
        )}</td></tr>
         <tr><th>Pais:</th><td>${this.point.country}</td></tr>

      </table>
    `;
      },
      followPointer: true,
    },
    // ...

    series: [
      {
        name: "Principal Aporte",
        data: serieMayorAporte,
        marker: {
          fillColor: "#2f7ed8",
        },
      },
      {
        name: "Menor Aporte",
        data: serieMenorAporte,
        marker: {
          fillColor: "#f28f43",
        },
      },
    ],
  };

  const handleSliderChange = (event) => {
    setCortePorcentaje(parseFloat(event.target.value));
  };

  return (
    <div>
      <div>
        <label htmlFor="corte-slider">
          Porcentaje de Corte: {cortePorcentaje * 100}%
        </label>
        <input
          id="corte-slider"
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={cortePorcentaje}
          onChange={handleSliderChange}
        />
      </div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default BubbleChart2;
