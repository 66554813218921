/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "react-leaflet-fullscreen/dist/styles.css";
import "./MapHeader.scss";
const redIcon = L.icon({
  iconUrl:
    "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});
const DetailArquitectMap = (props: any) => {
  return (
    <div>
      {props.customer.position ? (
        <MapContainer
          className="mapDetailHeader2"
          center={props.customer.position}
          zoom={17}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={props.customer.position}
            icon={redIcon}
            draggable={false}
          ></Marker>
        </MapContainer>
      ) : null}
    </div>
  );
};

export default DetailArquitectMap;
