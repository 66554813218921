import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Col,
  Input,
  Progress,
  Label,
  InputGroup
} from "reactstrap";
import axios from "axios";
import SelectCustomerModal from "../CustomerList/SelectCustomerModal";

const NewOfferDocumentModal = (props) => {
  const [file, setFile] = useState();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [customerdescription, setcustomerdescription] = useState("");
  const [customer_id, set_customer_id] = useState(null);
  const [openEditCustomerModal, setopenEditCustomerModal] = useState(false);
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };
    

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (customer_id) {
      
      const url = "https://rest.neobizztech.com/rest/uploadFile.php";
      const formData = new FormData();
      formData.append("query", "document");
      formData.append("customer_id", customer_id);
      formData.append("token", localStorage.getItem("token"));
      formData.append("userid", localStorage.getItem("userid"));
      formData.append("enterprise", 1);
      formData.append("file", file);
      formData.append("fileName", file.name);

      try {
        const res = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 10000000);
          },
        });

        console.log(res.data);
        props.toggle_document();
        props.addNewDocument();
      } catch (err) {
        alert(err);
      }
    } else {
      alert("Selecciona el Cliente");
    }
  };
    
    const toggle_customer = () => {
        setopenEditCustomerModal(!openEditCustomerModal);
    }
    
    const ediCustomer = (item) => {
      set_customer_id(item.id);
      setcustomerdescription(item.name);
      toggle_customer();
    }

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_document}>
        <ModalHeader>Nueva Oferta PDF</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
             <FormGroup row>
              <Label for="exampleFile" sm={2}>
                Cliente
              </Label>
              <Col sm={12}>
                <InputGroup>
                  <Input placeholder={customerdescription} disabled />

                  <Button color="secondary" onClick={toggle_customer}>
                    Selecciona
                  </Button>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={10}>
                <Input
                  id="newimage"
                  name="image"
                  placeholder="Ingresa Nombre"
                  type="file"
                  onChange={handleChange}
                  accept=".pdf"
                />
              </Col>
              <Col sm={2}>
                <Button type="submit" className="btn btn-success" block>
                  SUBIR
                </Button>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={12}>
                {" "}
                <Progress color="danger" value={uploadPercentage} />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.toggle_document}>CANCELAR</Button>
        </ModalFooter>
          </Modal>
          
          {openEditCustomerModal ? (
        <SelectCustomerModal
          isOpen={openEditCustomerModal}
          toggle_customer={toggle_customer}
          ediCustomer={ediCustomer}
        />
      ) : null}
     
          
      </div>
      );
     
};

export default NewOfferDocumentModal;