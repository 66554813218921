import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap";
import { Button } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import {
  //getCustomers,
  addNewProject,
  getCustomersDesktop,
} from "../services/customerService";
import { obtenerRelacionesProyectoCliente } from "../services/chartsService";
import MapProject from "./components/Projects/MapProject/MapProject";
//import ListProject2 from "./components/Projects/ListProject/ListProject2";
import ListProject from "./components/Projects/ListProject/ListProject";
import NewProjectModal from "./components/Projects/NewProjectModal/NewProjectModal";
import * as FaIcons from "react-icons/fa";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NetworkGraphProject from "../shared/components/charts/NetworkGraphProjects";
const Alumnos = () => {
  const { t } = useTranslation(["home", "projects"]);
  const history = useHistory();
  const [customers, setcustomers] = useState([]);
  const [openNewProjectModal, setopenNewProjectModal] = useState(false);
  const [datarelations, setDataRelations] = useState([]);
  const toggle = () => {
    setopenNewProjectModal(!openNewProjectModal);
  };
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const show = (data: any) => {
    window.open(`/layout/customer/${data.id}`);
  };

  const addProject = (item: any) => {
    setcustomers([]);
    addNewProject(item).then((response) => {
      if (response?.data.ok) {
        if (response?.data.code === 1) {
          alert(`${t("projects.NewProjectForm.messageProjectCreated")}`);
          setopenNewProjectModal(false);
          refresh();
        } else {
          alert(`${t("projects.NewProjectForm.messagefailedtocreate")}`);
        }
        //setcustomers(response?.data.customer);
      } else {
        alert(`${t("projects.NewProjectForm.messagefailedtocreate")}`);
        setopenNewProjectModal(false);
        refresh();
      }
      //refresh();
    });
  };

  const refresh = () => {
    getCustomersDesktop(1).then((response) => {
      console.table(response?.data.customersResults);
      if (response?.data.ok) {
        setcustomers(response?.data.customersResults);
        setopenNewProjectModal(false);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });

    obtenerRelacionesProyectoCliente().then((response) => {
      if (response?.data) {
        setDataRelations(response?.data);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            {t("projects.projectsTitle")}
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>{t("projects.projectsBreadcrum")}</BreadcrumbItem>
              <BreadcrumbItem>{t("projects.listBreadcrum")}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-1">
            <Col className="text-center text-md-right pull-right">
              <Button
                size="small"
                variant="contained"
                onClick={refresh}
                startIcon={<Refresh />}
              >
                {t("projects.refreshButton")}
              </Button>
            </Col>
          </Col>
          <Col className="col-lg-1">
            <Col className="text-center text-md-right pull-right">
              <Button size="small" variant="contained" onClick={toggle}>
                {t("projects.newprojectButton")}
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              <Row>
                <Col>
                  {datarelations ? (
                    <NetworkGraphProject data={datarelations} />
                  ) : null}
                </Col>
                <Col>
                  {customers ? (
                    <MapProject customers={customers} show={show} />
                  ) : null}
                </Col>
              </Row>

              {customers ? (
                <ListProject customers={customers} show={show} />
              ) : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
      {openNewProjectModal ? (
        <NewProjectModal
          isOpen={openNewProjectModal}
          toggle={toggle}
          addProject={addProject}
        />
      ) : null}
    </div>
  );
};

export default Alumnos;
