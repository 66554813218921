/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@material-ui/core";

import { Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import { useTranslation } from "react-i18next";
import CustomerCategoriesBadges from "../../../../shared/components/CustomerCategoriesBadges";
import { Card, CardBody, CardHeader } from "reactstrap";
import CustomerColaboratorsBadges from "../../../../shared/components/CustomerColaboratorsBadges";

const Header = (props: any) => {
  const [status_projet, set_status_project] = useState("");
  const { t, i18n } = useTranslation(["home", "projects"]);

  useEffect(() => {
    let newln = i18n.language;
    if (newln === "es") {
      console.log(props.customer.description_es);
      set_status_project(props.customer.description_es);
    }
    if (newln === "en") {
      console.log(props.customer.description_en);
      set_status_project(props.customer.description_en);
    }
    if (newln === "fr") {
      console.log(props.customer.description_fr);
      set_status_project(props.customer.description_fr);
    }
    if (newln === "pt") {
      console.log(props.customer.description_pt);
      set_status_project(props.customer.description_pt);
    }
  }, [props.customer]);

  useEffect(() => {
    const onLanguageChanged = () => {
      // Realiza las acciones necesarias cuando cambia el idioma
      console.log("El idioma ha cambiado a: ", i18n.language);

      let newln = i18n.language;
      console.log("nuevo idioma ", newln);
      if (newln === "es") {
        console.log(props.customer.description_es);
        set_status_project(props.customer.description_es);
      }
      if (newln === "en") {
        console.log(props.customer.description_en);
        set_status_project(props.customer.description_en);
      }
      if (newln === "fr") {
        console.log(props.customer.description_fr);
        set_status_project(props.customer.description_fr);
      }
      if (newln === "pt") {
        console.log(props.customer.description_pt);
        set_status_project(props.customer.description_pt);
      }
    };

    i18n.on("languageChanged", onLanguageChanged);
  }, [i18n, props.customer]);

  return (
    <div>
      <h5>
        <FaIcons.FaBuilding className="me-2" />

        <div className="headerButtonCustomerName">
          <Button
            size="small"
            variant="contained"
            onClick={props.toggle_editprojectname}
          >
            {props.customer.name}
          </Button>
        </div>
      </h5>
      {props.customer.address}
      <div className="headerButtonCustomer">
        <Button
          size="small"
          variant="contained"
          onClick={props.toggle_editadress}
        >
          {t("CustomerDetail.EditButton")}
        </Button>
      </div>
      <hr />
      <table className="detailprojectTable">
        <tbody>
          <tr>
            <th>ID</th>
            <td>{props.customer.id}</td>
          </tr>
          <tr>
            <th>{t("CustomerDetail.Header.Comercial")}</th>
            <td>{props.customer.agente}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  startIcon={<Edit />}
                  variant="contained"
                  onClick={props.toggle_agente}
                >
                  {t("CustomerDetail.EditButton")}
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <th>{t("CustomerDetail.Header.Location")}</th>
            <td>{props.customer.city}</td>
            <td className="detailProjecttr"> &ensp;</td>
          </tr>
          <tr>
            <th>{t("CustomerDetail.Header.Country")}</th>
            <td>{props.customer.country}</td>
            <td className="detailProjecttr"> &ensp;</td>
          </tr>
          <tr>
            <th>{t("CustomerDetail.Header.ComercialZone")}</th>
            <td>{props.customer.comercial_zone}</td>
            <td className="detailProjecttr"> &ensp;</td>
          </tr>
          <tr>
            <th>{t("CustomerDetail.Header.Status")}</th>
            <td>{status_projet ? status_projet : status_projet}</td>
            <td className="detailProjecttr">
              <div className="btn_edition">
                <Button
                  size="small"
                  startIcon={<Edit />}
                  variant="contained"
                  onClick={props.toggle_phase}
                >
                  {t("CustomerDetail.EditButton")}
                </Button>
              </div>
            </td>
          </tr>
          <tr></tr>
        </tbody>{" "}
      </table>
      <hr />
      <Card>
        <CardHeader>
          Relacionado con:
          <div className="btn_edition">
            <Button
              size="small"
              startIcon={<Edit />}
              variant="contained"
              onClick={props.toggle}
            >
              {t("CustomerDetail.EditButton")}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <CustomerCategoriesBadges
            customerCategories={props.customerCategories}
            handleDelete={props.handleDelete}
          />
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          Colaboradores
          <div className="btn_edition">
            <Button
              size="small"
              startIcon={<Edit />}
              variant="contained"
              onClick={props.toggle_colaborator}
            >
              {t("CustomerDetail.EditButton")}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <CustomerColaboratorsBadges
            colaborators={props.colaborators}
            handleDeleteColaborator={props.handleDeleteColaborator}
          />
        </CardBody>
      </Card>

      <hr />
      <div className="createdAt">
        {t("CustomerDetail.Header.CreatedAt")} {props.customer.createdAt}
      </div>
    </div>
  );
};
export default Header;
