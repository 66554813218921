import axios from "axios";
import config from "./confserver.json";




export const getDocuments = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    id:id
  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/documents', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteDocument = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    id: id,
    query:"deleteFileDocument"
  }
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteImage = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    id: id,
    query:"deleteFileImage"
  }
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}


export const deleteCustomerImages = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    customer_id: id,
    query:"deleteCustomerImages"
  }
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteCustomerFiles = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    customer_id: id,
    query:"deleteCustomerFiles"
  }
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const deleteCustomerReportFiles = async (id:any) => {
  const data = {
    token: localStorage.getItem("token"),
    userid: localStorage.getItem("userid"),
    customer_id: id,
    query:"deleteCustomerReportFiles"
  }
  try {
    const response = await axios.post(config.SERVER_DOC, data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}

export const newImageProduct = async (realPath: any, id: any) => {
  console.log("realPath:", realPath);
  console.log("ID del producto:", id);

  const url = 'https://neobizztech.net/rest/uploadFile.php';

  const token = localStorage.getItem("token") || ""; 
  const userid = localStorage.getItem("userid") || ""; 

  console.log("Token:", token);
  console.log("UserID:", userid);

  const formData = new FormData();
  formData.append('query', 'imageproduct');
  formData.append('product_code', id);
  formData.append('token', token);
  formData.append('userid', userid);
  
  // Si `realPath` es un File o Blob, úsalo directamente
  if (realPath instanceof File || realPath instanceof Blob) {
    console.log("Usando realPath como File o Blob directamente.");
    formData.append('file', realPath);
  } else {
    // Si `realPath` es una URL o algo más, intenta convertirlo a Blob
    try {
      const response = await fetch(realPath);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      formData.append('file', file);
      console.log("Archivo convertido desde la URL:", file);
    } catch (err) {
      console.error("Error al convertir realPath a Blob:", err);
      throw err;
    }
  }

  try {
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Respuesta del servidor:', res.data);
    return res.data;
  } catch (err) {
    console.error('Error al subir la imagen:', err);
    throw err;
  }
};

export const uploadExcelData = async (realPath: any) => {
  console.log("realPath:", realPath);

  const url = 'https://neobizztech.net/rest/uploadExcelFile.php';

  const token = localStorage.getItem("token") || ""; 
  const userid = localStorage.getItem("userid") || ""; 

  console.log("Token:", token);
  console.log("UserID:", userid);

  const formData = new FormData();
  formData.append('query', 'excel');
  formData.append('token', token);
  formData.append('userid', userid);
  
  // Si `realPath` es un File, se puede verificar el nombre
  if (realPath instanceof File) {
    if (realPath.type === 'text/csv' || realPath.name.endsWith('.csv')) {
      console.log("Usando realPath como archivo CSV directamente.");
      formData.append('file', realPath);
    } else {
      console.error("El archivo seleccionado no es un archivo CSV válido.");
      throw new Error("Por favor selecciona un archivo CSV válido.");
    }
  } else if (realPath instanceof Blob) {
    // Si `realPath` es un Blob (pero no un File), asumimos que es un CSV si se llegó aquí
    const file = new File([realPath], 'data.csv', { type: 'text/csv' });
    formData.append('file', file);
    console.log("Usando Blob convertido a archivo CSV.");
  } else {
    // Si `realPath` es una URL o algo más, intenta convertirlo a Blob
    try {
      const response = await fetch(realPath);
      const blob = await response.blob();
      const file = new File([blob], 'data.csv', { type: 'text/csv' });
      formData.append('file', file);
      console.log("Archivo convertido desde la URL:", file);
    } catch (err) {
      console.error("Error al convertir realPath a Blob:", err);
      throw err;
    }
  }

  try {
    const res = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('Respuesta del servidor:', res.data);
    return res.data;
  } catch (err) {
    console.error('Error al subir el archivo:', err);
    throw err;
  }
};