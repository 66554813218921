import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { getProducts } from "../services/productsServices";
import ListProduct from "./components/Products/List/ListProduct";
import { useHistory } from "react-router-dom";
import {
  deleteImageProduct,
  getProductsImages,
} from "../services/imagesServices";
import ImagesProducts from "../shared/components/ImagesProducts";
import { newImageProduct } from "../services/documentService";
interface ProductImage {
  id: number;
  src: string;
  thumbnail: string;
  thumbnailWidth: number;
  thumbnailHeight: number;
  caption: string;
  hash: string;
}
type SelectedImageGallery = {
  id: number;
  src: string;
  thumbnail: string;
  caption: string;
};
const Products = () => {
  const history = useHistory();
  const [products, setproducts] = useState([]);
  const [images, setImages] = useState<ProductImage[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImagesGal, setSelectedImagesGal] = useState<
    SelectedImageGallery[]
  >([]);
  const [selectedImage, setSelectedImage] = useState<ProductImage | null>(null);
  const [productName, setProductName] = useState<string | null>(null);
  const [productcode, setproductcode] = useState<string | null>(null);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getProducts().then((response) => {
      if (response?.data.ok) {
        console.table(response?.data.productsResults);
        setproducts(response?.data.productsResults);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  const showImages = (item: any) => {
    getProductsImages(item.nmc_code).then((response) => {
      setproductcode(item.nmc_code);

      if (response?.data.ok) {
        setImages(response?.data.imagesResults as ProductImage[]);
        setProductName(item.description);
        setModalVisible(true);
      }
    });
  };

  const showImagesCode = (code: any) => {
    getProductsImages(code).then((response) => {
      if (response?.data.ok) {
        setImages(response?.data.imagesResults as ProductImage[]);
        setModalVisible(true);
      }
    });
  };

  const handleUpload = async () => {
    // Crear un input de tipo file para seleccionar imágenes
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true; // Permitir seleccionar múltiples imágenes
    input.onchange = async (event) => {
      const target = event.target as HTMLInputElement;

      if (target?.files) {
        // Iterar sobre cada archivo seleccionado
        for (let i = 0; i < target.files.length; i++) {
          const file = target.files[i];
          const realPath = URL.createObjectURL(file); // Obtener la URL del archivo

          // Llamar a la función newImageProduct con cada imagen
          try {
            const response = await newImageProduct(realPath, productcode); // Asegúrate de tener `selectedProductId` definido
            if (response?.ok) {
              //@ts-ignore
              console.log(response?.msg);
              console.log(`Imagen ${productcode} subida exitosamente`);
              showImagesCode(productcode);
              // Aquí puedes manejar la actualización de la lista de imágenes o mostrar un mensaje de éxito
            } else {
              console.log(`Error al subir la imagen ${productcode}`);
              // Manejar el error, por ejemplo, mostrando un mensaje de error
            }
          } catch (err) {
            console.error(`Error al subir la imagen ${file.name}:`, err);
          }
        }
      } else {
        console.log("No se seleccionaron archivos.");
      }
    };

    // Abrir el cuadro de diálogo para seleccionar archivos
    input.click();
  };

  const handleDelete = (id: number) => {
    const userConfirmed = window.confirm(
      "¿Está seguro de que desea eliminar esta imagen?"
    );

    if (userConfirmed) {
      // Implementa la lógica para eliminar la imagen usando el id
      deleteImageProduct(id).then((response) => {
        if (response?.data.ok) {
          console.log("Imagen eliminada exitosamente");
          showImagesCode(productcode);
          // Aquí puedes agregar lógica adicional, como actualizar la lista de imágenes o mostrar un mensaje de éxito
        } else {
          console.log("Error al eliminar la imagen");
          // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje de error
        }
      });
    } else {
      console.log("Eliminación cancelada por el usuario");
    }
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBarcode className="me-2" />
            Productos
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Productos</BreadcrumbItem>
              <BreadcrumbItem>Lista de Productos</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={refresh}
              >
                <MdIcons.MdRefresh className="me-2" />
                Refrescar
              </button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Lista de Productos</CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              {products ? (
                <ListProduct products={products} showImages={showImages} />
              ) : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
      <ImagesProducts
        images={images}
        isOpen={modalVisible}
        toggle={() => setModalVisible(false)}
        onUpload={handleUpload}
        handleDelete={handleDelete}
        productName={productName}
      />
    </div>
  );
};

export default Products;
