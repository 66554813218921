import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";

// Inicializar los módulos necesarios
highchartsMore(Highcharts);
solidGauge(Highcharts);

const GaugeChart = (props: any) => {
  let datosProcesados: any;

  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      return null;
    }
  } else if (Array.isArray(props.datos) && props.datos.length > 0) {
    datosProcesados = props.datos[0];
  } else {
    console.error("Formato de datos desconocido");
    return null;
  }

  // Verificar si meta y actual están definidos

  const meta = datosProcesados[0].goal;
  const actual = datosProcesados[0].totalVentas;
  const porcentajeCumplimiento = meta && actual ? (actual / meta) * 100 : 0;

  const options = {
    chart: {
      type: "solidgauge",
    },
    title: {
      text: "Cumplimiento de Meta de Ventas",
    },
    tooltip: {
      enabled: false, // Deshabilita el tooltip
    },
    pane: {
      center: ["50%", "85%"],
      size: "100%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    yAxis: {
      stops: [
        [0.1, "#DF5353"], // Rojo
        [0.5, "#DDDF0D"], // Amarillo
        [0.9, "#55BF3B"], // Verde
      ],
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70,
      },
      labels: {
        y: 16,
      },
      min: 0,
      max: 100,
      // title: {
      // text: "Cumplimiento",
      // },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Cumplimiento",
        data: [porcentajeCumplimiento],
        dataLabels: {
          format: `<div style="text-align:center">
                    <span style="font-size:13px;color:black">Meta: ${
                      meta?.toLocaleString() ?? "N/A"
                    }</span><br/>
                    <span style="font-size:13px;color:black">Actual: ${
                      actual?.toLocaleString() ?? "N/A"
                    }</span><br/>
                    <span style="font-size:25px">{y:.1f}%</span><br/>
                    <span style="font-size:12px;opacity:0.4">cumplimiento</span>
                   </div>`,
        },
        tooltip: {
          valueSuffix: " %",
        },
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: "400px", maxWidth: "500px" } }}
      />
    </div>
  );
};

export default GaugeChart;
