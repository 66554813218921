import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthContext } from "./AuthContext"; // Asegúrate de importar correctamente el contexto

const ProtectedRoute = ({ ...routeProps }: RouteProps) => {
  const { isAuthenticated } = useContext(AuthContext); // Usa el contexto de autenticación

  if (isAuthenticated) {
    return <Route {...routeProps} />;
  }

  return <Redirect to="/login" />;
};

export default ProtectedRoute;
