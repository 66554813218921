import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { Button } from "@material-ui/core";
import {
  getBillingTotal,
  getCustomersFacturation2,
  getFacturationByProducts,
  getCantidadByProducts,
  getFacturationChannelsPie,
  getSalesByCategory,
  getComercialFacturation,
  obtenerDatosDeVentas,
  obtenerVentasPorMesYAnio,
  obtenerVentasTotalesPorAnio,
  obtenerVentasPorRegion,
  obtenerVentasPorMesYAnio2,
  obtenerCumplimientoMetaAnual,
  obtenerVentas80y20PorMesYAnio,
  obtenerDatosParaGraficoDeBurbujas,
} from "../services/chartsService";

//import { useHistory } from "react-router-dom";
import BillingTotalChart from "../shared/components/charts/BillingTotalChart";
import Facturation2Chart from "../shared/components/charts/Facturacion2Chart";
import FacturationByProducts from "../shared/components/charts/FacturationByProducts";
import CantidadByProducts from "../shared/components/charts/CantidadByProducts";
import FacturacionCanales from "../shared/components/charts/FacturacionCanales";
import VentasCategorias from "../shared/components/charts/VentasCategorias";
import VentasVendedores from "../shared/components/charts/VentasVendedores";
//import VentasVendedoresTorta from "../shared/components/charts/VentasVendoresTorta";
import SalesTrendChart from "../shared/components/charts/SalesTrendChart";
import MonthlySalesComparisonChart from "../shared/components/charts/MonthlySalesComparisonChart";
import TotalSalesByYearChart from "../shared/components/charts/TotalSalesByYearChart";

import HeathMapChart from "../shared/components/charts/HeathMapChart";

import MapRegion from "../shared/components/charts/MapRegion";
import MapRegionPortugal from "../shared/components/charts/MapRegionPortugal";
import GaugeChart from "../shared/components/charts/GaugeChart";

import AreaChart from "../shared/components/charts/AreaChart";

import BubbleChart2 from "../shared/components/charts/BubleChart2";
import "bootstrap/dist/css/bootstrap.min.css";

const Inicio = (props: any) => {
  //const history = useHistory();

  const [billingdata, setbillingdata] = useState([]);
  const [datos2, setdatos2] = useState([]);
  const [datos3, setdatos3] = useState([]);
  const [datos4, setdatos4] = useState([]);
  const [datos5, setdatos5] = useState([]);
  const [datos6, setdatos6] = useState([]);
  const [datos7, setdatos7] = useState([]);
  const [datos8, setdatos8] = useState([]);
  const [datos9, setdatos9] = useState([]);
  const [datos10, setdatos10] = useState([]);
  const [datos11, setdatos11] = useState([]);
  const [datos12, setdatos12] = useState([]);
  //const [datos13, setdatos13] = useState([]);
  const [datos14, setdatos14] = useState([]);
  //const [datos15, setdatos15] = useState([]);
  const [datos16, setdatos16] = useState([]);

  const [datos17, setdatos17] = useState([]);
  const [datos18, setdatos18] = useState([]);

  const [bubbleyear, setbubbleyear] = useState(new Date().getFullYear());

  const [paretoyear, setparetoyear] = useState(new Date().getFullYear());

  const [portugalyear, setportugalyear] = useState(new Date().getFullYear());
  const [spainyear, setspainyear] = useState(new Date().getFullYear());
  const [gausyear, setgausyear] = useState(new Date().getFullYear());
  const [FacturationChannelsPieyear, setFacturationChannelsPieyear] = useState(
    new Date().getFullYear()
  );

  //const [FacturationComercialPieYear, setFacturationComercialPieYear] =
  // useState(new Date().getFullYear());

  const years = Array.from(
    new Array(new Date().getFullYear() - 2021),
    (val, index) => 2022 + index
  );

  const [useGrayScale, setUseGrayScale] = useState(false);

  const colorSchemes = {
    original: {
      minColor: "#FFFFFF",
      maxColor: "#B03A2E",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#FFEDA0"],
        [1, "#B03A2E"],
      ],
    },
    grayScale: {
      minColor: "#FFFFFF",
      maxColor: "#000000",
      stops: [
        [0, "#FFFFFF"],
        [0.5, "#CCCCCC"],
        [1, "#000000"],
      ],
    },
  };

  const toggleColorScheme = () => {
    setUseGrayScale(!useGrayScale);
  };

  useEffect(() => {
    refreshCustomers();
    // getBillingTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [datos18]);

  const refreshCustomers = () => {
    getBillingTotal().then((response) => {
      if (response?.data) {
        //console.table(response?.data);
        setbillingdata(response?.data);
      } else {
      }
    });

    obtenerVentasPorMesYAnio2().then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos12(response?.data);
      } else {
      }
    });

    obtenerVentasTotalesPorAnio().then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos10(response?.data);
      } else {
      }
    });

    obtenerCumplimientoMetaAnual(gausyear).then((response) => {
      console.table(response?.data);
      if (response?.data && response.data.length > 0) {
        console.log("los datos=>", response?.data);
        setdatos16(response?.data);
      } else {
      }
    });

    obtenerVentasPorMesYAnio().then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        setdatos9(response?.data);
      } else {
      }
    });

    obtenerDatosDeVentas().then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        console.table(response?.data);
        setdatos8(response?.data);
      } else {
      }
    });

    obtenerDatosParaGraficoDeBurbujas(bubbleyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos18(response?.data);
      } else {
      }
    });

    obtenerVentas80y20PorMesYAnio(paretoyear).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos17(response?.data);
      } else {
      }
    });

    getFacturationChannelsPie(FacturationChannelsPieyear).then((response) => {
      if (response?.data) {
        console.table(response?.data);
        setdatos5(response?.data);
      } else {
      }
    });

    getSalesByCategory().then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        // console.table(response?.data);
        setdatos6(response?.data);
      } else {
      }
    });

    getCustomersFacturation2().then((response) => {
      if (response?.data) {
        //console.table(response?.data);
        setdatos2(response?.data);
      } else {
      }
    });

    getFacturationByProducts().then((response) => {
      if (response?.data) {
        //console.table(response?.data);
        setdatos3(response?.data);
      } else {
      }
    });

    getCantidadByProducts().then((response) => {
      if (response?.data) {
        //console.table(response?.data);
        setdatos4(response?.data);
      } else {
      }
    });

    getComercialFacturation().then((response) => {
      //console.table(response?.data);
      if (response?.data) {
        // console.table(response?.data);
        setdatos7(response?.data);
      } else {
      }
    });

    if (localStorage.getItem("enterprise") === "1") {
      obtenerVentasPorRegion("es", spainyear).then((response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos11(response?.data);
        } else {
        }
      });

      obtenerVentasPorRegion("pt", portugalyear).then((response) => {
        console.table(response?.data);
        if (response?.data) {
          setdatos14(response?.data);
        } else {
        }
      });
    }
  };

  const obtenerDatosParaGraficoDeBurbujasChange = (e: any) => {
    setbubbleyear(e.target.value);

    obtenerDatosParaGraficoDeBurbujas(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos18(response?.data);
      } else {
      }
    });
  };

  const obtenerVentas80y20PorMesYAnioChange = (e: any) => {
    setparetoyear(e.target.value);

    obtenerVentas80y20PorMesYAnio(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos17(response?.data);
      } else {
      }
    });
  };

  const obtenerVentasPorRegionPTChange = (e: any) => {
    setportugalyear(e.target.value);

    obtenerVentasPorRegion("pt", e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos14(response?.data);
      } else {
      }
    });
  };

  const obtenerVentasPorRegionESChange = (e: any) => {
    setspainyear(e.target.value);

    obtenerVentasPorRegion("es", e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data) {
        setdatos11(response?.data);
      } else {
      }
    });
  };

  const obtenerCumplimientoMetaAnualChange = (e: any) => {
    setgausyear(e.target.value);
    obtenerCumplimientoMetaAnual(e.target.value).then((response) => {
      console.table(response?.data);
      if (response?.data && response.data.length > 0) {
        console.log("los datos=>", response?.data);
        setdatos16(response?.data);
      } else {
      }
    });
  };

  const getFacturationChannelsPieChange = (e: any) => {
    setFacturationChannelsPieyear(e.target.value);
    getFacturationChannelsPie(e.target.value).then((response) => {
      if (response?.data) {
        console.table(response?.data);
        setdatos5(response?.data);
      } else {
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaHome className="me-2" />
            Home
          </h4>
        </div>

        <Breadcrumb listTag="div">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem>Dashboard</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="content-page">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Mapa de Calor
                <div className="pull-right">
                  {" "}
                  {/* Alinea el botón a la derecha */}
                  <Button onClick={toggleColorScheme}>
                    {" "}
                    {/* Botón pequeño */}
                    {useGrayScale
                      ? "Usar colores originales"
                      : "Cambiar a escala de grises"}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <div>
                  {datos12 ? (
                    <HeathMapChart
                      data={datos12}
                      colorScheme={
                        useGrayScale
                          ? colorSchemes.grayScale
                          : colorSchemes.original
                      }
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row xs="3">
          <Col xs="6">
            <Card>
              <CardHeader>Facturación Mensual</CardHeader>
              <CardBody>
                {billingdata ? (
                  <BillingTotalChart billingdata={billingdata} />
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col xs="3">
            <Card>
              <CardHeader>Ventas</CardHeader>
              <CardBody>
                {datos10 ? <TotalSalesByYearChart datos={datos10} /> : null}
              </CardBody>
            </Card>
          </Col>
          <Col xs="3">
            <Card>
              <CardHeader>
                Cumplimiento
                <select
                  value={gausyear}
                  onChange={obtenerCumplimientoMetaAnualChange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                {datos16 ? <GaugeChart datos={datos16} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>Comparación de Ventas Mensuales por Año </CardHeader>
              <CardBody>
                {datos9 ? <MonthlySalesComparisonChart datos={datos9} /> : null}
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                Análisis de Tendencias Temporales de las ventas{" "}
              </CardHeader>
              <CardBody>
                {datos8 ? <SalesTrendChart datos={datos8} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Diagrama de Pareto
                <select
                  id="1"
                  value={paretoyear}
                  onChange={obtenerVentas80y20PorMesYAnioChange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                {datos17 ? <AreaChart datos={datos17} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                Análisis de Clientes por Ventas y Transacciones
                <select
                  id="2"
                  value={bubbleyear}
                  onChange={obtenerDatosParaGraficoDeBurbujasChange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                {datos18 ? <BubbleChart2 datos={datos18} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {localStorage.getItem("enterprise") === "1" && (
          <Row xs="2">
            <Col xs="6">
              <Card>
                <CardHeader>
                  Ventas por Region en Portugal
                  <select
                    value={portugalyear}
                    onChange={obtenerVentasPorRegionPTChange}
                    style={{ marginLeft: "20px", float: "right" }}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </CardHeader>
                <CardBody>
                  {datos14 ? <MapRegionPortugal datos={datos14} /> : null}
                </CardBody>
              </Card>
            </Col>
            <Col xs="6">
              <Card>
                <CardHeader>
                  Ventas por Region en España
                  <select
                    value={spainyear}
                    onChange={obtenerVentasPorRegionESChange}
                    style={{ marginLeft: "20px", float: "right" }}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </CardHeader>
                <CardBody>
                  {datos11 ? <MapRegion datos={datos11} /> : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <br />
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>
                Facturación Anual Canales
                <select
                  value={FacturationChannelsPieyear}
                  onChange={getFacturationChannelsPieChange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                {datos5 ? <FacturacionCanales datos={datos5} /> : null}
              </CardBody>
            </Card>
          </Col>

          <Col>
            <Card>
              <CardHeader>Ventas por Canales</CardHeader>
              <CardBody>
                {datos6 ? <VentasCategorias datos={datos6} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/*  <Col>
            <Card>
              <CardHeader>
                Facturación Anual Comerciales
                <select
                  value={FacturationChannelsPieyear}
                  onChange={getFacturationChannelsPieChange}
                  style={{ marginLeft: "20px", float: "right" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </CardHeader>
              <CardBody>
                {datos7 ? <VentasVendedoresTorta series={datos7} /> : null}
              </CardBody>
            </Card>
          </Col> */}

          <Col>
            <Card>
              <CardHeader>Facturación por Comerciales al mes Actual</CardHeader>
              <CardBody>
                {datos7 ? <VentasVendedores series={datos7} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <CardHeader>TOP 10 Clientes</CardHeader>
              <CardBody>
                {datos2 ? <Facturation2Chart datos={datos2} /> : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />
        <Row xs="2">
          <Col>
            <Card>
              <CardHeader>TOP 15 Productos en Facturación</CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                <CardText>
                  {datos3 ? <FacturationByProducts datos={datos3} /> : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>Top 15 Productos en Cantidad</CardHeader>
              <CardBody>
                <CardTitle tag="h5"></CardTitle>
                <CardText>
                  {datos4 ? <CantidadByProducts datos={datos4} /> : null}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Inicio;
