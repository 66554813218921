import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Col,
  Row,
  Modal,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import {
  getUsers,
  getUserscharges,
  saveNewUser,
  saveUserEdition,
} from "../services/userService";
import ListUsers from "./components/Users/ListUsers";
import { useHistory } from "react-router-dom";

type UserInteface = {
  id: number;
  name: string;
  last_name: string;
  charge: string;
  fone: string;
  email: string;
  code: string;
  canViewInfo: boolean;
  dep: number;
  isManager: boolean;
  operation: boolean;
  enabled: boolean;
  is_internal: boolean;
};

type ChargeInterface = {
  id: number;
  description: string;
};
const Users = () => {
  const history = useHistory();
  const [users, setusers] = useState([]);
  const [charges, setcharges] = useState<ChargeInterface[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserInteface | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [newUser, setNewUser] = useState<UserInteface>({
    id: 0,
    name: "",
    last_name: "",
    charge: "",
    fone: "",
    email: "",
    code: "",
    dep: 0,
    isManager: false,
    operation: false,
    enabled: true,
    is_internal: false,
    canViewInfo: false, // Agrega esta línea
  });
  const [createModalOpen, setCreateModalOpen] = useState(false);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    getUsers().then((response) => {
      if (response?.data.ok) {
        setusers(response?.data.usersResults);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
    getcharges();
  };

  const getcharges = () => {
    getUserscharges().then((response) => {
      if (response?.data.ok) {
        setcharges(response?.data.charges);
      }
    });
  };

  const editUser = (user: UserInteface) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const target = e.target as HTMLInputElement; // Asegura que es un HTMLInputElement
      setSelectedUser(
        (prevState) =>
          ({
            ...prevState,
            [name]: target.checked, // Aquí accedes a `checked`
          } as UserInteface)
      );
    } else {
      setSelectedUser(
        (prevState) =>
          ({
            ...prevState,
            [name]: value,
          } as UserInteface)
      );
    }
  };

  const saveChanges = async () => {
    if (selectedUser) {
      try {
        const response = await saveUserEdition(
          selectedUser.id,
          selectedUser.name,
          selectedUser.last_name,
          selectedUser.fone,
          selectedUser.email,
          selectedUser.code,
          selectedUser.dep,
          selectedUser.isManager,
          selectedUser.operation,
          selectedUser.enabled,
          selectedUser.is_internal
        );

        if (response?.data.ok) {
          console.log("Usuario actualizado con éxito");
          // Aquí podrías agregar una llamada para refrescar la lista de usuarios
          refresh();
          // O cerrar el modal
          setModalOpen(false);
        } else {
          console.log("Error al actualizar el usuario");
          alert(response?.data.error);
        }
      } catch (e) {
        console.error("Error en la solicitud de actualización del usuario:", e);
      }
    }
  };

  const handleNewUserInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      // Asegúrate de que `e.target` es un `HTMLInputElement` antes de acceder a `checked`
      const target = e.target as HTMLInputElement;
      setNewUser((prevState) => ({
        ...prevState,
        [name]: target.checked, // Accede a `checked` solo cuando es un checkbox
      }));
    } else {
      setNewUser((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const saveNewUserChanges = async () => {
    try {
      const response = await saveNewUser(
        newUser.name,
        newUser.last_name,
        newUser.fone,
        newUser.email,
        newUser.code,
        newUser.dep,
        newUser.isManager,
        newUser.operation,
        newUser.enabled,
        newUser.is_internal
      );

      if (response?.data.ok) {
        console.log("Nuevo usuario creado con éxito");
        // Refrescar la lista de usuarios
        refresh();
        // Cerrar el modal
        setCreateModalOpen(false);
        setNewUser({
          id: 0,
          name: "",
          last_name: "",
          charge: "",
          fone: "",
          email: "",
          code: "",
          dep: 0,
          isManager: false,
          operation: false,
          enabled: true,
          is_internal: false,
          canViewInfo: false, // Asegúrate de incluir esta propiedad
        });
      } else {
        console.log("Error al crear el nuevo usuario");

        alert(response?.data.error);
      }
    } catch (e) {
      console.error("Error en la solicitud de creación del usuario:", e);
    }
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaUsers className="me-2" />
            Usuarios
          </h4>
        </div>

        <Row xs="2">
          <Col xl="10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Usuarios</BreadcrumbItem>
              <BreadcrumbItem>Lista de Usuarios</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col xl="2">
            <Col className="text-center text-md-right pull-right">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => setCreateModalOpen(true)}
              >
                <MdIcons.MdAdd className="me-2" />
                Nuevo Usuario
              </button>{" "}
              <Button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={refresh}
              >
                <MdIcons.MdRefresh className="me-2" />
                Refrescar
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Usuarios</CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <CardText>
              {users ? <ListUsers users={users} editUser={editUser} /> : null}
            </CardText>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={createModalOpen}
        toggle={() => setCreateModalOpen(!createModalOpen)}
      >
        <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>
          Crear Nuevo Usuario
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Nombre</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={newUser.name}
              onChange={handleNewUserInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="last_name">Apellido</Label>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              value={newUser.last_name}
              onChange={handleNewUserInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={newUser.email}
              onChange={handleNewUserInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="fone">Fono</Label>
            <Input
              type="text"
              name="fone"
              id="fone"
              value={newUser.fone}
              onChange={handleNewUserInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="code">Codigo</Label>
            <Input
              type="text"
              name="code"
              id="code"
              value={newUser.code}
              onChange={handleNewUserInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="dep">Rol</Label>
            <Input
              type="select"
              name="dep"
              id="dep"
              value={newUser.dep}
              onChange={handleNewUserInputChange}
            >
              {charges.map((charge) => (
                <option key={charge.id} value={charge.id}>
                  {charge.description}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="isManager"
                checked={newUser.isManager}
                onChange={handleNewUserInputChange}
              />{" "}
              Es Manager
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="operation"
                checked={newUser.operation}
                onChange={handleNewUserInputChange}
              />{" "}
              Operación
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="enabled"
                checked={newUser.enabled}
                onChange={handleNewUserInputChange}
              />{" "}
              Habilitado
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="is_internal"
                checked={newUser.is_internal}
                onChange={handleNewUserInputChange}
              />{" "}
              Es Interno
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveNewUserChanges}>
            Guardar
          </Button>{" "}
          <Button color="secondary" onClick={() => setCreateModalOpen(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      {selectedUser && (
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            Editar Usuario
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">Nombre</Label>
              <Input
                type="text"
                name="name"
                id="name"
                value={selectedUser.name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="last_name">Apellido</Label>
              <Input
                type="text"
                name="last_name"
                id="last_name"
                value={selectedUser.last_name}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={selectedUser.email}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fone">Fono</Label>
              <Input
                type="text"
                name="fone"
                id="fone"
                value={selectedUser.fone}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="code">Codigo</Label>
              <Input
                type="text"
                name="code"
                id="code"
                value={selectedUser.code}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dep">Rol</Label>
              <Input
                type="select"
                name="dep"
                id="dep"
                value={selectedUser.dep}
                onChange={handleInputChange}
              >
                {charges.map((charge) => (
                  <option key={charge.id} value={charge.id}>
                    {charge.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="isManager"
                  checked={selectedUser?.isManager || false} // Asegúrate de que tiene un valor booleano
                  onChange={handleInputChange}
                />{" "}
                Administrador
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="operation"
                  checked={selectedUser?.operation || false} // Asegúrate de que tiene un valor booleano
                  onChange={handleInputChange}
                />{" "}
                Operaciones (Atención al Cliente)
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="enabled"
                  checked={selectedUser?.enabled || false} // Asegúrate de que tiene un valor booleano
                  onChange={handleInputChange}
                />{" "}
                Habilitado
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="is_internal"
                  checked={selectedUser?.is_internal || false} // Asegúrate de que tiene un valor booleano
                  onChange={handleInputChange}
                />{" "}
                Es Interno
              </Label>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={saveChanges}>
              Guardar
            </Button>{" "}
            <Button color="secondary" onClick={() => setModalOpen(false)}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Users;
