import axios from "axios";
import config from "./confserver.json";




export const getProducts = async () => {
  const data = {
    token: localStorage.getItem("token"),

  }
  try {
    const response = await axios.post(config.SERVER_URL+'/api/products', data);
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
  
}