import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Col,
  Input,
} from "reactstrap";
import "./style.scss";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

const NewProjectModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [name, setname] = useState("");
  const [latitud, setlatitud] = useState(0);
  const [longitud, setlongitud] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [position, setposition] = useState([0, 0]);
  const [address, setaddress] = useState("");

  const PlaceSelected = (place: any) => {
    setlatitud(place.geometry.location.lat());
    setlongitud(place.geometry.location.lng());
    setposition([place.geometry.location.lat(), place.geometry.location.lng()]);
    setaddress(place.formatted_address);
  };

  const nameChanged = (e: any) => {
    setname(e.target.value);
  };

  const NewProject = () => {
    if (name) {
      if (address) {
        const item = {
          name: name,
          address: address,
          latitud: latitud,
          longitud: longitud,
          type: 1,
        };

        props.addProject(item);
      } else {
        alert(`${t("projects.NewProjectForm.messageAddress")}`);
      }
    } else {
      alert(`${t("projects.NewProjectForm.messageName")}`);
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader>{t("projects.NewProjectForm.title")}</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                {t("projects.NewProjectForm.name")}
              </Label>
              <Col sm={10}>
                <Input
                  id="exampleEmail"
                  name="email"
                  placeholder={
                    t("projects.NewProjectForm.namePlaceholder") as string
                  }
                  type="text"
                  onChange={nameChanged}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                {t("projects.NewProjectForm.address")}
              </Label>
              <Col sm={10}>
                <Autocomplete
                  className="address-input"
                  placeholder={
                    t("projects.NewProjectForm.addressPlaceholder") as string
                  }
                  apiKey="AIzaSyC0x7V6B-BvBRTrJz2hl8mtj4JVJmH0RPc"
                  style={{ width: "100%" }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(place) => PlaceSelected(place)}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle}>
            {t("projects.NewProjectForm.cancelButton")}
          </Button>
          <Button onClick={NewProject} color="success">
            {t("projects.NewProjectForm.insertButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewProjectModal;
