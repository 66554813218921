import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Col,
  Input,
} from "reactstrap";
import "./style.scss";
import Autocomplete from "react-google-autocomplete";

const NewDecoratortModal = (props: any) => {
  const [latitud, setlatitud] = useState(0);
  const [longitud, setlongitud] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [position, setposition] = useState([0, 0]);

  const [promotername, setpromotername] = useState("");
  const [promoteraddress, setpromoteraddress] = useState("");
  const [codenmc, setcodenmc] = useState("");
  const [promoternif, setpromoternif] = useState("");
  const [promoteralias, setpromoteralias] = useState("");

  const PlaceSelected = (place: any) => {
    setlatitud(place.geometry.location.lat());
    setlongitud(place.geometry.location.lng());
    setposition([place.geometry.location.lat(), place.geometry.location.lng()]);
    setpromoteraddress(place.formatted_address);
  };
  const promotername_handler = (e: any) => {
    setpromotername(e.target.value);
  };
  const codenmc_handler = (e: any) => {
    setcodenmc(e.target.value);
  };
  const promoternif_handler = (e: any) => {
    setpromoternif(e.target.value);
  };

  const promoternamealias_handler = (e: any) => {
    setpromoteralias(e.target.value);
  };

  const NewDecorator = () => {
    if (promotername) {
      if (promoteralias) {
        if (promoteraddress) {
          if (codenmc) {
            if (promoternif) {
              const item = {
                promotername: promotername,
                alias: promoteralias,
                promoteraddress: promoteraddress,
                codenmc: codenmc,
                promoternif: promoternif,
                latitud: latitud,
                longitud: longitud,
                type: 5,
              };
              props.AddNewPromoter(item);
            } else {
              alert("Ingresa NIF o CIF");
            }
          } else {
            alert("Ingresa el Codigo NMC");
          }
        } else {
          alert("Ingresa una Dirección");
        }
      } else {
        alert("Ingresa el Alias de la Promotora");
      }
    } else {
      alert("Ingresa el Nombre de la Promotora");
    }
  };

  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader>Nuevo Decorador</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Nombre Decorador
              </Label>
              <Col sm={10}>
                <Input
                  id="formPromotora"
                  name="promotora"
                  placeholder="Ingresa Nombre Decorador"
                  type="text"
                  onChange={promotername_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleEmail" sm={2}>
                Alias Decorador
              </Label>
              <Col sm={10}>
                <Input
                  id="formPromotoraalias"
                  name="promotoraalias"
                  placeholder="Ingresa Alias Decorador"
                  type="text"
                  onChange={promoternamealias_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                Dirección Decorador
              </Label>
              <Col sm={10}>
                <Autocomplete
                  className="address-input"
                  apiKey="AIzaSyC0x7V6B-BvBRTrJz2hl8mtj4JVJmH0RPc"
                  style={{ width: "100%" }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(place) => PlaceSelected(place)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                NMC Codigo
              </Label>
              <Col sm={10}>
                <Input
                  id="formNMCCode"
                  name="nmccode"
                  placeholder="Ingresa Codigo de NMC"
                  type="text"
                  onChange={codenmc_handler}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                NIF/CIF
              </Label>
              <Col sm={10}>
                <Input
                  id="formNIF"
                  name="nif"
                  placeholder="Ingresa NIF/CIF"
                  type="text"
                  onChange={promoternif_handler}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle}>CANCELAR</Button>
          <Button onClick={NewDecorator} color="success">
            INGRESAR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewDecoratortModal;
