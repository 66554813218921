/*import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./i18n";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
); */

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./i18n";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <React.Fragment>
      <App />
    </React.Fragment>
  );
} else {
  console.error("Could not find container element with id 'root'");
}
