import React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
require("highcharts/modules/exporting")(Highcharts);

const BillingChart = (props: any) => {
  //const data = props.customer;

  /* interface FormatterContext {
    y: number;
  } */

  const options = {
    colors: [
      "#A3CEE7",
      "#1AA588",
      "#6600cc",
      "#0099e6",
      "#0066ff",
      "#005EBB",
      "#ffe680",
      "#ff9900",
      "#ff3300",
      "#000d1a",
    ],
    chart: {
      type: "areaspline",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: props.customers.name,
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yAxis: {
      title: {
        text: "Facturación (€)",
      },
    },
    series: props.billingdata.map((d: { name: any; data: any }) => ({
      name: d.name,
      data: d.data,
    })),

    /* plotOptions: {
      area: {
        dataLabels: {
          enabled: true,
          formatter: function (): string {
            const context = this as unknown as FormatterContext;
            return "$" + context.y;
          },
        },
      },
    }, */
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BillingChart;
