import React from "react";
import { Grid } from "@material-ui/core";
import Eye from "@material-ui/icons/RemoveRedEye";
import MaterialTable from "material-table";

const CustomersProductsSoldList = (props: any) => {
  let datosProcesados: any;
  let errorMessage: string | null = null;

  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      errorMessage = "Error al analizar JSON";
    }
  } else if (Array.isArray(props.datos)) {
    datosProcesados = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    errorMessage = "Formato de datos desconocido";
  }

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {datosProcesados ? (
            <MaterialTable
              columns={[
                { title: "ID", field: "id", width: "2%" },
                { title: "Nombre", field: "nombreCliente", width: "80%" },
                { title: "Codigo", field: "customerCode", width: "80%" },
                {
                  title: "Canal",
                  field: "tipoClienteDesc",
                  width: "80%",
                },
                {
                  title: "Total Ventas Producto",
                  field: "total_ventas",
                  width: "80%",
                },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: true,
                paging: false,
                pageSize: 50,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={datosProcesados}
              title=""
              actions={[
                {
                  icon: () => <Eye />,
                  tooltip: "Ver",
                  onClick: (event, rowData) => props.show(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomersProductsSoldList;
