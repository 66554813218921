/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { FileIcon, defaultStyles } from "react-file-icon";
import Delete from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import "./document.scss";

const DocumentList = (props: any) => {
  interface DocumentResult {
    id: number;
    src: string;
    file_name: string;
    extension: string;
    uploadedAt: string;
  }

  const [openDocument, setOpenDocument] = useState<boolean>(false);
  const [src, setSrc] = useState<string>("");
  const [ext, setExt] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const check = (src_doc: string, ext: string) => {
    setSrc(src_doc);
    setExt(ext);
    setIsOpen(!isOpen);
  };

  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  const render = (extension: string, src: string) => {
    if (extension === "docx") {
      return (
        <>
          <a href={src}>
            <FileIcon extension={extension} {...defaultStyles.docx} />
          </a>
        </>
      );
    }

    if (extension === "pdf") {
      return (
        <>
          <FileIcon extension={extension} {...defaultStyles.pdf} />

          <Button
            size="small"
            variant="contained"
            onClick={() => check(src, extension)}
          >
            Abrir
          </Button>
        </>
      );
    }

    if (extension === "xlsx") {
      return (
        <>
          <a href={src}>
            <FileIcon extension={extension} {...defaultStyles.xlsx} />
          </a>
        </>
      );
    }

    if (extension === "cad") {
      return (
        <>
          <a href={src}>
            <FileIcon extension={extension} {...defaultStyles.obj} />
          </a>
        </>
      );
    }
  };

  return (
    <>
      <div className="grid-container">
        {props.documents.map(
          ({ id, src, file_name, extension, uploadedAt }: DocumentResult) => (
            <div className="grid-item">
              <div className="icon">{render(extension, src)}</div>
              <div className="file_name">
                <table>
                  <tr>{file_name}</tr>
                  <tr>{uploadedAt}</tr>
                  <tr>
                    <IconButton
                      onClick={() =>
                        props.deleteDocumentCustomer(id, file_name)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </tr>
                </table>
              </div>
            </div>
          )
        )}
      </div>
      {isOpen && (
        <div className="document-overlay" onClick={handleOverlayClick}>
          <div className="document-container">
            <iframe src={src} title="Document Viewer" />
            <button onClick={() => setOpenDocument(false)}>Cerrar</button>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentList;
