import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";

type ChartOptions = {
  chart: {
    type: string;
  };
  credits: {
    enabled: boolean;
  };
  title: {
    text: string;
  };
  yAxis: {
    min: number;
    title: {
      text: string;
    };
  };
  xAxis: {
    categories: string[];
  };
  series: {
    name: string;
    data: number[];
  }[];
};

type DatosVentas = {
  [anio: string]: { [mes: string]: number };
};

const MonthlySalesComparisonChart = (props: any) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Comparación de Ventas Mensuales por Año",
    },
    xAxis: {
      categories: [],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Ventas Totales",
      },
    },
    series: [],
  });

  useEffect(() => {
    let datosConvertidos: DatosVentas | null = null;

    // Verificar si props.datos es una cadena y, de ser así, analizarla
    if (typeof props.datos === "string") {
      try {
        datosConvertidos = JSON.parse(props.datos) as DatosVentas;
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else if (Array.isArray(props.datos)) {
      // Si props.datos ya es un arreglo, usarlo directamente
      datosConvertidos = props.datos as DatosVentas;
    } else {
      // Si no es una cadena ni un arreglo, probablemente hay un error
      console.error("Formato de datos desconocido");
      return;
    }

    if (datosConvertidos) {
      // Mapeo de números de meses a nombres de meses
      const nombresMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      // Crear series para el gráfico
      const series = Object.keys(datosConvertidos).map((anio) => {
        const valoresAnio = datosConvertidos![anio];
        return {
          name: anio,
          data: Object.values(valoresAnio).map(Number),
        };
      });

      // Asumir que todos los años tienen los mismos meses
      const primerAnio = Object.keys(datosConvertidos)[0];
      const categories = primerAnio
        ? Object.keys(datosConvertidos[primerAnio]).map(
            (mes) => nombresMeses[parseInt(mes) - 1]
          )
        : [];

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xAxis: {
          ...prevOptions.xAxis,
          categories: categories,
        },
        series: series,
      }));
    }
  }, [props.datos]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default MonthlySalesComparisonChart;
