import React, { useEffect, useState } from "react";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from "react-icons/fa";

interface ProjectClientRelation {
  projectId: string;
  projectName: string;
  clientId: string;
  clientName: string;
  categoryId: string;
  categoryDescription: string;
  projectCount: number;
  commercialName: string; // Nuevo campo
  commercialId: string;
}
interface LegendItem {
  color: string;
  description: string;
}

const NetworkGraphProjectComercial = ({
  data,
}: {
  data: ProjectClientRelation[];
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    const elem = document.getElementById("network-container");
    if (!document.fullscreenElement && elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const [legendItems, setLegendItems] = useState<LegendItem[]>([]);

  useEffect(() => {
    const categoryColors = new Map<string, string>();
    categoryColors.clear();
    const nodes = new DataSet([]);
    const edges = new DataSet([]);
    const networkData = { nodes, edges };

    // Un Map para almacenar los colores únicos por categoryId para los clientes

    const colors = [
      "#68b0ab", // verde agua
      "#006a71", // cian oscuro
      "#6f4a8e", // púrpura medio
      "#ffcb77", // naranja suave
      "#ffd670", // amarillo suave
      "#fc5c9c", // rosa fuerte
      "#40514e", // verde bosque
      "#11999e", // verde azulado
      "#efee9d", // amarillo limón
      "#f67280", // rosa coral
    ];

    data.forEach((item) => {
      if (!categoryColors.has(item.categoryId)) {
        categoryColors.set(
          item.categoryId,
          colors[categoryColors.size % colors.length]
        );
      }
    });

    // Ahora, utiliza categoryColors directamente para asignar colores y construir la leyenda
    setLegendItems(
      Array.from(categoryColors.entries()).map(([categoryId, color]) => {
        const description =
          data.find((item) => item.categoryId === categoryId)
            ?.categoryDescription ?? "";
        return { color, description };
      })
    );

    // Agregar nodos y aristas
    const commercialColor = "gray"; // Ejemplo: Dorado
    data.forEach((item) => {
      const clientNodeId = `client-${item.clientId}`;
      const projectNodeId = `project-${item.projectId}`;

      const color = categoryColors.get(item.categoryId);
      const commercialNodeId = `commercial-${item.commercialId}`;
      // Agrega nodo del comercial si aún no existe
      if (!nodes.get(commercialNodeId)) {
        nodes.add({
          id: commercialNodeId,
          label: item.commercialName,
          title: `Comercial: ${item.commercialName}`,
          group: "commercial", // Nuevo grupo
          value: 20, // Valor estático o puedes ajustar basado en alguna métrica
          color: commercialColor,
        });
      }

      // Agrega nodo del cliente
      if (!nodes.get(clientNodeId)) {
        nodes.add({
          id: clientNodeId,
          label: item.clientName,
          title: `${item.clientName}(${item.categoryDescription}), Proyectos:${item.projectCount}`,
          group: item.categoryId,
          value: item.projectCount,
          color: color,
        });
      }

      // Agrega nodo del proyecto
      if (!nodes.get(projectNodeId)) {
        nodes.add({
          id: projectNodeId,
          label: item.projectName,
          title: `Proyecto: ${item.projectName}`,
          group: 1,
          value: 1,
          color: "#DC143C",
        });
      }

      // Agrega la arista entre el cliente y el proyecto
      edges.add({
        from: projectNodeId,
        to: clientNodeId,
      });

      edges.add({
        from: clientNodeId,
        to: commercialNodeId,
        color: { color: commercialColor },
      });
    });

    // Configuración de la red
    const options = {
      nodes: {
        shape: "dot",
        scaling: {
          min: 10,
          max: 30,
          label: {
            enabled: true,
            min: 14,
            max: 30,
            maxVisible: 30,
            drawThreshold: 5,
          },
          customScalingFunction: (
            min: number,
            max: number,
            total: any,
            value: number
          ) => {
            return Math.max(0, (value - min) / (max - min));
          },
        },
      },
      font: {
        size: 12,
        face: "Tahoma",
      },
      edges: {
        smooth: true,
      },
      physics: {
        stabilization: { iterations: 3 },
      },
      layout: {
        improvedLayout: true,
      },
    };

    // Inicializar la red
    const container = document.getElementById("mynetwork");
    if (container) {
      new Network(container, networkData, options);
    }
  }, [data]); // Dependencia de efecto

  // Componente de leyenda
  const Legend = () => (
    <div className="legend">
      {legendItems.map((item, index) => (
        <div key={index} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: item.color }}
          ></span>
          <span className="legend-description">{item.description}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div
      id="network-container"
      style={{ position: "relative", height: "500px", width: "100%" }}
    >
      <div id="mynetwork"></div>
      {/* Otros componentes como <Legend /> */}
      <button
        onClick={toggleFullScreen}
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: 20,
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
      >
        {isFullScreen ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
      </button>
    </div>
  );
};

export default NetworkGraphProjectComercial;
