import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";
import { useHistory } from "react-router-dom";
import OffersList from "../shared/components/OffersList";
import OfferDetailsModal from "../shared/components/OfferDetailsModal";
import {
  deleteOffer,
  getOffersByStatus,
  updateEndDateOffer,
  updateStatusOffer,
} from "../services/offersServices";
import NewOfferDocumentModal from "../shared/components/DocumentList/NewOfferDocumentModal";
import ChartProductsOffer from "./components/DashBoard/ChartProductsOffer";

import OffersListPedido from "../shared/components/OffersListPedido";
import OffersListEntregada from "../shared/components/OffersListEntregada";
import OffersListAnulada from "../shared/components/OffersListAnulada";
import ChartProductsOffersPedido from "./components/DashBoard/ChartProductsOffersPedido";
import ChartProductsOffersEntregada from "./components/DashBoard/ChartProductsOffersEntregada";
import ChartProductsOffersAnulados from "./components/DashBoard/ChartProductsOffersAnulados";
import OffersSellerChart from "../shared/components/charts/OffersSellerChart";
import "../shared/components/charts/style.scss";
import { useEffect, useState } from "react";
import OffersSellerChartPedido from "../shared/components/charts/OffersSellerChartPedido";
import OffersSellerChartEntregada from "../shared/components/charts/OffersSellerChartEntregada";
const Offers = () => {
  const history = useHistory();
  const [openOfferModal, setopenOfferModal] = useState(false);
  const [openDocumentModal, setopenDocumentModal] = useState(false);
  const [idoffer, setidoffer] = useState(null);
  const [offers, setoffers] = useState([]);
  const [offerspedido, setofferspedido] = useState([]);
  const [offersentregada, setoffersentregada] = useState([]);
  const [offersanulada, setoffersanulada] = useState([]);
  const [offeritem, setofferitem] = useState([]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle_offer = () => {
    setopenOfferModal(!openOfferModal);
  };

  const toggle_document = () => {
    setopenDocumentModal(!openDocumentModal);
  };

  const refresh = () => {
    getOffersByStatus(0).then((response) => {
      //console.table(response);
      if (response?.data.ok) {
        setoffers(response?.data.offersResults);
      } else {
        setoffers([]);
        history.push("/login");
        window.location.reload();
      }
    });
    console.log("cargar o refrescar");
    getOffersPedido();
    getOffersEntregada();
    getOffersAnulada();
  };

  const getOffersPedido = () => {
    getOffersByStatus(1).then((response) => {
      if (response?.data.ok) {
        setofferspedido(response?.data.offersResults);
      } else {
        setofferspedido([]);
      }
    });
  };
  const getOffersEntregada = () => {
    getOffersByStatus(3).then((response) => {
      if (response?.data.ok) {
        setoffersentregada(response?.data.offersResults);
      } else {
        setoffersentregada([]);
      }
    });
  };
  const getOffersAnulada = () => {
    getOffersByStatus(2).then((response) => {
      if (response?.data.ok) {
        setoffersanulada(response?.data.offersResults);
      } else {
        setoffersanulada([]);
      }
    });
  };

  const offer_handler = (item: any) => {
    //alert(item.idoffer);
    setidoffer(item.idoffer);
    setofferitem(item);
    setopenOfferModal(true);
  };

  const _delete_offer = (item: any) => {
    const userid = localStorage.getItem("userid");
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Quieres ELIMINAR la Oferta " + item.idoffer + " ?")) {
      if (
        userid === "1" ||
        userid === "27" ||
        userid === "26" ||
        userid === "5" ||
        userid === "4" ||
        userid === "25"
      ) {
        deleteOffer(item.idoffer).then((response) => {
          if (response?.data.ok) {
            refresh();
          }
        });
      } else {
        alert("No autorizado para Eliminar Ofertas");
      }
    }
  };

  const addNewDocument = () => {
    setopenDocumentModal(false);
    refresh();
  };
  const updateStatus = (
    idstatus: number,
    idoffer: string,
    idanulation: any
  ) => {
    updateStatusOffer(idstatus, idoffer, idanulation).then((response) => {
      if (response?.data.ok) {
        refresh();
        toggle_offer();
      }
    });
  };

  const updateEndDate = (end_date: any, idoffer: string) => {
    updateEndDateOffer(end_date, idoffer).then((response) => {
      if (response?.data.ok) {
        refresh();
        toggle_offer();
      }
    });
  };

  const showCustomer = (data: any) => {
    if (data.type === 1) {
      window.open(`/layout/customer/${data.customerid}`);
    }
    if (data.type === 2) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 3) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 4) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 5) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 6) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 7) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 8) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 9) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 10) {
      window.open(`/layout/channel/${data.customerid}`);
    }
    if (data.type === 11) {
      window.open(`/layout/channel/${data.customerid}`);
    }
  };
  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaMoneyBill className="me-2" />
            Ofertas
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Ofertas</BreadcrumbItem>
              <BreadcrumbItem>Listado Ofertas</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="pull-right"></Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>
            Ofertas{" "}
            <div className="Button">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={toggle_document}
              >
                Cargar Oferta
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <div>
              <ChartProductsOffer showCustomer={showCustomer} />

              <OffersSellerChart
                color={"#E67E22"}
                title={"Ofertas Comerciales"}
                showCustomer={showCustomer}
              />

              {offers ? (
                <OffersList
                  offers={offers}
                  offer_handler={offer_handler}
                  showCustomer={showCustomer}
                  delete_offer={_delete_offer}
                />
              ) : null}
            </div>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader>Pedidos </CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <div>
              <ChartProductsOffersPedido showCustomer={showCustomer} />

              <OffersSellerChartPedido
                color={"#F4D03F"}
                title={"Pedidos Comerciales"}
                showCustomer={showCustomer}
              />

              {offerspedido ? (
                <OffersListPedido
                  offers={offerspedido}
                  offer_handler={offer_handler}
                  showCustomer={showCustomer}
                  delete_offer={_delete_offer}
                />
              ) : null}
            </div>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <div>
              <ChartProductsOffersEntregada showCustomer={showCustomer} />

              <OffersSellerChartEntregada
                color={"#229954"}
                title={"Entregado Comerciales"}
                showCustomer={showCustomer}
              />

              {offersentregada ? (
                <OffersListEntregada
                  offers={offersentregada}
                  offer_handler={offer_handler}
                  showCustomer={showCustomer}
                  delete_offer={_delete_offer}
                />
              ) : null}
            </div>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <CardTitle tag="h5"></CardTitle>
            <div>
              <ChartProductsOffersAnulados showCustomer={showCustomer} />

              <OffersSellerChart
                color={"#C0392B"}
                title={"Anulada Comerciales"}
              />

              {offersanulada ? (
                <OffersListAnulada
                  offers={offersanulada}
                  offer_handler={offer_handler}
                  showCustomer={showCustomer}
                  delete_offer={_delete_offer}
                />
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
      {openOfferModal ? (
        <OfferDetailsModal
          isOpen={openOfferModal}
          toggle_offer={toggle_offer}
          idoffer={idoffer}
          offeritem={offeritem}
          updateStatus={updateStatus}
          updateEndDate={updateEndDate}
        />
      ) : null}

      {openDocumentModal ? (
        <NewOfferDocumentModal
          isOpen={openDocumentModal}
          toggle_document={toggle_document}
          addNewDocument={addNewDocument}
        />
      ) : null}
    </div>
  );
};

export default Offers;
