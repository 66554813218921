import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Table,
} from "reactstrap";
import { getCustomersAll } from "../services/customerService";

import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Importa los iconos

import { useHistory } from "react-router-dom";
import CustomerListAll from "../shared/components/CustomerList/CustomerListAll";
import { uploadExcelData } from "../services/documentService";
const CustomersAll = () => {
  const history = useHistory();
  const [customers, setcustomers] = useState([]);
  const [loading, setloading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState<any[]>([]); // Estado para almacenar los resultados del backend

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => setModal(!modal);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file && file.type === "text/csv") {
      setSelectedFile(file);
    } else if (file && file.name.endsWith(".csv")) {
      // En algunos navegadores, el tipo MIME puede no ser reconocido correctamente,
      // por lo que también verificamos la extensión del archivo.
      setSelectedFile(file);
    } else {
      alert("Por favor selecciona un archivo .csv válido");
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Por favor selecciona un archivo antes de subirlo");
      return;
    }

    setUploading(true);

    try {
      const response = await uploadExcelData(selectedFile);
      console.log("Upload successful:", response);
      //alert("Archivo subido exitosamente");
      console.log(response.data);
      setUploadResults(response.data);
    } catch (error) {
      console.error("Upload failed:", error);
      alert("Error al subir el archivo");
    } finally {
      setUploading(false);
      //toggleModal(); // Cerrar el modal después de la subida
    }
  };

  const show = (data: any) => {
    if (data.type_id === 1) {
      window.open(`/layout/customer/${data.id}`);
    }
    if (data.type_id === 2) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 3) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 4) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 5) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 6) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 7) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 8) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 9) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 10) {
      window.open(`/layout/channel/${data.id}`);
    }
    if (data.type_id === 11) {
      window.open(`/layout/channel/${data.id}`);
    }
  };

  const refresh = () => {
    getCustomersAll().then((response) => {
      if (response?.data.ok) {
        setcustomers(response?.data.customersResults);
        setloading(!loading);
      } else {
        history.push("/login");
        window.location.reload();
      }
    });
  };

  return (
    <div>
      <div className="wrapper-content border-bottom page-heading-color">
        <div className="page-heading-title ">
          <h4>
            <FaIcons.FaBuilding className="me-2" />
            Clientes
          </h4>
        </div>

        <Row xs="2">
          <Col className="col-lg-10">
            <Breadcrumb listTag="div">
              <BreadcrumbItem>Todos</BreadcrumbItem>
              <BreadcrumbItem>Lista</BreadcrumbItem>
            </Breadcrumb>
          </Col>
          <Col className="col-lg-2">
            <Col className="text-center text-md-right pull-right">
              <Button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={refresh}
              >
                <MdIcons.MdRefresh className="me-2" />
                Refrescar
              </Button>
              <Button
                type="button"
                className="btn btn-primary btn-sm ml-2"
                onClick={toggleModal}
              >
                <MdIcons.MdCloudUpload className="me-2" />
                Carga Masiva Clientes
              </Button>
            </Col>
          </Col>
        </Row>
      </div>
      <div className="content-page">
        <Card>
          <CardHeader>Clientes</CardHeader>
          <CardBody>
            {loading ? (
              <CustomerListAll customers={customers} edit={show} />
            ) : (
              <>
                <Button color="primary" disabled>
                  <Spinner size="sm">Loading...</Spinner>
                  <span> Cargando Clientes, por favor espera</span>
                </Button>
              </>
            )}
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={modal} toggle={toggleModal} size="xl">
        <ModalHeader toggle={toggleModal}>Carga Masiva de Clientes</ModalHeader>
        <ModalBody>
          <Input type="file" accept=".csv" onChange={handleFileChange} />
          <Button
            color="primary"
            onClick={handleUpload}
            disabled={uploading || !selectedFile}
            className="mt-3"
          >
            {uploading ? "Subiendo..." : <MdIcons.MdCloudUpload />}
          </Button>
          {/* Mostrar los resultados después de subir el archivo */}
          <div className="mt-4">
            <h5>Resultados de la carga:</h5>
            <Table striped>
              <thead>
                <tr>
                  <th>NMC Code</th>
                  <th>Nombre</th>
                  <th>Dirección</th>
                  <th>Estado</th>
                  <th>País</th>
                  <th>Ingresado</th>
                </tr>
              </thead>
              <tbody>
                {uploadResults.map((result, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: result.is_valid ? "#d4edda" : "#f5c6cb", // Rojo más visible si no es válido
                      color: result.is_valid ? "#155724" : "#721c24", // Color de texto adecuado para el fondo
                    }}
                  >
                    <td>{result.nmc_code}</td>
                    <td>{result.nombre}</td>
                    <td>{result.formatted_address || result.direccion}</td>
                    <td>{result.state_code || "N/A"}</td>
                    <td>{result.country || "N/A"}</td>
                    <td>
                      {result.is_valid ? (
                        <FaCheckCircle style={{ color: "green" }} />
                      ) : (
                        <FaTimesCircle style={{ color: "red" }} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CustomersAll;
