import React from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { Edit } from "@material-ui/icons";
const ListUsers = (props: any) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.users ? (
            <MaterialTable
              columns={[
                {
                  title: "Id",
                  field: "id",
                  width: 20,
                },
                {
                  title: "Codigo",
                  field: "code",
                  width: 20,
                },
                {
                  title: "Nombre",
                  field: "name",
                  width: 20,
                },
                {
                  title: "Apellido",
                  field: "last_name",
                  width: 20,
                },
                {
                  title: "Email",
                  field: "email",
                  width: 20,
                },
                {
                  title: "Fono",
                  field: "fone",
                  width: 20,
                },
                { title: "Rol", field: "charge", width: "80%" },
              ]}
              options={{
                toolbar: true,
                exportButton: true,
                selection: false,
                search: false,
                paging: true,
                pageSize: 30,
                pageSizeOptions: [10, 20, 30, 40, 50],
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: (rowData, index) => ({
                  fontSize: 13,
                  color: "#676A6C",
                  backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#fff",
                }),
                filtering: true,
              }}
              data={props.users}
              title=""
              actions={[
                (rowData) => ({
                  icon: () => <Edit />,
                  tooltip: "Editar",
                  onClick: (event, rowData) => props.editUser(rowData),
                  //@ts-ignore
                  hidden: !rowData.canViewInfo, // Oculta el botón si canViewInfo es falso
                }),
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};
export default ListUsers;
