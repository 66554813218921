import React from "react";
import { Card, CardBody, CardTitle, Table, Button } from "reactstrap";

const MapPopUp = (props: any) => {
  console.table(props);
  return (
    <Card className="shadow-sm rounded-lg">
      <CardBody>
        <CardTitle tag="h6" className="text-center mb-3">
          {props.name}
        </CardTitle>
        <Table borderless size="sm">
          <tbody>
            <tr>
              <th>ID:</th>
              <td>{props.id}</td>
            </tr>
            <tr>
              <th>Canal:</th>
              <td>{props.description}</td>
            </tr>
            <tr>
              <th>Dirección:</th>
              <td>{props.address}</td>
            </tr>
            <tr>
              <th>Producto</th>
              <td>
                <span style={{ color: "#27AE60" }}> € {props.totalVentas}</span>
              </td>
            </tr>
            {/* Agrega más filas si necesitas */}
          </tbody>
        </Table>
        <Button
          color="primary"
          block
          size="sm"
          onClick={() => props.show(props)}
        >
          Ver Detalles
        </Button>
      </CardBody>
    </Card>
  );
};

export default MapPopUp;
