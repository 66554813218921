import React from "react";
import { Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import * as FaIcons from "react-icons/fa";
const CustomerMapPopUp = (props: any) => {
  return (
    <Card className="shadow-sm rounded-lg map-popup-card">
      <div
        className="color-bar"
        style={{ backgroundColor: props.CustomerColorReport }}
      ></div>
      <CardBody>
        <CardTitle tag="h6" className="text-center mb-3">
          <FaIcons.FaMapMarkerAlt
            className="me-2"
            color={props.CustomerColorReport}
          />
          {props.name}
        </CardTitle>
        <Table borderless size="sm">
          <tbody>
            <tr>
              <th>ID:</th>
              <td>{props.id}</td>
            </tr>
            <tr>
              <th>Dirección:</th>
              <td>{props.address}</td>
            </tr>

            <tr>
              <th>
                <FaIcons.FaUser />
              </th>
              <td>Comercial: {props.comercial}</td>
            </tr>
            <tr>
              <th>
                <FaIcons.FaClock />
              </th>
              <td>Días desde última Gestión: {props.diffDays}</td>
            </tr>
            <tr>
              <th>
                <FaIcons.FaCalendarAlt />
              </th>
              <td>Próxima Gestión: {props.nextVisit}</td>
            </tr>
            {/* Add more rows if needed */}
          </tbody>
        </Table>
        <Button
          color="primary"
          block
          size="sm"
          style={{
            backgroundColor: props.CustomerColorReport,
            borderColor: props.CustomerColorReport,
          }}
          onClick={() => props.show(props)}
        >
          Ver Detalles
        </Button>
      </CardBody>
    </Card>
  );
};

export default CustomerMapPopUp;
