import React from "react";
import { Grid } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import MaterialTable from "material-table";

const ContactList = (props: any) => {
  console.log(props.contacts);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {props.contacts ? (
            <MaterialTable
              columns={[
                {
                  title: "Nombre",
                  field: "full_name",
                  width: 20,
                },
                {
                  title: "Email",
                  field: "email",
                  width: 20,
                },
                {
                  title: "Teléfono",
                  field: "phone",
                  width: 20,
                },
                {
                  title: "Cargo",
                  field: "charge",
                  width: 20,
                },
                { title: "ID", field: "id", width: "2%", hidden: true },
              ]}
              options={{
                toolbar: false,
                exportButton: false,
                selection: false,
                search: false,
                paging: false,
                pageSize: 15,
                actionsColumnIndex: -1,
                padding: "dense",
                maxBodyHeight: "100%",
                rowStyle: {
                  fontSize: 13,
                  color: "#676A6C",
                  background: "#F5F5F5",
                },
                filtering: false,
              }}
              data={props.contacts}
              title=""
              actions={[
                {
                  icon: () => <Delete />,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) => props.deleteContact(rowData),
                },
              ]}
              localization={{
                body: {
                  emptyDataSourceMessage: "Sin Registros",
                  addTooltip: "Agregar",
                  deleteTooltip: "Borrar",
                  editTooltip: "Editar",
                  filterRow: {
                    filterTooltip: "Filtrar",
                  },
                  editRow: {
                    deleteText: "Estas seguro de borrar esta fila?",
                    cancelTooltip: "Cancelar",
                    saveTooltip: "Salvar",
                  },
                },
                grouping: {
                  placeholder: "Arrastrar Columnas ...",
                  groupedBy: "Agrupado por:",
                },
                header: {
                  actions: "Acciones",
                },
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Filas",
                  labelRowsPerPage: "Filas por Página:",
                  firstAriaLabel: "Primera Página",
                  firstTooltip: "Primera Página",
                  previousAriaLabel: "Pagina previa",
                  previousTooltip: "Página previa",
                  nextAriaLabel: "Siguiente Página",
                  nextTooltip: "Siguiente Página",
                  lastAriaLabel: "Última Página",
                  lastTooltip: "Última Página",
                },
                toolbar: {
                  addRemoveColumns: "Agregar o Borrar columnas",
                  nRowsSelected: "{0} Fila(s) Seleccionadas",
                  showColumnsTitle: "Mostrar Columnas",
                  showColumnsAriaLabel: "Mostrar Columnas",
                  exportTitle: "Exportar",
                  exportAriaLabel: "Exportar",
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
              }}
            />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactList;
