import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Col,
  Input,
} from "reactstrap";
import "./style.scss";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

const EditAdressModal = (props: any) => {
  const { t } = useTranslation(["home", "projects"]);
  const [latitud, setlatitud] = useState(props.customer.position.lat);
  const [longitud, setlongitud] = useState(props.customer.position.lng);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [position, setposition] = useState([0, 0]);

  const [promoteraddress, setpromoteraddress] = useState(
    props.customer.address
  );
  const [finaladress, setfinaladress] = useState(props.customer.address);

  const PlaceSelected = (place: any) => {
    setlatitud(place.geometry.location.lat());
    setlongitud(place.geometry.location.lng());
    setposition([place.geometry.location.lat(), place.geometry.location.lng()]);
    setpromoteraddress(place.formatted_address);
    setfinaladress(place.formatted_address);
    //alert(place.formatted_address);
  };

  const finaladress_handler = (e: any) => {
    //alert(e.target.value);
    setfinaladress(e.target.value);
  };

  const NewCustomer = () => {
    if (finaladress) {
      const item = {
        id: props.customer.id,
        address: promoteraddress,
        finaladress: finaladress,
        latitud: latitud,
        longitud: longitud,
      };
      props.editAdress(item);
    } else {
      alert("Debes Ingresar una dirección");
    }
  };
  return (
    <div>
      <Modal size="xl" isOpen={props.isOpen} toggle={props.toggle_editadress}>
        <ModalHeader>
          {t("CustomerDetail.Modal.EditAdressModal.Title")}{" "}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup row>
              <Label for="examplePassword" sm={2}>
                {t("CustomerDetail.Modal.EditAdressModal.SearchAddressLabel")}
              </Label>
              <Col sm={10}>
                <Autocomplete
                  className="address-input"
                  placeholder={promoteraddress}
                  apiKey="AIzaSyC0x7V6B-BvBRTrJz2hl8mtj4JVJmH0RPc"
                  style={{ width: "100%" }}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(place) => PlaceSelected(place)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="exampleFile" sm={2}>
                {t("CustomerDetail.Modal.EditAdressModal.FinalAddressLabel")}
              </Label>
              <Col sm={10}>
                <Input
                  id="finaladress"
                  name="finaladress"
                  placeholder={
                    t(
                      "CustomerDetail.Modal.EditAdressModal.FinalAddressLabel"
                    ) as string
                  }
                  type="text"
                  onChange={finaladress_handler}
                  value={finaladress}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={props.toggle_editadress}>
            {t("CustomerDetail.Modal.EditAdressModal.CancelButton")}
          </Button>
          <Button onClick={NewCustomer} color="success">
            {t("CustomerDetail.Modal.EditAdressModal.EditButton")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAdressModal;
