/* eslint-disable react-hooks/exhaustive-deps */
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react";
require("highcharts/modules/exporting")(Highcharts);

type ChartOptions = {
  chart: {
    type: string;
  };
  credits: {
    enabled: boolean;
  };
  title: {
    text: string;
  };
  yAxis: {
    title: {
      text: string;
    };
  };
  xAxis: {
    categories: string[];
  };
  series: {
    name: string;
    data: number[];
    dataLabels: {
      enabled: boolean;
    };
  }[];
};

const TotalSalesByYearChart = (props: any) => {
  const [chartOptions, setChartOptions] = useState<ChartOptions>({
    chart: {
      type: "bar", // Cambiar a un gráfico de barras horizontales
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Total de Ventas por Año",
    },
    yAxis: {
      title: {
        text: "Ventas Totales",
      },
    },
    xAxis: {
      categories: [], // Los años irán aquí
    },
    series: [
      {
        name: "Ventas",
        data: [],
        dataLabels: {
          enabled: true,
        },
      },
    ],
  });

  useEffect(() => {
    let datosConvertidos;

    // Verificar si props.datos es una cadena y, de ser así, analizarla
    if (typeof props.datos === "string") {
      try {
        datosConvertidos = JSON.parse(props.datos);
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        return;
      }
    } else if (Array.isArray(props.datos)) {
      // Si props.datos ya es un arreglo, usarlo directamente
      datosConvertidos = props.datos;
    } else {
      // Si no es una cadena ni un arreglo, probablemente hay un error
      console.error("Formato de datos desconocido");
      return;
    }

    if (Array.isArray(datosConvertidos)) {
      const categories = datosConvertidos.map((item) => item.anio);
      const ventasTotales = datosConvertidos.map((item) => item.totalVentas);

      setChartOptions({
        ...chartOptions,
        xAxis: {
          categories: categories,
        },
        series: [
          {
            ...chartOptions.series[0],
            data: ventasTotales,
          },
        ],
      });
    }
  }, [props.datos]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default TotalSalesByYearChart;
