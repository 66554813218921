import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const AreaChart = (props: { datos: string | any[] }) => {
  let datosProcesados;

  // Procesamiento de los datos
  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      return null;
    }
  } else if (Array.isArray(props.datos) && props.datos.length > 0) {
    datosProcesados = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    return null;
  }

  // Opciones de configuración del gráfico de áreas
  const chartOptions = {
    chart: {
      type: "area",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Diagrama de Pareto ",
    },
    xAxis: {
      categories: datosProcesados.map((item: { mes: any }) => item.mes),
      tickmarkPlacement: "on",
      title: {
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        // @ts-ignore
        formatter: function () {
          // @ts-ignore
          return Highcharts.numberFormat(this.value, 2);
        },
      },
    },
    tooltip: {
      split: true,
      valueSuffix: " euros",
      pointFormat: "{series.name}: <b>{point.y:.2f}</b><br/>",
    },
    plotOptions: {
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
        dataLabels: {
          enabled: true,
          // @ts-ignore
          formatter: function () {
            // @ts-ignore
            return Highcharts.numberFormat(this.y, 2);
          },
        },
      },
    },
    series: [
      {
        name: "VENTA 80%",
        color: "#EBBE82", // Un verde atractivo
        data: datosProcesados.map((item: { venta80: number }) =>
          parseFloat(item.venta80.toFixed(2))
        ),
      },
      {
        name: "VENTA 20%",
        color: "#9BBEEC", // Un verde atractivo
        data: datosProcesados.map((item: { venta20: number }) =>
          parseFloat(item.venta20.toFixed(2))
        ),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default AreaChart;
