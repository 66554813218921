import { Grid, Paper } from "@material-ui/core";
import React from "react";
import "./style.scss";
import ReportsList2 from "./ReportsList2";
const Reports2 = (props: any) => {
  const onReportClick = (item: any) => {
    props.getcustomerid(item);
  };
  return (
    <div>
      {" "}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <ReportsList2
              reports={props.reports}
              onReportClick={onReportClick}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports2;
