import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const FacturacionCanales = (props: any) => {
  const { datos } = props;

  let dataParsed = [];

  try {
    dataParsed = JSON.parse(datos);
  } catch (error) {
    console.error("Error al analizar los datos JSON:", error);
  }

  const colors = [
    "#96D400",
    "#04AB00",
    "#BDFBFF",
    "#04ABD8",
    "#3535CB",
    "#6628A5",
    "#A000B0",
    "#FE00D2",
    "#FF0068",
    "#F50101",
  ];
  //const currentYear = new Date().getFullYear();
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: `Facturación Anual por Canales`,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "<b>{point.name}</b>: {point.percentage:.1f}%",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "{point.name}: € {point.y:.0f}  ({point.percentage:.1f}%)",
        },
      },
    },
    colors: colors,
    series: [
      {
        name: "Categoría",
        colorByPoint: true,
        data: dataParsed.map((dataPoint: { y: number }) => ({
          ...dataPoint,
          y: Math.round(dataPoint.y),
        })),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default FacturacionCanales;
