import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const TortaChart = (props: any) => {
  let datosProcesados: any;

  // Procesamiento de los datos
  if (typeof props.datos === "string") {
    try {
      datosProcesados = JSON.parse(props.datos);
    } catch (error) {
      console.error("Error al analizar JSON:", error);
      return null;
    }
  } else if (Array.isArray(props.datos) && props.datos.length > 0) {
    datosProcesados = props.datos;
  } else {
    console.error("Formato de datos desconocido");
    return null;
  }

  // Opciones de configuración del gráfico
  const chartOptions = {
    chart: {
      type: "pie",
    },
    credits: {
      enabled: false,
    },
    colors: [
      "#FF0000", // rojo
      "#FF4000", // naranja oscuro
      "#FF8000", // naranja
      "#FFBF00", // amarillo oscuro
      "#FFFF00", // amarillo
      "#BFFF00", // amarillo verdoso
      "#80FF00", // verde claro
      "#00FF00", // verde
      "#00FF80", // verde azulado
      "#00FFFF", // cian
      "#0080FF", // azul verdoso
      "#0000FF", // azul
      "#4000FF", // azul violeta
      "#8000FF", // violeta oscuro
      "#BF00FF", // magenta oscuro
      "#FF00FF", // magenta
      "#FF40FF", // rosa oscuro
      "#FF80FF", // rosa claro
      "#FFC0CB", // rosa
      "#FF8C69", // coral
      "#FF6EC7", // rosa claro
      "#8B0000", // marrón oscuro
      "#8B4513", // marrón
      "#808080", // gris
    ],

    title: {
      text: "Porcentaje de Ventas Producto - Clientes",
    },
    tooltip: {
      pointFormat: `
        {series.name}: <b>{point.percentage:.1f}%</b><br/>
        Vendido: <b>€ {point.y}</b>
      `,
      shared: true,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: "Ventas",
        colorByPoint: true,
        data: datosProcesados.map(
          (item: { nombreCliente: any; total_ventas: string }) => ({
            name: item.nombreCliente,
            y: parseFloat(item.total_ventas),
          })
        ),
      },
    ],
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default TortaChart;
